<ng-container *ngIf="infoUser$ | async as infoUser">
    <ng-container>
        <div class="fixed-sidenav"
            [ngStyle]="{ 'min-height.px': innerHeight, 'border-right': !menuOpened && !showMainLayout ? '1px solid #E1E1E1' : 'none'}"
            [ngClass]="{'new-layout': !showMainLayout, 'default-layout': showMainLayout}"
        >
            <ng-container *ngIf="!menuOpened">
                <button mat-icon-button id="openMenuButton" (click)="openMenu()" class="arrow-menu-closed">
            <mat-icon>arrow_forward</mat-icon>
          </button>
            </ng-container>

            <mat-accordion>
                <ng-container *ngIf="!menuOpened">
                    <button id="initialsButton" class="radial-btn" *ngIf="showMainLayout">
                 {{ (infoUser.name.split(' ')[0][0] | capitalize) + ' ' + (infoUser.name.split(' ')[1][0] | capitalize) }}
            </button>
                </ng-container>

                <div class="fixed-header">
                    <ng-container *ngIf="profiles.latamManagement">
                        <mat-expansion-panel id="sidenav-menu"
                            [expanded]="sidebarMenu.panelOpened === MENU_LATAM"
                            (click)="openMenuFromPanel(MENU_LATAM)"
                            (opened)="setPanelOpened(MENU_LATAM, MENU_LATAM)"
                            (closed)="setPanelOpened(MENU_NONE, MENU_LATAM)"
                            class="fixed-expansion-panel"
                            [ngClass]="{'new-layout': !showMainLayout, 'default-layout': showMainLayout}"
                        >
                            <mat-expansion-panel-header id="menuGestionLatamIcon" [ngClass]="{ 'active': sidebarMenu.panelOpened === MENU_LATAM || (sidebarMenu.panelOpened === MENU_NONE && lastOpened === MENU_LATAM && !menuOpened), 'menu-item-hovered': menuItemsHovered[MENU_LATAM] , 'new-layout': !showMainLayout}"
                                (mouseover)="menuItemHovered($event, MENU_LATAM)" (mouseout)="menuItemHovered($event, MENU_LATAM)" class="arrow-hidden">
                                <mat-panel-title>
                                    <mat-icon class="icon-regular-air020"></mat-icon>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <mat-nav-list *ngIf="menuOpened" class="pt-0">
                                <ng-container *ngIf="profiles.readUserManagement">
                                    <mat-list-item routerLink="/latam/users/list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_LATAM_USERS_LIST] }" (click)="subMenuItemHovered($event, SUBMENU_LATAM_USERS_LIST)" (mouseover)="subMenuItemHovered($event, SUBMENU_LATAM_USERS_LIST)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_LATAM_USERS_LIST)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readFeedback">
                                    <mat-list-item routerLink="/latam/feedback" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_LATAM_FEEDBACK] }" (click)="subMenuItemHovered($event, SUBMENU_LATAM_FEEDBACK)" (mouseover)="subMenuItemHovered($event, SUBMENU_LATAM_FEEDBACK)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_LATAM_FEEDBACK)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readBudgetManagement">
                                    <mat-list-item routerLink="/latam/budget/management" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_LATAM_BUDGET_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_LATAM_BUDGET_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_LATAM_BUDGET_MANAGEMENT)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_LATAM_BUDGET_MANAGEMENT)" disableRipple>
                                    </mat-list-item>
                                </ng-container>


                                <ng-container *ngIf="profiles.createBudgetBulkLoad">
                                    <mat-list-item routerLink="/latam/budget/bulk-load" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_LATAM_BUDGET_BULK_LOAD] }" (click)="subMenuItemHovered($event, SUBMENU_LATAM_BUDGET_BULK_LOAD)" (mouseover)="subMenuItemHovered($event, SUBMENU_LATAM_BUDGET_MANAGEMENT)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_LATAM_BUDGET_BULK_LOAD)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readWaiverAutomation">
                                    <mat-list-item routerLink="/latam/waiver-automation" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_AUTOMATION] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_AUTOMATION)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_AUTOMATION)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_AUTOMATION)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readWaiverTypeActivation">
                                    <mat-list-item routerLink="/latam/waiver-type-management" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_TYPE_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_TYPE_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_TYPE_MANAGEMENT)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_TYPE_MANAGEMENT)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readFarediffManagement">
                                    <mat-list-item routerLink="/latam/waiver-farediff-management" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_FAREDIFF_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_FAREDIFF_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_FAREDIFF_MANAGEMENT)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_FAREDIFF_MANAGEMENT)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readBulkLoad">
                                    <mat-list-item routerLink="/latam/bulk-load" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_BULK_LOAD] }" (click)="subMenuItemHovered($event, SUBMENU_BULK_LOAD)" (mouseover)="subMenuItemHovered($event, SUBMENU_BULK_LOAD)" (mouseout)="subMenuItemHovered($event, SUBMENU_BULK_LOAD)"
                                        disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readUserProfile">
                                    <mat-list-item routerLink="/latam/user-profile" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_USER_PROFILE] }" (click)="subMenuItemHovered($event, SUBMENU_USER_PROFILE)" (mouseover)="subMenuItemHovered($event, SUBMENU_USER_PROFILE)" (mouseout)="subMenuItemHovered($event, SUBMENU_USER_PROFILE)"
                                        disableRipple>
                                    </mat-list-item>
                                </ng-container>
                            </mat-nav-list>
                        </mat-expansion-panel>
                    </ng-container>

                    <ng-container *ngIf="profiles.agencyManagment">
                        <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened === MENU_AGENCIES" (click)="openMenuFromPanel(MENU_AGENCIES)" (opened)="setPanelOpened(MENU_AGENCIES, MENU_AGENCIES)" (closed)="setPanelOpened(MENU_NONE, MENU_AGENCIES)" class="fixed-expansion-panel">
                            <mat-expansion-panel-header [ngClass]="{ 'active': sidebarMenu.panelOpened === MENU_AGENCIES || (sidebarMenu.panelOpened === MENU_NONE && lastOpened === MENU_AGENCIES && !menuOpened), 'menu-item-hovered': menuItemsHovered[MENU_AGENCIES] }" (mouseover)="menuItemHovered($event, MENU_AGENCIES)"
                                (mouseout)="menuItemHovered($event, MENU_AGENCIES)" class="arrow-hidden"
                                [ngStyle]="{'border-right': !menuOpened && !showMainLayout ? '1px solid #E1E1E1' : 'none'}"
                            >
                                <mat-panel-title>
                                    <mat-icon *ngIf="showMainLayout" class="icon-regular-tra002"></mat-icon>
                                    <img *ngIf="!showMainLayout" src="assets/images/correct-name/Settings.svg" alt="Gestión">
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <mat-nav-list *ngIf="menuOpened" class="pt-0">
                                <ng-container *ngIf="profiles.readActivateAgency">
                                    <mat-list-item routerLink="/latam/agencies/search" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_AGENCY_SEARCH] }" (click)="subMenuItemHovered($event, SUBMENU_AGENCY_SEARCH)" (mouseover)="subMenuItemHovered($event, SUBMENU_AGENCY_SEARCH)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_AGENCY_SEARCH)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readAgencyGroupProfile && profiles.roleAgency">
                                    <mat-list-item routerLink="/agency/group/profile" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_MATRIX_PROFILE] }" (click)="subMenuItemHovered($event, SUBMENU_MATRIX_PROFILE)" (mouseover)="subMenuItemHovered($event, SUBMENU_MATRIX_PROFILE)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_MATRIX_PROFILE)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readBranchOfficeProfile && profiles.roleBranchOffice">
                                    <mat-list-item routerLink="/agency/branch-office/profile" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_BRANCH_OFFICE_PROFILE] }" (click)="subMenuItemHovered($event, SUBMENU_BRANCH_OFFICE_PROFILE)" (mouseover)="subMenuItemHovered($event, SUBMENU_BRANCH_OFFICE_PROFILE)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_BRANCH_OFFICE_PROFILE)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readAgencyRegister">
                                    <mat-list-item routerLink="/latam/agencies/register" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_AGENCY_REGISTER] }" (click)="subMenuItemHovered($event, SUBMENU_AGENCY_REGISTER)" (mouseover)="subMenuItemHovered($event, SUBMENU_AGENCY_REGISTER)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_AGENCY_REGISTER)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readAgencyGroupManagement">
                                    <mat-list-item routerLink="/latam/agencies/group/list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_AGENCY_GROUP] }" (click)="subMenuItemHovered($event, SUBMENU_AGENCY_GROUP)" (mouseover)="subMenuItemHovered($event, SUBMENU_AGENCY_GROUP)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_AGENCY_GROUP)" disableRipple>
                                    </mat-list-item>
                                </ng-container>
                            </mat-nav-list>
                        </mat-expansion-panel>
                    </ng-container>

                    <ng-container *ngIf="profiles.waiverManagment">
                        <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened === MENU_WAIVERS" (click)="openMenuFromPanel(MENU_WAIVERS)" (opened)="setPanelOpened(MENU_WAIVERS, MENU_WAIVERS)" (closed)="setPanelOpened(MENU_NONE, MENU_WAIVERS)" class="fixed-expansion-panel">
                            <mat-expansion-panel-header [ngClass]="{ 'active':sidebarMenu.panelOpened === MENU_WAIVERS || (sidebarMenu.panelOpened === MENU_NONE && lastOpened === MENU_WAIVERS && !menuOpened), 'menu-item-hovered': menuItemsHovered[MENU_WAIVERS] }" (mouseover)="menuItemHovered($event, MENU_WAIVERS)"
                                (mouseout)="menuItemHovered($event, MENU_WAIVERS)" class="arrow-hidden"
                                [ngStyle]="{'border-right': !menuOpened && !showMainLayout ? '1px solid #E1E1E1' : 'none'}"
                                >
                                <mat-panel-title>
                                    <mat-icon *ngIf="showMainLayout" class="icon-light-sig064"></mat-icon>
                                    <img *ngIf="!showMainLayout" src="assets/images/correct-name/Waivers.svg" alt="Waivers">
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <mat-nav-list *ngIf="menuOpened" class="pt-0">
                                <ng-container *ngIf="profiles.readWaiverRequest && profiles.roleAgency && isWaiversLatamAvailable">
                                    <mat-list-item routerLink="agency/waivers/latam/home" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_COURTESY_REQUEST] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_COURTESY_REQUEST)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_COURTESY_REQUEST)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_COURTESY_REQUEST)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readWaiverRequest && profiles.roleAgency && isAllowedEmail && isWaiversLatamAvailable">
                                    <mat-list-item routerLink="agency/waivers/policies" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_POLICY_REQUEST] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_POLICY_REQUEST)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_POLICY_REQUEST)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_POLICY_REQUEST)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readPostSaleRequest && profiles.roleAgency && sessionInfo.role != 'MANUAL_MTZ'">
                                    <mat-list-item routerLink="agency/waivers/request" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_REQUEST] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_REQUEST)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_REQUEST)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_REQUEST)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readPostSaleRequest && profiles.roleAgent">
                                    <mat-list-item routerLink="agency/agent/waivers/request" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_REQUEST] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_REQUEST)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_REQUEST)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_REQUEST)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readWaiverPreSaleManagementAgency && profiles.roleAgency && sessionInfo.role != 'MANUAL_MTZ'">
                                    <mat-list-item routerLink="agency/waivers/presale" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_PRESALE_WAIVER_REQUEST] }" (click)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_REQUEST)" (mouseover)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_REQUEST)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_REQUEST)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readWaiverPreSaleManagementAgency && profiles.latamManagement">
                                    <mat-list-item routerLink="latam/waivers/presale" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_ONE_DEAL_REQUEST] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_ONE_DEAL_REQUEST)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_ONE_DEAL_REQUEST)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_ONE_DEAL_REQUEST)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readWaiverPreSaleManagementAgency && profiles.roleAgent">
                                    <mat-list-item routerLink="agency/agent/waivers/presale" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_PRESALE_WAIVER_REQUEST] }" (click)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_REQUEST)" (mouseover)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_REQUEST)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_REQUEST)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readWaiverPostSaleManagementAgency && profiles.roleAgency">
                                    <mat-list-item routerLink="agency/waivers/list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readWaiverPostSaleManagementAgency && profiles.roleAgent">
                                    <mat-list-item routerLink="agency/agent/waivers/list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)" disableRipple>
                                    </mat-list-item>
                                </ng-container>


                                <ng-container *ngIf="profiles.readWaiverPostSaleManagement">
                                    <mat-list-item routerLink="latam/waivers/list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readWaiverPostSaleManagement && sessionInfo.isLatamExternalSession()">
                                    <mat-list-item routerLink="outsourcing/waivers/list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readWaiverPreSaleManagementAgency && profiles.roleAgent ">
                                    <mat-list-item routerLink="agency/agent/waivers/pre-sale-list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_PRESALE_WAIVER_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readWaiverPreSaleManagementAgency && profiles.roleAgency">
                                    <mat-list-item routerLink="agency/waivers/pre-sale-list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_PRESALE_WAIVER_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readWaiverPreSaleManagmenet">
                                    <mat-list-item routerLink="latam/waivers/pre-sale-list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_PRESALE_WAIVER_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.waiverManualManagement">
                                  <mat-list-item routerLink="/latam/waivers/manuals" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_MANUAL_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_MANUAL_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_MANUAL_MANAGEMENT)" (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_MANUAL_MANAGEMENT)"
                                      disableRipple>
                                  </mat-list-item>
                              </ng-container>

                                <ng-container *ngIf="profiles.readAgencyReports && profiles.roleAgency">
                                    <mat-list-item routerLink="/agency/waivers/report" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_AGENCY_REPORT] }" (click)="subMenuItemHovered($event, SUBMENU_AGENCY_REPORT)" (mouseover)="subMenuItemHovered($event, SUBMENU_AGENCY_REPORT)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_AGENCY_REPORT)" disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readReports && !sessionInfo.isLatamExternalSession()">
                                    <mat-list-item routerLink="latam/waivers/report" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_LATAM_REPORT] }" (click)="subMenuItemHovered($event, SUBMENU_LATAM_REPORT)" (mouseover)="subMenuItemHovered($event, SUBMENU_LATAM_REPORT)" (mouseout)="subMenuItemHovered($event, SUBMENU_LATAM_REPORT)"
                                        disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="sessionInfo.isLatamExternalSession()">
                                    <mat-list-item routerLink="outsourcing/waivers/report" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_LATAM_REPORT] }" (click)="subMenuItemHovered($event, SUBMENU_LATAM_REPORT)" (mouseover)="subMenuItemHovered($event, SUBMENU_LATAM_REPORT)" (mouseout)="subMenuItemHovered($event, SUBMENU_LATAM_REPORT)"
                                        disableRipple>
                                    </mat-list-item>
                                </ng-container>

                                <ng-container *ngIf="profiles.readParameters">
                                    <mat-list-item [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_PARAMETERS] }" (click)="subMenuItemHovered($event, SUBMENU_PARAMETERS)" (mouseover)="subMenuItemHovered($event, SUBMENU_PARAMETERS)" (mouseout)="subMenuItemHovered($event, SUBMENU_PARAMETERS)"
                                        disableRipple>
                                    </mat-list-item>
                                </ng-container>
                            </mat-nav-list>
                        </mat-expansion-panel>
                    </ng-container>

                    <!-- Portal BDA  -->
                    <ng-container *ngIf="profiles.bdaManagment && !sessionInfo.isLoginLatamExternalSession()">
                        <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened === MENU_VENTA_PORTAL" (click)="openMenuFromPanel(MENU_VENTA_PORTAL)" (opened)="setPanelOpened(MENU_VENTA_PORTAL, MENU_VENTA_PORTAL)" (closed)="setPanelOpened(MENU_NONE, MENU_VENTA_PORTAL)"
                            class="fixed-expansion-panel">
                            <mat-expansion-panel-header [ngClass]="{ 'active':sidebarMenu.panelOpened === MENU_VENTA_PORTAL || (sidebarMenu.panelOpened === MENU_NONE && lastOpened === MENU_VENTA_PORTAL && !menuOpened), 'menu-item-hovered': menuItemsHovered[MENU_VENTA_PORTAL] }"
                                (mouseover)="menuItemHovered($event, MENU_VENTA_PORTAL)" (mouseout)="menuItemHovered($event, MENU_VENTA_PORTAL)" class="arrow-hidden">
                                <mat-panel-title>
                                    <mat-icon class="icon-light-sig039"></mat-icon>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <mat-nav-list *ngIf="menuOpened" class="pt-0">
                                <ng-container>
                                    <mat-list-item routerLink="agency/bda-portal/load-sales-report" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_VENTA_PORTAL] }" (click)="subMenuItemHovered($event, SUBMENU_VENTA_PORTAL)" (mouseover)="subMenuItemHovered($event, SUBMENU_VENTA_PORTAL)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_VENTA_PORTAL)" disableRipple>
                                    </mat-list-item>
                                </ng-container>


                            </mat-nav-list>
                        </mat-expansion-panel>
                    </ng-container>

                    <ng-container *ngIf="sessionInfo.isAgencySession() && sessionInfo.role != 'AGENT_MTZ_CWA'">
                        <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened === MENU_STATUSTICKET" (click)="openMenuFromPanel(MENU_AGENCIES)" (opened)="setPanelOpened(MENU_STATUSTICKET, MENU_STATUSTICKET)" (closed)="setPanelOpened(MENU_NONE, MENU_STATUSTICKET)" class="fixed-expansion-panel">
                            <mat-expansion-panel-header [ngClass]="{ 'active': sidebarMenu.panelOpened === MENU_STATUSTICKET, 'menu-item-hovered': menuItemsHovered[MENU_STATUSTICKET] }" (mouseover)="menuItemHovered($event, MENU_STATUSTICKET)" (mouseout)="menuItemHovered($event, MENU_STATUSTICKET)" class="arrow-hidden"
                            [ngStyle]="{'border-right': !menuOpened && !showMainLayout ? '1px solid #E1E1E1' : 'none'}"
                            >

                            <mat-panel-title>
                                <img *ngIf="!showMainLayout" src="assets/images/correct-name/StatusTicket.svg" alt="status-ticket">
                            </mat-panel-title>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                    </ng-container>

                    <ng-container *ngIf="sessionInfo.isAgencySession() && sessionInfo.role != 'AGENT_MTZ_CWA'">
                        <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened === MENU_CASE_FORM" (click)="openMenuFromPanel(MENU_CASE_FORM)" (opened)="setPanelOpened(MENU_CASE_FORM, MENU_CASE_FORM)" (closed)="setPanelOpened(MENU_NONE, MENU_CASE_FORM)">
                            <mat-expansion-panel-header [ngClass]="{ 'active': sidebarMenu.panelOpened === MENU_CASE_FORM, 'menu-item-hovered': menuItemsHovered[MENU_CASE_FORM] }" (mouseover)="menuItemHovered($event, MENU_CASE_FORM)" (mouseout)="menuItemHovered($event, MENU_CASE_FORM)" class="arrow-hidden"
                            [ngStyle]="{'border-right': !menuOpened && !showMainLayout ? '1px solid #E1E1E1' : 'none'}"
                            >
                                <mat-panel-title >
                                    <img *ngIf="!showMainLayout" src="assets/images/correct-name/DudasCaso.svg" alt="DudasCaso">
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                    </ng-container>

                    <ng-container *ngIf="profiles.readCorrectName">
                        <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened === MENU_CORRECTNAME" (click)="openMenuFromPanel(MENU_AGENCIES)" (opened)="setPanelOpened(MENU_CORRECTNAME, MENU_CORRECTNAME)" (closed)="setPanelOpened(MENU_NONE, MENU_CORRECTNAME)">
                            <mat-expansion-panel-header [ngClass]="{ 'active': sidebarMenu.panelOpened === MENU_CORRECTNAME, 'menu-item-hovered': menuItemsHovered[MENU_CORRECTNAME] }" (mouseover)="menuItemHovered($event, MENU_CORRECTNAME)" (mouseout)="menuItemHovered($event, MENU_CORRECTNAME)" class="arrow-hidden"
                            [ngStyle]="{'border-right': !menuOpened && !showMainLayout ? '1px solid #E1E1E1' : 'none'}"
                            >
                                <mat-panel-title style=" padding-left: 4px;">
                                    <img *ngIf="!showMainLayout" src="assets/images/correct-name/CorrectName.svg" alt="correctName">
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                    </ng-container>

                    <ng-container *ngIf="sessionInfo.isAgencySession() && profiles.readFareRegulation">
                      <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened === MENU_FARE_REGULATION" (click)="openMenuFromPanel(MENU_AGENCIES)" (opened)="setPanelOpened(MENU_FARE_REGULATION, MENU_FARE_REGULATION)" (closed)="setPanelOpened(MENU_NONE, MENU_FARE_REGULATION)">
                          <mat-expansion-panel-header [ngClass]="{ 'active': sidebarMenu.panelOpened === MENU_FARE_REGULATION, 'menu-item-hovered': menuItemsHovered[MENU_FARE_REGULATION] }" (mouseover)="menuItemHovered($event, MENU_FARE_REGULATION)" (mouseout)="menuItemHovered($event, MENU_FARE_REGULATION)" class="arrow-hidden"
                          [ngStyle]="{'border-right': !menuOpened && !showMainLayout ? '1px solid #E1E1E1' : 'none'}"
                          >
                              <mat-panel-title style=" padding-left: 4px;">
                                  <img *ngIf="!showMainLayout" src="assets/images/fare-regulation/fares.svg" alt="fareRegulation">
                              </mat-panel-title>
                          </mat-expansion-panel-header>
                      </mat-expansion-panel>
                  </ng-container>


                </div>
            </mat-accordion>
        </div>
        <mat-sidenav-container class="h-100" [ngStyle]="{ 'min-height.px': innerHeight }">
            <mat-sidenav *ngIf="sidebarMenu.panelOpened !== undefined && sidebarMenu.panelOpened != null"
                id="sidenav"
                #sidenav mode="push"
                [ngClass]="{'new-layout': !showMainLayout, 'default-layout': showMainLayout}"
                opened
            >
                <div class="row">
                    <div class="col text-right">
                        <button mat-icon-button id="collapseOpenedMenuButton" (click)="closeMenu()" class="arrow-green">
                <mat-icon>arrow_back</mat-icon>
              </button>
                    </div>
                </div>

                <mat-accordion>
                    <div class="header">
                        <ng-container *ngIf="profiles.latamManagement">
                            <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened === MENU_LATAM" (opened)="setPanelOpened(MENU_LATAM, MENU_LATAM)" (closed)="setPanelOpened(MENU_NONE, MENU_LATAM)">
                                <mat-expansion-panel-header id="menuGestionLatam" [ngClass]="{ 'active': sidebarMenu.panelOpened === MENU_LATAM, 'menu-item-hovered': menuItemsHovered[MENU_LATAM] }" (mouseover)="menuItemHovered($event, MENU_LATAM)" (mouseout)="menuItemHovered($event, MENU_LATAM)"
                                    class="arrow-class">
                                    <mat-panel-title>
                                        <span id="latamManagementSectionTitle">{{ 'menu.latamManagement' | translate }}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-nav-list *ngIf="menuOpened" class="pt-0">
                                    <ng-container *ngIf="profiles.readUserManagement">
                                        <mat-list-item id="listLatamUsersItem" routerLink="/latam/users/list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_LATAM_USERS_LIST] }" (click)="subMenuItemHovered($event, SUBMENU_LATAM_USERS_LIST)" (mouseover)="subMenuItemHovered($event, SUBMENU_LATAM_USERS_LIST)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_LATAM_USERS_LIST)" disableRipple>
                                            <a matLine routerLink="/latam/users/list" routerLinkActive="active" [ngClass]="{ 'active':sidebarMenu.subPanelOpened === SUBMENU_LATAM_USERS_LIST }" class="sidenav-item-link-sub">{{ 'menu.userManagement' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readFeedback">
                                        <mat-list-item id="feedbackModuleItem" routerLink="latam/feedback" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_LATAM_FEEDBACK] }" (click)="subMenuItemHovered($event, SUBMENU_LATAM_FEEDBACK)" (mouseover)="subMenuItemHovered($event, SUBMENU_LATAM_FEEDBACK)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_LATAM_FEEDBACK)" disableRipple>
                                            <a matLine routerLink="latam/feedback" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_LATAM_FEEDBACK }" class="sidenav-item-link-sub">{{ 'menu.feedback' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readBudgetManagement">
                                        <mat-list-item id="budgetManagementModuleItem" routerLink="latam/budget/management" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_LATAM_BUDGET_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_LATAM_BUDGET_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_LATAM_BUDGET_MANAGEMENT)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_LATAM_BUDGET_MANAGEMENT)" disableRipple>
                                            <a matLine routerLink="latam/budget/management" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_LATAM_BUDGET_MANAGEMENT }" class="sidenav-item-link-sub">{{ 'menu.budgetManagement' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.createBudgetBulkLoad">
                                        <mat-list-item id="budgetBulkLoadModuleItem" routerLink="latam/budget/bulk-load" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_LATAM_BUDGET_BULK_LOAD] }" (click)="subMenuItemHovered($event, SUBMENU_LATAM_BUDGET_BULK_LOAD)" (mouseover)="subMenuItemHovered($event, SUBMENU_LATAM_BUDGET_BULK_LOAD)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_LATAM_BUDGET_BULK_LOAD)" disableRipple>
                                            <a matLine routerLink="latam/budget/bulk-load" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_LATAM_BUDGET_BULK_LOAD }" class="sidenav-item-link-sub">{{ 'menu.budgetBulkLoad' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>
                                    <ng-container *ngIf="profiles.readWaiverAutomation">
                                        <mat-list-item id="waiverAutomationModuleItem" routerLink="latam/waiver-automation" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_AUTOMATION] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_AUTOMATION)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_AUTOMATION)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_AUTOMATION)" disableRipple>
                                            <a matLine routerLink="latam/waiver-automation" routerLinkActive="active" [ngClass]="{ 'active':sidebarMenu.subPanelOpened === SUBMENU_WAIVER_AUTOMATION }" class="sidenav-item-link-sub">{{ 'menu.waiversAutomation' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readWaiverTypeActivation">
                                        <mat-list-item id="waiverAutomationModuleItem" routerLink="latam/waiver-type-management" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_TYPE_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_TYPE_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_TYPE_MANAGEMENT)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_TYPE_MANAGEMENT)" disableRipple>
                                            <a matLine routerLink="latam/waiver-type-management" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_WAIVER_TYPE_MANAGEMENT }" class="sidenav-item-link-sub">{{ 'menu.typeWaiverActivation' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readFarediffManagement">
                                        <mat-list-item id="waiverFarediffManagementItem" routerLink="latam/waiver-farediff-management" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_FAREDIFF_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_FAREDIFF_MANAGEMENT)"
                                            (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_FAREDIFF_MANAGEMENT)" (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_FAREDIFF_MANAGEMENT)" disableRipple>
                                            <a matLine routerLink="latam/waiver-farediff-management" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_WAIVER_FAREDIFF_MANAGEMENT }" class="sidenav-item-link-sub">{{ 'menu.farediffManagement' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readBulkLoad">
                                        <mat-list-item id="waiverAutomationModuleItem" routerLink="latam/bulk-load" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_BULK_LOAD] }" (click)="subMenuItemHovered($event, SUBMENU_BULK_LOAD)" (mouseover)="subMenuItemHovered($event, SUBMENU_BULK_LOAD)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_BULK_LOAD)" disableRipple>
                                            <a matLine routerLink="latam/bulk-load" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_BULK_LOAD }" class="sidenav-item-link-sub">{{ 'menu.bulkLoad' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readUserProfile">
                                        <mat-list-item id="waiverUserProFileModuleItem" routerLink="latam/user-profile" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_USER_PROFILE] }" (click)="subMenuItemHovered($event, SUBMENU_USER_PROFILE)" (mouseover)="subMenuItemHovered($event, SUBMENU_USER_PROFILE)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_USER_PROFILE)" disableRipple>
                                            <a matLine routerLink="latam/user-profile" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_USER_PROFILE }" class="sidenav-item-link-sub">{{ 'menu.userProfile' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>
                                </mat-nav-list>
                            </mat-expansion-panel>
                        </ng-container>

                        <ng-container *ngIf="profiles.agencyManagment">
                            <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened === MENU_AGENCIES" (opened)="setPanelOpened(MENU_AGENCIES, MENU_AGENCIES)" (closed)="setPanelOpened(MENU_NONE, MENU_AGENCIES)">
                                <mat-expansion-panel-header [ngClass]="{ 'active': sidebarMenu.panelOpened === MENU_AGENCIES, 'menu-item-hovered': menuItemsHovered[MENU_AGENCIES] }" (mouseover)="menuItemHovered($event, MENU_AGENCIES)" (mouseout)="menuItemHovered($event, MENU_AGENCIES)"
                                    class="arrow-class"
                                >
                                    <mat-panel-title>
                                        <span *ngIf="infoUser.isLatamSession() || infoUser.isAgencySession()">{{ 'menu.agenciesManagement' | translate }}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-nav-list *ngIf="menuOpened" class="pt-0">
                                    <ng-container *ngIf="profiles.readActivateAgency">
                                        <mat-list-item routerLink="/latam/agencies/search" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_AGENCY_SEARCH] }" (click)="subMenuItemHovered($event, SUBMENU_AGENCY_SEARCH)" (mouseover)="subMenuItemHovered($event, SUBMENU_AGENCY_SEARCH)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_AGENCY_SEARCH)" disableRipple>
                                            <a matLine routerLink="/latam/agencies/search" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_AGENCY_SEARCH }" class="sidenav-item-link-sub">{{ 'menu.activateAgency' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readAgencyGroupProfile && profiles.roleAgency">
                                        <mat-list-item routerLink="/agency/group/profile" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_MATRIX_PROFILE] }" (click)="subMenuItemHovered($event, SUBMENU_MATRIX_PROFILE)" (mouseover)="subMenuItemHovered($event, SUBMENU_MATRIX_PROFILE)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_MATRIX_PROFILE)" disableRipple>
                                            <a matLine routerLink="/agency/group/profile" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_MATRIX_PROFILE }" class="sidenav-item-link-sub">{{ 'agencyGroupProfile' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readBranchOfficeProfile && profiles.roleBranchOffice">
                                        <mat-list-item routerLink="/agency/branch-office/profile" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_BRANCH_OFFICE_PROFILE] }" (click)="subMenuItemHovered($event, SUBMENU_BRANCH_OFFICE_PROFILE)" (mouseover)="subMenuItemHovered($event, SUBMENU_BRANCH_OFFICE_PROFILE)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_BRANCH_OFFICE_PROFILE)" disableRipple>
                                            <a matLine routerLink="/agency/branch-office/profile" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_BRANCH_OFFICE_PROFILE }" class="sidenav-item-link-sub">{{ 'branchOfficeProfile' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readAgencyRegister">
                                        <mat-list-item routerLink="/latam/agencies/register" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_AGENCY_REGISTER] }" (click)="subMenuItemHovered($event, SUBMENU_AGENCY_REGISTER)" (mouseover)="subMenuItemHovered($event, SUBMENU_AGENCY_REGISTER)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_AGENCY_REGISTER)" disableRipple>
                                            <a matLine routerLink="/latam/agencies/register" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_AGENCY_REGISTER }" class="sidenav-item-link-sub">{{ 'menu.agencyList' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readAgencyGroupManagement">
                                        <mat-list-item routerLink="/latam/agencies/group/list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_AGENCY_GROUP] }" (click)="subMenuItemHovered($event, SUBMENU_AGENCY_GROUP)" (mouseover)="subMenuItemHovered($event, SUBMENU_AGENCY_GROUP)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_AGENCY_GROUP)" disableRipple>
                                            <a matLine routerLink="/latam/agencies/group/list" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_AGENCY_GROUP }" class="sidenav-item-link-sub">{{ 'menu.agencyGroupManagement' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>
                                </mat-nav-list>
                            </mat-expansion-panel>
                        </ng-container>

                        <ng-container *ngIf="profiles.waiverManagment">
                            <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened === MENU_WAIVERS" (opened)="setPanelOpened(MENU_WAIVERS, MENU_WAIVERS)" (closed)="setPanelOpened(MENU_NONE, MENU_WAIVERS)">
                                <mat-expansion-panel-header [ngClass]="{ 'active': sidebarMenu.panelOpened === MENU_WAIVERS, 'menu-item-hovered': menuItemsHovered[MENU_WAIVERS] }" (mouseover)="menuItemHovered($event, MENU_WAIVERS)" (mouseout)="menuItemHovered($event, MENU_WAIVERS)"
                                    class="arrow-class">
                                    <mat-panel-title>
                                        <span id="waiverSectionTitle">{{ 'menu.waivers' | translate }}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-nav-list *ngIf="menuOpened" class="pt-0">
                                    <ng-container *ngIf="profiles.readWaiverRequest && isWaiversLatamAvailable ">
                                        <mat-list-item id="waiversModuleRequestItem" routerLink="agency/waivers/latam/home" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_COURTESY_REQUEST] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_COURTESY_REQUEST)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_COURTESY_REQUEST)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_COURTESY_REQUEST)" disableRipple>
                                            <a matLine routerLink="agency/waivers/latam/home" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_WAIVER_COURTESY_REQUEST }" class="sidenav-item-link-sub">{{ 'menu.waiverLatam' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readWaiverRequest && isWaiversLatamAvailable && isAllowedEmail">
                                        <mat-list-item id="waiversModuleRequestItem" routerLink="agency/waivers/policies" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_POLICY_REQUEST] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_POLICY_REQUEST)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_POLICY_REQUEST)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_POLICY_REQUEST)" disableRipple>
                                            <a matLine routerLink="agency/waivers/policies" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_WAIVER_POLICY_REQUEST }" class="sidenav-item-link-sub">{{ 'menu.waiverPolicy' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readPostSaleRequest && profiles.roleAgency && sessionInfo.role != 'MANUAL_MTZ'">
                                        <mat-list-item id="waiversModuleRequestItem" routerLink="agency/waivers/request" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_REQUEST] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_REQUEST)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_REQUEST)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_REQUEST)" disableRipple>
                                            <a matLine routerLink="agency/waivers/request" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_WAIVER_REQUEST }" class="sidenav-item-link-sub">{{ 'menu.waiverAfterSalesRequest' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readPostSaleRequest && profiles.roleAgent">
                                        <mat-list-item id="waiversModuleAgentRequestItem" routerLink="agency/agent/waivers/request" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_REQUEST] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_REQUEST)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_REQUEST)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_REQUEST)" disableRipple>
                                            <a matLine routerLink="agency/agent/waivers/request" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_WAIVER_REQUEST }" class="sidenav-item-link-sub">{{ 'menu.waiverAfterSalesRequest' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readPreSaleRequest && profiles.roleAgency && sessionInfo.role != 'MANUAL_MTZ'">
                                        <mat-list-item id="waiversModuleRequestItem" routerLink="agency/waivers/presale" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_PRESALE_WAIVER_REQUEST] }" (click)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_REQUEST)" (mouseover)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_REQUEST)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_REQUEST)" disableRipple>
                                            <a matLine routerLink="agency/waivers/presale" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_PRESALE_WAIVER_REQUEST }" class="sidenav-item-link-sub">{{ 'waiverPresaleRequest' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readPreSaleRequest && profiles.roleAgent">
                                        <mat-list-item id="waiversModuleAgentRequestItem" routerLink="agency/agent/waivers/presale" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_PRESALE_WAIVER_REQUEST] }" (click)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_REQUEST)" (mouseover)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_REQUEST)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_REQUEST)" disableRipple>
                                            <a matLine routerLink="agency/agent/waivers/presale" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_PRESALE_WAIVER_REQUEST }" class="sidenav-item-link-sub">{{ 'waiverPresaleRequest' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readPreSaleRequest && profiles.latamManagement">
                                        <mat-list-item id="waiversModuleRequestOneDealItem" routerLink="latam/waivers/presale" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_ONE_DEAL_REQUEST] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_ONE_DEAL_REQUEST)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_ONE_DEAL_REQUEST)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_ONE_DEAL_REQUEST)" disableRipple>
                                            <a matLine routerLink="latam/waivers/presale" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_WAIVER_ONE_DEAL_REQUEST }" class="sidenav-item-link-sub">{{ 'waiverOneDealRequest' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readWaiverPostSaleManagementAgency && profiles.roleAgency">
                                        <mat-list-item id="agencyWaiversModuleManagementItem" routerLink="agency/waivers/list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)" disableRipple>
                                            <a matLine routerLink="agency/waivers/list" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_WAIVER_MANAGEMENT }" class="sidenav-item-link-sub">{{ 'menu.afterSaleManagement' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readWaiverPostSaleManagementAgency && profiles.roleAgent">
                                        <mat-list-item id="agentWaiversModuleManagementItem" routerLink="agency/agent/waivers/list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)" disableRipple>
                                            <a matLine routerLink="agency/agent/waivers/list" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_WAIVER_MANAGEMENT }" class="sidenav-item-link-sub">{{ 'menu.afterSaleManagement' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readWaiverPostSaleManagement && sessionInfo.isLatamSession()">
                                        <mat-list-item id="latamWaiversModuleManagementItem" routerLink="latam/waivers/list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)" disableRipple>
                                            <a matLine routerLink="latam/waivers/list" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_WAIVER_MANAGEMENT }" class="sidenav-item-link-sub">{{ 'menu.afterSaleManagement' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readWaiverPostSaleManagement && sessionInfo.isLatamExternalSession()">
                                        <mat-list-item id="latamWaiversModuleManagementItem" routerLink="outsourcing/waivers/list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_MANAGEMENT)" disableRipple>
                                            <a matLine routerLink="outsourcing/waivers/list" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_WAIVER_MANAGEMENT }" class="sidenav-item-link-sub">{{ 'menu.afterSaleManagement' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readWaiverPreSaleManagementAgency && profiles.roleAgent">
                                        <mat-list-item [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_PRESALE_WAIVER_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)" disableRipple>
                                            <a matLine routerLink="agency/agent/waivers/pre-sale-list" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_PRESALE_WAIVER_MANAGEMENT }" class="sidenav-item-link-sub">{{ 'menu.preSaleManagement' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readWaiverPreSaleManagementAgency && profiles.roleAgency">
                                        <mat-list-item id="agencyWaiversModuleManagementItem" routerLink="agency/waivers/pre-sale-list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_PRESALE_WAIVER_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)"
                                            (mouseover)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)" (mouseout)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)" disableRipple>
                                            <a matLine routerLink="agency/waivers/pre-sale-list" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_PRESALE_WAIVER_MANAGEMENT }" class="sidenav-item-link-sub">{{ 'menu.preSaleManagement' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readWaiverPreSaleManagmenet">
                                        <mat-list-item id="latamWaiversModuleManagementItem" routerLink="latam/waivers/pre-sale-list" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_PRESALE_WAIVER_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)"
                                            (mouseover)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)" (mouseout)="subMenuItemHovered($event, SUBMENU_PRESALE_WAIVER_MANAGEMENT)" disableRipple>
                                            <a matLine routerLink="latam/waivers/pre-sale-list" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_PRESALE_WAIVER_MANAGEMENT }" class="sidenav-item-link-sub">{{ 'menu.preSaleManagement' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.waiverManualManagement">
                                        <mat-list-item id="waiverUserProFileModuleItem" routerLink="latam/waivers/manuals" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_WAIVER_MANUAL_MANAGEMENT] }" (click)="subMenuItemHovered($event, SUBMENU_WAIVER_MANUAL_MANAGEMENT)" (mouseover)="subMenuItemHovered($event, SUBMENU_WAIVER_MANUAL_MANAGEMENT)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_WAIVER_MANUAL_MANAGEMENT)" disableRipple>
                                            <a matLine routerLink="latam/waivers/manuals" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_WAIVER_MANUAL_MANAGEMENT }" class="sidenav-item-link-sub">{{ 'menu.userManual' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>
                                    <ng-container *ngIf="profiles.readAgencyReports && profiles.roleAgency">
                                        <mat-list-item id="reportModuleItem" routerLink="/agency/waivers/report" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_AGENCY_REPORT] }" (click)="subMenuItemHovered($event, SUBMENU_AGENCY_REPORT)" (mouseover)="subMenuItemHovered($event, SUBMENU_AGENCY_REPORT)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_AGENCY_REPORT)" disableRipple>
                                            <a matLine routerLink="/agency/waivers/report" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_AGENCY_REPORT }" class="sidenav-item-link-sub">{{ 'reports' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readReports && !sessionInfo.isLatamExternalSession()">
                                        <mat-list-item id="latamReportModuleItem" routerLink="latam/waivers/report" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_LATAM_REPORT]}" (click)="subMenuItemHovered($event, SUBMENU_LATAM_REPORT)" (mouseover)="subMenuItemHovered($event, SUBMENU_LATAM_REPORT)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_LATAM_REPORT)" disableRipple>
                                            <a matLine routerLink="latam/waivers/report" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_LATAM_REPORT }" class="sidenav-item-link-sub">{{ 'reports' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="sessionInfo.isLatamExternalSession()">
                                        <mat-list-item id="latamReportModuleItem" routerLink="outsourcing/waivers/report" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_LATAM_REPORT]}" (click)="subMenuItemHovered($event, SUBMENU_LATAM_REPORT)" (mouseover)="subMenuItemHovered($event, SUBMENU_LATAM_REPORT)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_LATAM_REPORT)" disableRipple>
                                            <a matLine routerLink="outsourcing/waivers/report" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_LATAM_REPORT }" class="sidenav-item-link-sub">{{ 'reports' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>

                                    <ng-container *ngIf="profiles.readParameters">
                                        <mat-list-item [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_PARAMETERS] }" (click)="subMenuItemHovered($event, SUBMENU_PARAMETERS)" (mouseover)="subMenuItemHovered($event, SUBMENU_PARAMETERS)" (mouseout)="subMenuItemHovered($event, SUBMENU_PARAMETERS)"
                                            disableRipple>
                                            <a matLine href="{{ urlparameters }}" target="_blank" rel="noopener" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_PARAMETERS }" class="sidenav-item-link-sub">{{ 'parameters' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>
                                </mat-nav-list>
                            </mat-expansion-panel>
                        </ng-container>
                        <!-- portal BDA -->
                        <ng-container *ngIf="profiles.bdaManagment && !sessionInfo.isLoginLatamExternalSession()">
                            <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened === MENU_VENTA_PORTAL" (opened)="setPanelOpened(MENU_VENTA_PORTAL, MENU_VENTA_PORTAL)" (closed)="setPanelOpened(MENU_NONE, MENU_VENTA_PORTAL)">
                                <mat-expansion-panel-header [ngClass]="{ 'active': sidebarMenu.panelOpened === MENU_VENTA_PORTAL, 'menu-item-hovered': menuItemsHovered[MENU_VENTA_PORTAL] }" (mouseover)="menuItemHovered($event, MENU_VENTA_PORTAL)" (mouseout)="menuItemHovered($event, MENU_VENTA_PORTAL)"
                                    class="arrow-class">
                                    <mat-panel-title>
                                        <span id="">{{ 'menu.portalBda' | translate }}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-nav-list *ngIf="menuOpened" class="pt-0">
                                    <ng-container>
                                        <mat-list-item id="" routerLink="agency/bda-portal/load-sales-report" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_VENTA_PORTAL] }" (click)="subMenuItemHovered($event, SUBMENU_VENTA_PORTAL)" (mouseover)="subMenuItemHovered($event, SUBMENU_VENTA_PORTAL)"
                                            (mouseout)="subMenuItemHovered($event, SUBMENU_VENTA_PORTAL)" disableRipple>
                                            <a matLine routerLink="agency/bda-portal/load-sales-report" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_VENTA_PORTAL }" class="sidenav-item-link-sub">{{ 'menu.loadingSalesReport' | translate }}</a>
                                        </mat-list-item>
                                    </ng-container>
                                </mat-nav-list>
                            </mat-expansion-panel>
                        </ng-container>

                        <!-- StatusTicket -->
                        <ng-container *ngIf="sessionInfo.isAgencySession() && sessionInfo.role != 'AGENT_MTZ_CWA'">
                            <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened === MENU_STATUSTICKET" (opened)="setPanelOpened(MENU_STATUSTICKET, MENU_STATUSTICKET)" (closed)="setPanelOpened(MENU_NONE, MENU_STATUSTICKET)">
                                <mat-expansion-panel-header [ngClass]="{ 'active': sidebarMenu.panelOpened === MENU_STATUSTICKET, 'menu-item-hovered': menuItemsHovered[MENU_STATUSTICKET] }" (mouseover)="menuItemHovered($event, MENU_STATUSTICKET)" (mouseout)="menuItemHovered($event, MENU_STATUSTICKET)" class="arrow-class">

                                    <mat-panel-title routerLink="agency/status-ticket/search">
                                        {{ 'statusTicket.name' | translate }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                            </mat-expansion-panel>
                        </ng-container>

                        <!-- StatusTicket -->
                    <ng-container *ngIf="sessionInfo.isLatamSession() && !sessionInfo.isUserBdaLatam()">
                            <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened === MENU_STATUSTICKET" (opened)="setPanelOpened(MENU_STATUSTICKET, MENU_STATUSTICKET)" (closed)="setPanelOpened(MENU_NONE, MENU_STATUSTICKET)">
                                <mat-expansion-panel-header [ngClass]="{ 'active': sidebarMenu.panelOpened === MENU_STATUSTICKET, 'menu-item-hovered': menuItemsHovered[MENU_STATUSTICKET] }" (mouseover)="menuItemHovered($event, MENU_STATUSTICKET)" (mouseout)="menuItemHovered($event, MENU_STATUSTICKET)">
                                    <mat-panel-title routerLink="latam/status-ticket/search">
                                        {{ 'statusTicket.name' | translate }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                            </mat-expansion-panel>
                        </ng-container>

                        <!-- StatusTicket -->
                        <ng-container *ngIf="sessionInfo.isLatamExternalSession()">
                            <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened === MENU_STATUSTICKET" (opened)="setPanelOpened(MENU_STATUSTICKET, MENU_STATUSTICKET)" (closed)="setPanelOpened(MENU_NONE, MENU_STATUSTICKET)">
                                <mat-expansion-panel-header [ngClass]="{ 'active': sidebarMenu.panelOpened === MENU_STATUSTICKET, 'menu-item-hovered': menuItemsHovered[MENU_STATUSTICKET] }" (mouseover)="menuItemHovered($event, MENU_STATUSTICKET)" (mouseout)="menuItemHovered($event, MENU_STATUSTICKET)">
                                    <mat-panel-title routerLink="outsourcing/status-ticket/search">
                                        {{ 'statusTicket.name' | translate }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                            </mat-expansion-panel>
                        </ng-container>

                        <ng-container *ngIf="sessionInfo.isAgencySession() && sessionInfo.role != 'AGENT_MTZ_CWA'">
                            <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened === MENU_CASE_FORM" (opened)="setPanelOpened(MENU_CASE_FORM, MENU_CASE_FORM)" (closed)="setPanelOpened(MENU_NONE, MENU_CASE_FORM)">
                                <mat-expansion-panel-header [ngClass]="{ 'active': sidebarMenu.panelOpened === MENU_CASE_FORM, 'menu-item-hovered': menuItemsHovered[MENU_CASE_FORM] }" (mouseover)="menuItemHovered($event, MENU_CASE_FORM)" (mouseout)="menuItemHovered($event, MENU_CASE_FORM)" class="arrow-class">
                                    <mat-panel-title routerLink="agency/form-cases">
                                        {{ 'menu.dudasCasos' | translate }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                            </mat-expansion-panel>
                        </ng-container>


                        <ng-container  *ngIf="profiles.readCorrectName" >
                            <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened === MENU_CORRECTNAME" (opened)="setPanelOpened(MENU_CORRECTNAME, MENU_CORRECTNAME)" (closed)="setPanelOpened(MENU_NONE, MENU_CORRECTNAME)">
                                <mat-expansion-panel-header [ngClass]="{ 'active': sidebarMenu.panelOpened === MENU_CORRECTNAME, 'menu-item-hovered': menuItemsHovered[MENU_CORRECTNAME] }" (mouseover)="menuItemHovered($event, MENU_CORRECTNAME)" (mouseout)="menuItemHovered($event, MENU_CORRECTNAME)" class="arrow-class">
                                    <mat-panel-title routerLink="agency/correct-name">
                                        {{ 'correctName.correctName' | translate }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                            </mat-expansion-panel>
                        </ng-container>

                        <ng-container *ngIf="sessionInfo.isAgencySession() && profiles.readFareRegulation">
                          <div class="mat-expansion-panel" id="sidenav-menu">
                            <div class="mat-expansion-panel-header"
                                [ngClass]="{
                                    'active': router.isActive('/agency/fare-regulation', true),
                                    'menu-item-hovered': menuItemsHovered[MENU_FARE_REGULATION]
                                }"
                                (mouseover)="menuItemHovered($event, MENU_FARE_REGULATION)"
                                (mouseout)="menuItemHovered($event, MENU_FARE_REGULATION)">
                                <span class="mat-expansion-panel-header-title" routerLink="agency/fare-regulation">
                                    {{ 'fareRegulation.moduleName' | translate}}
                                </span>
                            </div>
                        </div>
                      </ng-container>

                    <!-- BdaPortal-Reprocess -->
                    <ng-container *ngIf="sessionInfo.isLatamSession() && !sessionInfo.isLoginLatamExternalSession() && profiles.reprocessBdaLT">
                        <mat-expansion-panel id="sidenav-menu" [expanded]="sidebarMenu.panelOpened ===MENU_VENTA_PORTAL" (opened)="setPanelOpened(MENU_VENTA_PORTAL, MENU_VENTA_PORTAL)" (closed)="setPanelOpened(MENU_NONE, MENU_VENTA_PORTAL)">
                            <mat-expansion-panel-header [ngClass]="{ 'active': sidebarMenu.panelOpened === MENU_VENTA_PORTAL, 'menu-item-hovered': menuItemsHovered[MENU_VENTA_PORTAL] }" (mouseover)="menuItemHovered($event, MENU_VENTA_PORTAL)" (mouseout)="menuItemHovered($event, MENU_VENTA_PORTAL)"
                            class="arrow-class">
                            <mat-panel-title>
                                <span id="">{{ 'menu.portalBda' | translate }}</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                            <mat-nav-list *ngIf="menuOpened" class="pt-0">
                                <ng-container>
                                    <mat-list-item id="" routerLink="latam/bda-portal/download-sales-report" [ngClass]="{ 'menu-item-hovered': subMenuItemsHovered[SUBMENU_VENTA_PORTAL] }" (click)="subMenuItemHovered($event, SUBMENU_VENTA_PORTAL)" (mouseover)="subMenuItemHovered($event, SUBMENU_VENTA_PORTAL)"
                                        (mouseout)="subMenuItemHovered($event, SUBMENU_VENTA_PORTAL)" disableRipple>
                                        <a matLine routerLink="latam/bda-portal/download-sales-report" routerLinkActive="active" [ngClass]="{ 'active': sidebarMenu.subPanelOpened === SUBMENU_VENTA_PORTAL }" class="sidenav-item-link-sub">{{ 'menu.reprocessSalesReport' | translate }}</a>
                                    </mat-list-item>
                                </ng-container>


                            </mat-nav-list>
                        </mat-expansion-panel>
                    </ng-container>
                    </div>

                </mat-accordion>
            </mat-sidenav>

            <mat-sidenav-content class="sidenav-content" [ngClass]="{'new-layout': !showMainLayout}">

                <div *ngIf="!showMainLayout && isNewWaiverModule" class="header-waivers-latam-menu">
                    <div class="waivers-latam-menu-left">
                        <button *ngIf="sessionInfo.role != 'MANUAL_MTZ' && profiles.createWaiverRequest" class="menu-item" [routerLink]="waiverRequestRoute.waiverRequest" routerLinkActive="active-menu-item">
                            <span>{{ 'waiverLatam.waiverRequest' | translate }}</span>
                        </button>

                        <button *ngIf="profiles.readWaiverManagementAgency" class="menu-item" [routerLink]="waiverRequestRoute.waiverList" routerLinkActive="active-menu-item">
                            <span>{{ 'waiverLatam.waiverManagement' | translate }}</span>
                        </button>

                         <button class="menu-item" [routerLink]="waiverRequestRoute.waiverManual" routerLinkActive="active-menu-item">
                            <span>{{ 'waiverLatam.waiverManuals' | translate }}</span>
                        </button>
                    </div>
                </div>
                <router-outlet></router-outlet>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </ng-container>
</ng-container>
