import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

import { AgencyCSV } from '../../../models/agencycsv.model';
import { AgencyPage } from '../../../models/agency-page.model';
import { Profile } from '../../../models/profile.model';
import { SessionInfo } from '../../../models/SessionInfo.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';
import { UserAgencysCsvElement } from '../../../models/UserAgencysCsvElement.model';

import { AgencyService } from '../../../services/agency.service';
import { ProfilingService } from '../../../services/profiling.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { UserService } from './../../../services/user.service';
import { MessageErrorService } from './../../../services/message-error.service';


@Component({
  selector: 'app-register-agency',
  templateUrl: './register-agency.component.html',
  styleUrls: ['./register-agency.component.css']
})
export class RegisterAgencyComponent implements OnInit, OnChanges {
  agenciesDataFound = false;
  loadAgenciesNoDataError = false;
  loading = false;
  downloadAgenciesList = false;
  downloadAgenciesListSuccessfully = false;
  downloadAgenciesUserList = false;
  downloadAgenciesUserListSuccessfully = false;

  selectedRowIndex: number = -1;

  activeFilter = new UntypedFormControl();
  agencyCSV: AgencyCSV[] = [];
  cityFilter = new UntypedFormControl();
  dataSource = new MatTableDataSource([]);
  displayedColumns = ['name', 'iata', 'tier', 'city', 'executive', 'status', 'marketName', 'actions'];
  executiveFilter = new UntypedFormControl();
  iataFilter = new UntypedFormControl();
  infoUser$: Promise<SessionInfo> = this.sessionInfoService.getSessionInfoAsPromise();
  marketNameFilter = new UntypedFormControl();
  nameFilter = new UntypedFormControl();
  pipe = new DatePipe('en-US');
  profiles = new Profile();
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_AGENCIES, SidebarMenuModel.SUBMENU_AGENCY_REGISTER);
  statusSelected = new UntypedFormControl();
  tierFilter = new UntypedFormControl();
  userAgencyCsv: UserAgencysCsvElement[] = [];

  private maxLength = 10;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loadingAgenciesDataError = (index: number, item: { errorLoadingData: boolean }) => item.errorLoadingData;
  noAgenciesDataFound = (index: number, item: { noData: boolean }) => item.noData;

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private agencyService: AgencyService,
    private messageErrorService: MessageErrorService,
    private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService,
    private userService: UserService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reloadUsers.currentValue) {
      this.getAgencies(0, this.paginator ? this.paginator.pageSize : this.maxLength);
    }
  }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.initFilters();
    this.getAgencies(0, this.maxLength);
  }

  private initFilters() {
    this.nameFilter.setValue('');
    this.iataFilter.setValue('');
    this.cityFilter.setValue('');
    this.tierFilter.setValue('');
    this.executiveFilter.setValue('');
    this.statusSelected.setValue('');
    this.marketNameFilter.setValue('');
  }

  private getAgencies(pageIndex: number, pageSize: number) {
    this.loading = true;
    this.agenciesDataFound = false;
    this.loadAgenciesNoDataError = false;
    let agency = new  AgencyPage(
      0,
      this.nameFilter.value,
      this.iataFilter.value,
      this.cityFilter.value,
      this.tierFilter.value,
      this.executiveFilter.value,
      this.statusSelected.value,
      this.marketNameFilter.value
    );

    this.agencyService.getAgencyListActivePos(pageIndex, pageSize, agency).subscribe({
      next: data => {
        if (data.agencies.length === 0) {
          this.dataSource.data = [{ noData: true }];
        } else {
          this.agenciesDataFound = true;

          setTimeout(() => {
            this.paginator.length = data.pagination.totalElements;
            this.paginator.pageIndex = data.pagination.pageIndex;
            this.paginator.pageSize = data.pagination.pageSize;
            this.dataSource.data = data.agencies;
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (dataAux: AgencyPage, header) => {
              switch (header) {
                case 'tier': return dataAux.agencyTierName;
                case 'status': return dataAux.status;
                case 'marketName': return dataAux.marketName;
                default:
                  return (!isNaN(dataAux[header]) || dataAux[header] == null) ? dataAux[header] : dataAux[header].toLocaleLowerCase();
              }
            };
          });
        }

        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.loadAgenciesNoDataError = true;
        this.dataSource.data = [{ errorLoadingData: true }];
        this.messageErrorService.sendError(error, 'error.loadingAgencies');

        this.loading = false;
        console.log('error', error);
      }
  });
  }

  downloadAgenciesListCSV() {
    this.downloadAgenciesList = true;
    this.downloadAgenciesListSuccessfully = false;

    this.agencyService.getAgencyListByPos().subscribe({
      next: data => {
        this.agencyCSV = data;
        let options = {
          fieldSeparator: ';',
          headers: [
            'ID',
            this.translateService.instant('field.iata'),
            this.translateService.instant('field.name'),
            this.translateService.instant('field.admcId'),
            this.translateService.instant('field.admcBranchId'),
            this.translateService.instant('field.officeId'),
            this.translateService.instant('field.pcc'),
            this.translateService.instant('field.agencyGroupId'),
            this.translateService.instant('field.agencyGroup'),
            this.translateService.instant('field.marketId'),
            this.translateService.instant('field.market'),
            this.translateService.instant('field.city'),
            this.translateService.instant('field.country'),
            this.translateService.instant('field.accountManager'),
            this.translateService.instant('field.active'),
            this.translateService.instant('field.piaCode'),
            this.translateService.instant('field.piaAgencyType')
          ]
        };
        // tslint:disable-next-line: no-unused-expression
        new AngularCsv(this.agencyCSV, 'Agencies_LT' + this.pipe.transform(new Date, 'ddMMyyyy'), options);
        this.downloadAgenciesList = false;
        this.downloadAgenciesListSuccessfully = true;
      },
      error: (error: HttpErrorResponse) => {
        this.downloadAgenciesList = false;
        this.messageErrorService.sendError(error, 'error.loadingAgencies');

        console.log('error', error);
      }
  });
  }

  downloadAgenciesUserListCSV() {
    this.downloadAgenciesUserList = true;
    this.downloadAgenciesUserListSuccessfully = false;

    this.userService.getActiveAgenciesUsers().subscribe({
      next: data => {
        this.userAgencyCsv = data;
        let options = {
          fieldSeparator: ';',
          headers: [
            this.translateService.instant('field.name'),
            this.translateService.instant('field.surname'),
            this.translateService.instant('field.email'),
            this.translateService.instant('field.active'),
            this.translateService.instant('field.pos'),
            this.translateService.instant('field.agencyId'),
            this.translateService.instant('field.agencyName'),
            this.translateService.instant('field.roles'),
            this.translateService.instant('field.piaCode'),
            this.translateService.instant('field.agencyGroupId'),
            this.translateService.instant('field.agencyGroup'),
            this.translateService.instant('field.branchOfficeId'),
            this.translateService.instant('field.branchOffice')
          ],
        };
        // tslint:disable-next-line: no-unused-expression
        new AngularCsv(this.userAgencyCsv, 'export_AgencysUsers_' + this.pipe.transform(new Date, 'ddMMyyyy'), options);
        this.downloadAgenciesUserList = false;
        this.downloadAgenciesUserListSuccessfully = true;
      },
      error: (error: HttpErrorResponse) => {
        this.downloadAgenciesUserList = false;
        this.messageErrorService.sendError(error, 'error.loadingAgencies');

        console.log('error', error);
      }
  });
  }

  /**
   * Function to open the component with the profile of the agency 'ProfileAgencyComponent'
   */
  profileAgency(agencyId: number, active: number) {
    this.router.navigate(['../profile'], { relativeTo: this.activatedRoute, queryParams: { agencyId: agencyId, active: active } });
  }

  searchAgency() {
    this.router.navigate(['../search'], { relativeTo: this.activatedRoute });
  }

  reactivateAgency(agencyId: number) {
    this.agencyService.reactivateAgency(agencyId).subscribe({
      next: agency => {
        if (agency) {
          this.getAgencies(0, this.paginator ? this.paginator.pageSize : this.maxLength);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingAgencies');

        console.log('error', error);
      }
  });
  }

  deactivateAgency(agencyId: number) {
    this.agencyService.deactivateAgency(agencyId).subscribe({
      next: agency => {
        if (agency) {
          this.getAgencies(0, this.paginator ? this.paginator.pageSize : this.maxLength);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingAgencies');

        console.log('error', error);
      }
  });
  }

  highlightRow(event: Event, rowIndex: number) {
    this.selectedRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  pageEvent(event: PageEvent): void {
    this.getAgencies(event.pageIndex, event.pageSize);
  }

  searchAgencies() {
    this.dataSource = new MatTableDataSource();
    this.getAgencies(0, this.paginator ? this.paginator.pageSize : this.maxLength);
  }

  refreshAgencies(): void {
    this.initFilters();
    this.getAgencies(0, this.paginator ? this.paginator.pageSize : this.maxLength);
  }
}
