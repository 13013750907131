import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog,  MatDialogConfig } from '@angular/material/dialog';

import { BudgetResume } from '../../models/budget-resume.model';
import { MonthlyBudgetAgencyGroup } from '../../models/monthly-budget-agency-group.model';
import { MonthlyBudgetBranchOffice } from '../../models/monthly-budget-branch-office.model';

import { DialogBudgetComponent } from './dialog-budget/dialog-budget.component';

import { BudgetService } from '../../services/budget.service';
import { ConstantsService } from '../../services/constants.service';


@Component({
  selector: 'app-budget-popup',
  templateUrl: './budget-popup.component.html',
  styleUrls: ['./budget-popup.component.css']
})
export class BudgetPopupComponent implements OnChanges {
  loading = false;
  showBudget = false;

  language = 'pt';

  monthlyBudgetAgencyGroup: MonthlyBudgetAgencyGroup;
  monthlyBudgetBranchOffice: MonthlyBudgetBranchOffice;

  @Input() groupId: number;
  @Input() branchOfficeId: number;
  @Input() reload: boolean;

  constructor(
    private dialog: MatDialog,
    private budgetService: BudgetService,
    private constantsService: ConstantsService) { }



  ngOnChanges(changes: SimpleChanges) {
    if (this.branchOfficeId) {
      this.getBranchBudget(changes);
    } else {
      this.getAgencyGroupBudget(changes)
    }
  }

  private getBranchBudget(changes: SimpleChanges): void {
    if (changes.branchOfficeId) {
      if (changes.branchOfficeId.currentValue) {
        this.getBudgetPresaleByBranchOffice(changes.branchOfficeId.currentValue);
      }
    } else if (changes.reload) {
      if (changes.reload.currentValue) {
        this.getBudgetPresaleByBranchOffice(this.branchOfficeId);
      }
    }
  }

  private getAgencyGroupBudget(changes: SimpleChanges): void {
    if (changes.groupId) {
      if (changes.groupId.currentValue) {
        this.getMonthlyPresaleBudgetsByGroup(changes.groupId.currentValue);
      }
    } else if (changes.reload) {
      if (changes.reload.currentValue) {
        this.getMonthlyPresaleBudgetsByGroup(this.groupId);
      }
    }
  }

  openDialogBudget(): void {
    if (!this.branchOfficeId) {
      this.getBudgetResume(this.constantsService.presale.id, this.monthlyBudgetAgencyGroup.id);
    }
  }

  getMonthlyPresaleBudgetsByGroup(groupId: number) {
    this.showBudget = false;

    this.budgetService.getMonthlyPresaleBudgetsByGroup(groupId).subscribe(
      res => {
        if (res) {
          this.monthlyBudgetAgencyGroup = res;
          this.showBudget = this.monthlyBudgetAgencyGroup.agencyGroup.showPresaleBudget;
        }
      },
      (error: HttpErrorResponse) => {
        this.showBudget = false;
        console.log('error', error);
      }
    );
  }

  getBudgetPresaleByBranchOffice(branchOfficeId: number) {
    this.showBudget = false;

    this.budgetService.getBudgetPresaleByBranchOffice(branchOfficeId, true).subscribe(
      res => {
        if (res) {
          this.monthlyBudgetBranchOffice = res;
          this.showBudget = this.monthlyBudgetBranchOffice.agencyBranchOffice.showPresaleBudget;
        }
      },
      (error: HttpErrorResponse) => {
        this.showBudget = false;
       // console.log('error', error);
      }
    );
  }

  getBudgetResume(budgetTypeId: number, agencyGroupBudgetId: number) {
    this.loading = true;

    this.budgetService.getBudgetResume(budgetTypeId, agencyGroupBudgetId).subscribe(
      res => {
        if (res.length > 0) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.autoFocus = true;
          dialogConfig.height = '40vh';
          dialogConfig.width = '30vw';
          dialogConfig.panelClass = 'small-modal';
          const dialogRef = this.dialog.open(DialogBudgetComponent, dialogConfig);
          dialogRef.componentInstance.dataSource.data = res;
        }

        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        console.log('error', error);
      }
    );
  }

}
