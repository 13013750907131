import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {  MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ShareModule } from '../share-module/share.module';

import { CreateEditUserComponent } from './create-edit-user/create-edit-user.component';
import { UserListComponent } from './user-list/user-list.component';

import { AgencyBranchOfficeService } from './../../services/agency-branch-office.service';
import { AgencyPiaService } from './../../services/agency-pia.service';
import { UserService } from '../../services/user.service';


@NgModule({
  declarations: [
    CreateEditUserComponent,
    UserListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    ReactiveFormsModule,
    ShareModule,
    MatAutocompleteModule
  ],
  exports: [
    UserListComponent
  ],
  providers: [
    AgencyBranchOfficeService,
    AgencyPiaService,
    UserService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter
    }
  ]
})
export class UserModule { }
