import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Filter } from '../../interfaces/filter.interface';
import { Profile } from '../../models/profile.model';
import { DetailListWaiverAgencyComponent } from '../../modules/waiver-postsale-module/detail-list-waiver-agency/detail-list-waiver-agency.component';
import { MessageErrorService } from '../../services/message-error.service';
import { ProfilingService } from '../../services/profiling.service';
import { WaiverPostsaleService } from '../../services/waiver-postsale.service';

@Component({
  selector: 'app-waiver-list-postsale',
  templateUrl: './waiver-list-postsale.component.html',
  styleUrls: ['./waiver-list-postsale.component.css'],
})
export class WaiverListPostsaleComponent implements OnInit, OnChanges {
  @Input() displayedColumns: string[];
  @Input() tab: string;
  @Input() user: string;

  loading = false;
  noWaivers = false;
  dataSource = new MatTableDataSource();
  selectedRowIndex = -1;
  profiles = new Profile();
  pageSize: number = 10;
  pageNumber: number = 0;
  totalElements: number = 0;

  noWaiversFound = (index: number, item: { noData: boolean }) => item.noData;
  errorLoadingWaivers = (index: number, item: { errorLoadingData: boolean }) =>
    item.errorLoadingData;

  @ViewChild(MatSort) sort: MatSort;

  params: HttpParams = this.initParams();

  filter: Filter = {
    id: true,
    agency: false,
    applicant: true,
    statusDateFrom: true,
    statusDateTo: true,
    flightDateFrom: false,
    flightDateTo: false,
    executive: true,
    pnr: true,
    type: true,
    valueWaiver: false,
  };

  constructor(
    private profilesService: ProfilingService,
    private messageErrorService: MessageErrorService,
    private waiverPostsaleService: WaiverPostsaleService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.profiles = this.profilesService.getProfile();
    this.getWaiver(this.tab);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.pageSize === 0) {
      this.pageSize = 10;
    }

    if (
      !this.isFirstChange(changes.searchApproved) &&
      changes?.searchApproved?.currentValue
    ) {
      this.getWaiver('aprobados');
    } else if (
      !this.isFirstChange(changes.searchRejected) &&
      changes?.searchRejected?.currentValue
    ) {
      this.getWaiver('rechazados');
    } else if (
      !this.isFirstChange(changes.searchPending) &&
      changes?.searchPending?.currentValue
    ) {
      this.getWaiver('pendientes');
    } else if (
      !this.isFirstChange(changes.searchPendingAgency) &&
      changes?.searchRejected?.currentValue
    ) {
      this.getWaiver('pendientes-agencia');
    }
  }

  private isFirstChange(change: SimpleChange): boolean {
    return change?.firstChange;
  }

  getWaiver(statusName: string): void {
    this.loading = true;

    this.waiverPostsaleService
      .getWaiver(this.pageSize, this.pageNumber, this.params, statusName, this.user)
      .subscribe({
        next: (data) => {
          if (data.content.length === 0) {
            this.cleanData();
            this.dataSource.data = [{ noData: true }];
          } else {
            this.noWaivers = false;
            this.pageSize = data.size;
            this.pageNumber = data.number;
            this.totalElements = data.totalElements;
            this.dataSource.data = data.content;
            this.dataSource.sort = this.sort;
          }

          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.cleanData();
          this.dataSource.data = [{ errorLoadingData: true }];

          const statusToErrorMessage = {
            aprobados: 'error.loadingAprovedWaivers',
            rechazados: 'error.loadingDeniedWaivers',
            pendientes: 'error.loadingPendingWaivers',
            'pendientes-agencia': 'error.loadingPendingWaivers',
          };

          if (statusToErrorMessage.hasOwnProperty(statusName)) {
            const errorMessageKey = statusToErrorMessage[statusName];
            this.messageErrorService.sendError(error, errorMessageKey);
          }

          this.loading = false;
          console.log('error', error);
        },
      });
  }

  cleanData() {
    this.noWaivers = true;
    this.pageSize = 0;
    this.pageNumber = 0;
    this.totalElements = 0;
    this.dataSource.paginator = null;
    this.dataSource.sort = this.sort;
  }

  updateList(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.pageIndex;
    this.getWaiver(this.tab);
  }

  sendFilterData(paramsFilter: HttpParams) {
    this.pageSize = 10;
    this.pageNumber = 0;
    this.params = paramsFilter;
    this.getWaiver(this.tab);
  }

  cleanFilters() {
    if (this.pageSize == 0) {
      this.pageSize = 10;
    }
    this.params = this.initParams();

    this.getWaiver(this.tab);
  }

  initParams(): HttpParams {
    return new HttpParams()
      .set('id', 'n')
      .set('pnr', '')
      .set('valueWaiver', '')
      .set('statusDateFrom', 'n')
      .set('statusDateTo', 'n')
      .set('executive', '')
      .set('agency', '')
      .set('applicant', '')
      .set('type', '');
  }

  /**
   * call the waiver detail component
   * @param tab contains the information of the tab it is in (pendientes, aprobados, rechazados)
   * @param idWaiver identifier of the waiver which one wishes to see the detail
   */
  openDialog(tab: string, idWaiver: number): void {
    let dialogRef = this.dialog.open(DetailListWaiverAgencyComponent, {
      width: '90vw',
      height: '75vh',
      disableClose: true,
    });
    dialogRef.componentInstance.tab = tab;
    dialogRef.componentInstance.idWaiver = idWaiver;

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'reaload-update') {
        this.getWaiver('pendientes');
        this.getWaiver('pendientes-agencia');
      }
    });
  }

  highlightRow(event: Event, rowIndex: number) {
    this.selectedRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }
}
