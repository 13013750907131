import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { EditModeDirective } from '../../directives/edit-mode.directive';
import { OnlyPositiveNumberDirective } from '../../directives/only-positive-number.directive';
import { ViewModeDirective } from '../../directives/view-mode.directive';

import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipes/pipes.module';


@NgModule({
  declarations: [
    EditModeDirective,
    OnlyPositiveNumberDirective,
    ViewModeDirective
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
  ],
  exports: [
    EditModeDirective,
    OnlyPositiveNumberDirective,
    ViewModeDirective,
    ComponentsModule,
    PipesModule,
    TranslateModule,
  ]
})
export class ShareModule { }
