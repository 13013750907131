import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ShareModule } from '../share-module/share.module';

import { EditAgencyGroupComponent } from './edit-agency-group/edit-agency-group.component';
import { CreateAgencyGroupComponent } from './create-agency-group/create-agency-group.component';
import { CreateBranchOfficeComponent } from './create-branch-office/create-branch-office.component';
import { ListAgencyGroupComponent } from './list-agency-group/list-agency-group.component';

import { AgencyBranchOfficeService } from './../../services/agency-branch-office.service';
import { AgencyGroupService } from '../../services/agency-group.service';
import { AgencyService } from '../../services/agency.service';
import { BudgetService } from '../../services/budget.service';
import { MarketService } from './../../services/market.service';
import { AgencyGroupUtilService } from '../../services/agency-group-util.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
    declarations: [
        CreateAgencyGroupComponent,
        CreateBranchOfficeComponent,
        EditAgencyGroupComponent,
        ListAgencyGroupComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        ReactiveFormsModule,
        ShareModule,
        NgxSpinnerModule,
        BrowserAnimationsModule
    ],
    providers: [
        AgencyBranchOfficeService,
        AgencyGroupService,
        AgencyGroupUtilService,
        AgencyService,
        BudgetService,
        MarketService
    ]
})
export class AgencyGroupModule { }
