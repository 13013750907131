import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { Passenger } from '../../models/passenger.model';
import { PassengerInfoInterface } from '../../interfaces/passenger-info.interface';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-passenger-info',
  templateUrl: './passenger-info.component.html',
  styleUrls: ['./passenger-info.component.css']
})
export class PassengerInfoComponent implements OnInit {
  dataSource = new MatTableDataSource();
  selectedRow: number = -1;

  @Input() passengers: Passenger[];
  @Input() displayedColumns: string[];
  @Input() isValuated: boolean;
  selectionPassengers = new SelectionModel<PassengerInfoInterface>(true, []);



  ngOnInit() {
    if (this.passengers) {
      for (const pax of this.passengers) {
        const passengerInfo: PassengerInfoInterface = {
          firstName: pax.firstName,
          lastName: pax.lastName,
          type: pax.type,
          foid: pax.foid,
          ticketNumber: pax.ticketNumber,
          isSelected: pax.isSelected
        };

        this.dataSource.data.push(passengerInfo);
      }
    }
  }

  highlightRow(event: Event, rowIndex: number) {
    this.selectedRow = event.type === 'mouseover' ? rowIndex : -1;
  }

  toggleSelectionPassenger(element: PassengerInfoInterface) {

    this.selectionPassengers.toggle(element);
    let flag = this.selectionPassengers.isSelected(element) ;
    this.passengers.find(function(x) {
      if ( element.firstName === x.firstName  && element.lastName === x.lastName) {
          x.isSelected = flag;
      }
    });


  }

  disableTooltip (element: PassengerInfoInterface): boolean {
    return  element.type === 'CHLD' || element.type === 'INF' ||
    ( !element.firstName.endsWith('CNN')
    && !element.firstName.endsWith('INF') && !element.firstName.endsWith('CHD') );
  }

  isPassengerInfOrChd (element: PassengerInfoInterface): boolean {
    return  element.firstName.endsWith('CNN')
    || element.firstName.endsWith('INF') || element.firstName.endsWith('CHD');
  }

}
