import { UntypedFormGroup, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import swal from "sweetalert2";
import { StatusTicketService } from "./../../../services/status-ticket.service";
import { StatusTicket } from "./../../../models/status-ticket.model";
import { Component, OnInit, Input, Inject } from "@angular/core";
import { HttpErrorResponse } from '@angular/common/http';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import { ModalFarebasisComponent } from "../modal-farebasis/modal-farebasis.component";

@Component({
  selector: "app-by-ticket",
  templateUrl: "./by-ticket.component.html",
  styleUrls: ["./by-ticket.component.css"],
})
export class ByTicketComponent {
  _stNumber: number = 0;
  _dateTicket: string = "";
  statusTicket: StatusTicket[] = null;
  paso1: boolean = false;
  paso2: boolean = false;
  loading = false;
  form = null;
  clases = [
    { letra: "C", descripcion: "BUSINESS" },
    { letra: "J", descripcion: "BUSINESS" },
    { letra: "I", descripcion: "BUSINESS" },
    { letra: "D", descripcion: "BUSINESS" },
    { letra: "U", descripcion: "BUSINESS" },
    { letra: "Z", descripcion: "BUSINESS" },
    { letra: "W", descripcion: "PRE ECO" },
    { letra: "P", descripcion: "PRE ECO" },
    { letra: "R", descripcion: "PRE ECO" },
    { letra: "Y", descripcion: "ECO" },
    { letra: "B", descripcion: "ECO" },
    { letra: "H", descripcion: "ECO" },
    { letra: "K", descripcion: "ECO" },
    { letra: "M", descripcion: "ECO" },
    { letra: "L", descripcion: "ECO" },
    { letra: "V", descripcion: "ECO" },
    { letra: "X", descripcion: "ECO" },
    { letra: "S", descripcion: "ECO" },
    { letra: "N", descripcion: "ECO" },
    { letra: "Q", descripcion: "ECO" },
    { letra: "O", descripcion: "ECO" },
    { letra: "G", descripcion: "ECO" },
    { letra: "T", descripcion: "ECO" },
    { letra: "A", descripcion: "ECO" },
  ];
  //POSICION 2
  season = [
    { letra: "L", descripcion: "Low" },
    { letra: "K", descripcion: "Shoulder" },
    { letra: "H", descripcion: "High" },
    { letra: "P", descripcion: "Peak" },
    { letra: "E", descripcion: "Year" },
  ];
  //POSICION 3
  dayAplication = [
    { letra: "X", descripcion: "Weekday" },
    { letra: "W", descripcion: "Weekend" },
    { letra: "E", descripcion: "N/A" },
  ];

  //POSICION 4 Y 5
  family4y5 = [
    this.createSeatConfig("QP", "Public", "Y", "Basic"),
    this.createSeatConfig("SL", "Public", "Y", "Light"),
    this.createSeatConfig("SE", "Public", "Y", "Plus"),
    this.createSeatConfig("SF", "Public", "Y", "Top"),
    this.createSeatConfig("RL", "Public", "W", "Premium Economy Plus"),
    this.createSeatConfig("RY", "Public", "W", "Premium Economy Top"),
    this.createSeatConfig("EV", "Public", "J", "Premium Business Plus"),
    this.createSeatConfig("EJ", "Public", "J", "Premium Business Top"),
    this.createSeatConfig("PX", "Private", "Y", "Basic"),
    this.createSeatConfig("PF", "Private", "Y", "Light"),
    this.createSeatConfig("PP", "Private", "Y", "Plus"),
    this.createSeatConfig("PE", "Private", "Y", "Top"),
    this.createSeatConfig("PL", "Private", "W", "Premium Economy Plus"),
    this.createSeatConfig("PY", "Private", "W", "Premium Economy Top"),
    this.createSeatConfig("PV", "Private", "J", "Premium Business Plus"),
    this.createSeatConfig("PJ", "Private", "J", "Premium Business Top"),
  ];

  //POSICION 4 Y 5
  family4 = [
    this.createSeatConfig("1", "Public & Private", "Y", "Light"),
    this.createSeatConfig("8", "Public & Private", "Y", "Plus"),
    this.createSeatConfig("9", "Public & Private", "Y", "Top"),
    this.createSeatConfig("2", "Public & Private", "W", "Premiun Economy Plus"),
    this.createSeatConfig("A", "Public & Private", "W", "Premiun Economy Top"),
  ];

  //POSICION 6
  minimumStay = [
    { letra: "0", descripcion: "N/A" },
    { letra: "1", descripcion: "1 DAYS" },
    { letra: "2", descripcion: "2 DAYS" },
    { letra: "3", descripcion: "3 DAYS" },
    { letra: "4", descripcion: "5 DAYS" },
    { letra: "5", descripcion: "7 DAYS" },
    { letra: "6", descripcion: "10 DAYS" },
    { letra: "7", descripcion: "14 DAYS" },
    { letra: "8", descripcion: "21 DAYS" },
    { letra: "9", descripcion: "SU" },
    { letra: "A", descripcion: "SAoSU" },
    { letra: "C", descripcion: "2oSU" },
    { letra: "D", descripcion: "3oSU" },
    { letra: "G", descripcion: "4oSU" },
    { letra: "H", descripcion: "5oSU" },
    { letra: "M", descripcion: "4 DAYS" },
    { letra: "O", descripcion: "3ySU" },
    { letra: "Q", descripcion: "4ySU" },
    { letra: "U", descripcion: "6 DAYS" },
    { letra: "W", descripcion: "OW NO DOUBLE" },
    { letra: "Z", descripcion: "OW" },
  ];

  //POSICION 7
  advp = [
    { letra: "0", descripcion: "N/A" },
    { letra: "1", descripcion: "1 DAYS" },
    { letra: "2", descripcion: "2 DAYS" },
    { letra: "3", descripcion: "3 DAYS" },
    { letra: "4", descripcion: "4 DAYS" },
    { letra: "5", descripcion: "5 DAYS" },
    { letra: "6", descripcion: "7 DAYS" },
    { letra: "7", descripcion: "10 DAYS" },
    { letra: "8", descripcion: "14 DAYS" },
    { letra: "9", descripcion: "21 DAYS" },
    { letra: "W", descripcion: "28 DAYS" },
    { letra: "U", descripcion: "30 DAYS" },
    { letra: "D", descripcion: "40 DAYS" },
    { letra: "G", descripcion: "50 DAYS" },
    { letra: "H", descripcion: "60 DAYS" },
    { letra: "M", descripcion: "45 DAYS" },
    { letra: "B", descripcion: "95 DAYS" },
  ];
  //POSICION 8
  poc = [
    this.createCountryConfigEight("B", "CL", "CL", "CL", "CL", "CL", "-", "-", "-"),
    this.createCountryConfigEight("E", "BR", "BR", "BR", "BR", "BR", "-", "-", "-"),
    this.createCountryConfigEight("F", "PE", "PE", "PE", "PE", "PE", "-", "-", "-"),
    this.createCountryConfigEight("I", "AR", "AR", "AR", "AR", "AR", "-", "-", "-"),
    this.createCountryConfigEight("J", "EC", "EC", "EC", "EC", "EC", "-", "-", "-"),
    this.createCountryConfigEight("K", "CO", "CO", "CO", "CO", "CO", "-", "-", "-"),
    this.createCountryConfigEight("L", "UY", "UY", "UY", "UY", "UY", "-", "-", "-"),
    this.createCountryConfigEight("M", "BO", "BO", "BO", "BO", "BO", "-", "-", "-"),
    this.createCountryConfigEight("N", "VE", "VE", "VE", "VE", "VE", "-", "-", "-"),
    this.createCountryConfigEight("O", "PY", "PY", "PY", "PY", "PY", "-", "-", "-"),
    this.createCountryConfigEight("P", "MX", "-", "MX", "PF", "PF", "-", "-", "PF"),
    this.createCountryConfigEight("Q", "FI OTHERS", "US OTHERS", "EUR OTHERS", "ASIA OTHERS", "ASIA OTHERS", "EUR OTHERS", "ASIA OTHERS", "TC2 OTHERS"),
    this.createCountryConfigEight("R", "-", "US/CA-PE", "ES", "AU-CL", "-", "ES", "-", "ES"),
    this.createCountryConfigEight("S", "-", "US/CA-CL", "FR", "AU-BR", "-", "FR", "-", "FR"),
    this.createCountryConfigEight("T", "-", "US/CA-AR", "IT", "NZ-CL", "-", "IT", "-", "IT"),
    this.createCountryConfigEight("Y", "-", "US/CA-BR", "GB", "NZ-BR", "-", "GB", "-", "GB"),
    this.createCountryConfigEight("V", "-", "US/CA-EC", "DE", "ASIA-CL", "ASIA-CL", "DE", "-", "DE"),
    this.createCountryConfigEight("Z", "-", "US/CA-CO", "AFRICA", "AU<>OTHERS(US>AU)", "-", "AFRICA", "AU", "AU"),
    this.createCountryConfigEight("X", "-", "-", "-", "NZ<>OTHERS(US>AU)", "-", "-", "NZ", "NZ"),
    this.createCountryConfigEight("U", "-", "-", "-", "ASIA-BR", "ASIA-BR", "-", "-", "-"),
    this.createCountryConfigEight("W", "-", "-", "-", "-", "-", "-", "-", "-"),
  ];

  @Input() set stNumber(value: number) {
    this._stNumber = value;
    this.editarTicket(value);
  }

  @Input() set dateTicket(value: string) {
    this._dateTicket = value;
    this.editarFecha(value);
  }

  editarFecha(value: string) {
    this._dateTicket = value;
    this.paso2 = true;
    this.revision();
  }

  editarTicket(value: number) {
    this._stNumber = value;
    this.paso1 = true;
    this.revision();
  }

  revision() {
    if (this.paso1 && this.paso2) {
      this.loadStatusTicket();
    }
  }

  constructor(
    private statusTicketService: StatusTicketService,
    private translateService: TranslateService,
    public dialog: MatDialog
  ) {}

  onNoClick(): void {
    this.dialog.closeAll();
  }
  /*
  MCPMLT-4775 https://projectmanagement.appslatam.com/browse/MCPMLT-4775

  Para esta historia existen dos documentos, uno esta en los comentarios del ticket que subio Dirlene, y la otra es la que
  me consegui en teams sincronizacion canales. El dia lunes 16 de enero presente el documento excel donde se explica la traduccion
  tarifaria del farebasis y me aceptaron el documento (https://docs.google.com/spreadsheets/d/1rjjFNYZ4UjNy6Ap2qKEqEz3WH5B0sHNRt3kEVbP3-lk/edit#gid=2052805023).

  En este documento se mencionan dos tipos de farebasis de 7 u 8 caracteres de largo, de los cuales la logica del que tiene 8 ya esta realizada
  en el siguiente codigo, pero aun estoy a la espera de como continuar con la logica del de 7 caracteres (bloqueado por negocio).

  Los correos de las personas que estuvieron en las reuniones para este ticket son:

  "Daiane Henriques Silva (LATAM)" <daiane.henriques@latam.com>,
  "Freddy Marcelo Caro Aldunate (LATAM)" <freddy.caro@latam.com>,
  "Renato Vieira De Morais (LATAM)" <renato.morais@latam.com>

  PD: el tema del diseño del modal no pude arreglarlo mucho la verdad, disculpen.


  */
  openDialog(valor): void {
    let tipo = "";
    let columna1, columna2, columna3, columna4,columna5, columna6, columna7,columna8;

    let size = valor.length;

    if (size == 8) {
      tipo = "Publico y privado Inter y DOM PHH";
      let pos1 = valor.substring(0, 1);
      let pos2 = valor.substring(1, 2);
      let pos3 = valor.substring(2, 3);
      let pos45 = valor.substring(3, 5);
      let pos6 = valor.substring(5, 6);
      let pos7 = valor.substring(6, 7);
      let pos8 = valor.substring(7, 8);

      let primeraColumna = this.clases.find((obj) => {
        return obj.letra === pos1;
      });
      columna1 = primeraColumna?.descripcion;
      let segundaColumna = this.season.find((obj) => {
        return obj.letra === pos2;
      });
      columna2 = segundaColumna?.descripcion;
      let terceraColumna = this.dayAplication.find((obj) => {
        return obj.letra === pos3;
      });
      columna3 = terceraColumna?.descripcion;
      let cuartaColumna = this.family4y5.find((obj) => {
        return obj.letra === pos45;
      });
      columna4 = cuartaColumna?.descripcion;
      let sextaColumna = this.minimumStay.find((obj) => {
        return obj.letra === pos6;
      });
      columna6 = sextaColumna?.descripcion;
      let septimaColumna = this.advp.find((obj) => {
        return obj.letra === pos7;
      });
      columna7 = septimaColumna?.descripcion;
      let octavaColumna = this.poc.find((obj) => {
        return obj.letra === pos8;
      });
      columna8 =
        octavaColumna?.whfi +
        " | " +
        octavaColumna?.whfp +
        " | " +
        octavaColumna?.saar2 +
        " | " +
        octavaColumna?.saasviapa +
        " | " +
        octavaColumna?.saar3viaat +
        " | " +
        octavaColumna?.europ +
        " | " +
        octavaColumna?.tpfg +
        " | " +
        octavaColumna?.apfares;
    } else if (size == 7) {
      tipo = 'Publico y privado DOM BR';
    }

    const dialogRef = this.dialog.open(ModalFarebasisComponent, {
      //data: {name: this.name, animal: this.animal},
      height: '550px',
      width: '550px',
      data: {
        tipo: tipo,
        col1: columna1 !== undefined ? columna1 : "-",
        col2: columna2 !== undefined ? columna2 : "-",
        col3: columna3 !== undefined ? columna3 : "-",
        col4: columna4 !== undefined ? columna4 : "-",
        col6: columna6 !== undefined ? columna6 : "-",
        col7: columna7 !== undefined ? columna7 : "-",
        col8: columna8 !== undefined ? columna8 : "-"
      },
    });
  }

  buildForm() {
    if (this.statusTicket != null) {
      this.statusTicket.forEach((element) => {
        if (element.routing_seg != null) {
          if (element.routing_seg.includes("-&gt;")) {
            element.routing_seg = element.routing_seg.split("-&gt;").join("->");
          }
        }
        this.form = new UntypedFormGroup({});
      });
    } else {
      this.form = null;
    }
  }

  formatearDia(fecha: string) {
    let dia = fecha.split("-")[0];
    let mes = fecha.split("-")[1];
    if (dia.length == 1) {
      dia = "0" + dia;
    }
    if (mes.length == 1) {
      mes = "0" + mes;
    }
    return dia + "-" + mes + "-" + fecha.split("-")[2];
  }

  loadStatusTicket() {
    this.loading = true;
    this.statusTicket = null;
    this.buildForm();
    this._dateTicket = this.formatearDia(this._dateTicket);
    this.statusTicketService
      .getStatusTicket(this._stNumber, this._dateTicket)
      .subscribe(
        (result) => {
          this.statusTicket = result;
          this.loading = false;
          this.buildForm();
        },
        (error:HttpErrorResponse) => {
          console.log('error', error);
          if (error.error !== null !== undefined) {
            switch (error.status) {
              case 422: {
                this.swalertError(
                  this.translateService.instant(
                    "statusTicket.Error.alertErrConn.title"
                  ),
                  this.translateService.instant(
                    "statusTicket.Error.alertErrConn.message"
                  )
                );
                break;
              }
              case 404: {
                this.swalertInfo(
                  this.translateService.instant(
                    "statusTicket.Error.alertNotFound.title"
                  ),
                  this.translateService.instant(
                    "statusTicket.Error.alertNotFound.message"
                  )
                );
                break;
              }
              default: {
                this.swalertError(
                  this.translateService.instant(
                    "statusTicket.Error.alertUnknown.title"
                  ),
                  this.translateService.instant(
                    "statusTicket.Error.alertUnknown.message"
                  )
                );
                break;
              }
            }
          } else {
            this.swalertError(
              this.translateService.instant(
                "statusTicket.Error.alertErrConn.title"
              ),
              this.translateService.instant(
                "statusTicket.Error.alertErrConn.message"
              )
            );
          }
          this.loading = false;
        }
      );
  }

  swalertError(title: string, message: string) {
    swal.fire({
      title: "<p>" + title + "</p>",
      html: "<p>" + message + "</p>",
      confirmButtonText: this.translateService.instant("button.confirm"),
      confirmButtonColor: "#d33",
      icon: "error",
      allowOutsideClick: false,
    });
  }

  swalertInfo(title: string, message: string) {
    swal.fire({
      title: "<p>" + title + "</p>",
      html: "<p>" + message + "</p>",
      confirmButtonText: this.translateService.instant("button.confirm"),
      confirmButtonColor: "#00c",
      icon: "info",
      allowOutsideClick: false,
    });
  }

  createSeatConfig(letra, descripcion, cabina, brand) {
    return {
      letra,
      descripcion,
      cabina,
      brand,
    };
  }

  createCountryConfigEight(letra, whfi, whfp, saar2, saasviapa, saar3viaat, europ, tpfg, apfares) {
    return {
      letra,
      whfi,
      whfp,
      saar2,
      saasviapa,
      saar3viaat,
      europ,
      tpfg,
      apfares,
    };
  }
}
