import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { AgencyGroup } from '../../../../models/agency-group.model';
import { BranchOffice } from '../../../../models/branch-office.model';
import { Budget } from '../../../../models/budget.model';
import { BudgetType } from '../../../../models/budget-type.model';
import { BusinessException, Exception, SystemException } from '../../../../models/Exception.model';
import { Market } from '../../../../models/market.model';
import { MonthlyBudget } from '../../../../models/monthly-budget.model';
import { Profile } from '../../../../models/profile.model';

import { AgencyBranchOfficeService } from './../../../../services/agency-branch-office.service';
import { BudgetService } from '../../../../services/budget.service';
import { MessageService } from '../../../../services/message.service';
import { ProfilingService } from '../../../../services/profiling.service';
import { MessageErrorService } from '../../../../services/message-error.service';


@Component({
  selector: 'app-global-budget-branch-office',
  templateUrl: './global-budget-branch-office.component.html',
  styleUrls: ['./global-budget-branch-office.component.css']
})
export class GlobalBudgetBranchOfficeComponent implements OnInit, OnChanges {
  JSON = JSON;

  loadingBranchBudget = false;
  branchBudgetLoaded = false;
  showComponent = false;
  showConsumeGroupBudget = false;
  enableSaveByConsumeCheck = true;
  enableSaveShowBranchBudget = true;
  isSaving = false;

  branch = new UntypedFormControl();
  consumeGroupBudget = new UntypedFormControl(false);
  showBranchBudget = new UntypedFormControl(false);
  firstSemesterBudget = new MatTableDataSource();
  secondSemesterBudget = new MatTableDataSource();
  filteredBranches: Observable<BranchOffice[]>;
  profiles = new Profile();

  initialBranchBudgetFirstSemester = [];
  initialBranchBudgetSecondSemester = [];
  firstSemesterColumns = ['position', 'ene', 'feb', 'mar', 'abr', 'may', 'jun'];
  secondSemesterColumns = ['position', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic', 'total'];

  private budgets: Budget[] = [];
  private initialGroupBudget = {};
  private monthlyBudget: MonthlyBudget = new MonthlyBudget();
  private selectedBranch: BranchOffice;
  private selectedBranchs: BranchOffice[] = [];

  @Input() budgetType: BudgetType;
  @Input() market: Market;
  @Input() selectedAgencyGroup: AgencyGroup;
  @Output() budgetBranchSaved = new EventEmitter();

  constructor(
    private agencyBranchOfficeService: AgencyBranchOfficeService,
    private budgetService: BudgetService,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private profilesService: ProfilingService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.loadBranches(this.selectedAgencyGroup.id);
    this.initializeBranchBudgetTable();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.selectedAgencyGroup.firstChange) {
      this.selectedAgencyGroup = <AgencyGroup>changes.selectedAgencyGroup.currentValue;
      this.loadBranches(this.selectedAgencyGroup.id);
    }

    if (!changes.budgetType.firstChange) {
      this.budgetType = changes.budgetType.currentValue;
    }
  }

  displayFn(branch?: BranchOffice): string | undefined {
    return branch ? branch.name : undefined;
  }

  onSelectionBranchChange(event: MatOptionSelectionChange, branch: BranchOffice): void {
    if (event.source.selected) {
      this.selectedBranch = branch;
      this.consumeGroupBudget.setValue(this.selectedBranch.consumeGroupBudget);
      this.showBranchBudget.setValue(
        this.budgetType.id === 1 ? this.selectedBranch.showPostsaleBudget : this.selectedBranch.showPresaleBudget
      );
      this.showConsumeGroupBudget = true;
      this.getBudgetByBranch(this.selectedBranch);
    }
  }

  private initializeBranchBudgetTable(): void {
    this.firstSemesterBudget.data[0] = {};
    this.firstSemesterBudget.data[1] = {};
    this.firstSemesterBudget.data[2] = {};
    this.firstSemesterBudget.data[0]['input'] = true;
    this.firstSemesterBudget.data[1]['input'] = false;
    this.firstSemesterBudget.data[2]['input'] = false;
    this.firstSemesterBudget.data[0]['rowName'] = 'Cargado';
    this.firstSemesterBudget.data[1]['rowName'] = 'Gastado';
    this.firstSemesterBudget.data[2]['rowName'] = 'Disponible';
    this.firstSemesterBudget.data[0]['amount'] = [];
    this.firstSemesterBudget.data[1]['amount'] = [];
    this.firstSemesterBudget.data[2]['amount'] = [];

    this.secondSemesterBudget.data[0] = {};
    this.secondSemesterBudget.data[1] = {};
    this.secondSemesterBudget.data[2] = {};
    this.secondSemesterBudget.data[0]['input'] = true;
    this.secondSemesterBudget.data[1]['input'] = false;
    this.secondSemesterBudget.data[2]['input'] = false;
    this.secondSemesterBudget.data[0]['rowName'] = 'Cargado';
    this.secondSemesterBudget.data[1]['rowName'] = 'Gastado';
    this.secondSemesterBudget.data[2]['rowName'] = 'Disponible';
    this.secondSemesterBudget.data[0]['amount'] = [];
    this.secondSemesterBudget.data[1]['amount'] = [];
    this.secondSemesterBudget.data[2]['amount'] = [];
  }

  private getBudgetByBranch(selectedBranch: BranchOffice): void {
    this.branchBudgetLoaded = false;
    this.loadingBranchBudget = true;

    this.budgetService.getBudgetByBranch(selectedBranch.id, this.budgetType.id).subscribe({
      next: data => {
        this.budgets = data;
        this.setBudgetBranchData(data);
        this.branchBudgetLoaded = true;
        this.loadingBranchBudget = false;
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingBranchOfficeBudget');

        this.loadingBranchBudget = false;
        console.log('error', error);
  }});
  }

  private setBudgetBranchData(data: Budget[]): void {
    data.slice(0, 6).forEach(b => {
      this.firstSemesterBudget.data[0]['amount'][b.month] = parseFloat(b.assignedValue.toString()).toFixed(2);
      this.firstSemesterBudget.data[1]['amount'][b.month] = b.spentValue;
      this.firstSemesterBudget.data[2]['amount'][b.month] = b.currentAmount;

    });
    data.slice(6, 12).forEach(b => {
      this.secondSemesterBudget.data[0]['amount'][b.month] = parseFloat(b.assignedValue.toString()).toFixed(2);
      this.secondSemesterBudget.data[1]['amount'][b.month] = b.spentValue;
      this.secondSemesterBudget.data[2]['amount'][b.month] = b.currentAmount;
    });
    this.initialGroupBudget = data;
    this.initialBranchBudgetFirstSemester = this.firstSemesterBudget.data[0]['amount'].slice();
    this.initialBranchBudgetSecondSemester = this.secondSemesterBudget.data[0]['amount'].slice();
    this.secondSemesterBudget.data[0]['total'] =
      this.firstSemesterBudget.data[0]['amount'].concat(
        this.secondSemesterBudget.data[0]['amount']).reduce((a, b) => parseFloat(a) + parseFloat(b)
        );
    this.secondSemesterBudget.data[1]['total'] =
      this.firstSemesterBudget.data[1]['amount'].concat(this.secondSemesterBudget.data[1]['amount']).reduce((a, b) => a + b);
    this.secondSemesterBudget.data[2]['total'] =
      this.firstSemesterBudget.data[2]['amount'].concat(this.secondSemesterBudget.data[2]['amount']).reduce((a, b) => a + b);
  }

  private loadBranches(agencyGroupId: number): void {
    this.showComponent = false;

    this.agencyBranchOfficeService.getBranchOfficeList(agencyGroupId, null).subscribe({
      next: data => {
        if (data.length > 0) {
          this.showComponent = true;
        } else {
          return null;
        }

        this.selectedBranchs = data;
        this.filteredBranches = this.branch.valueChanges.pipe(
          startWith<string | BranchOffice>(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filter(name) : this.selectedBranchs.slice())
        );
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingBranchOffice');

        console.log('error', error);
      }
  });
  }

  private _filter(name: string): BranchOffice[] {
    const filterValue = name.toLowerCase();
    return this.selectedBranchs.filter(option => option.name.toLowerCase().startsWith(filterValue));
  }

  disabledMonthlyAmountInput(month: number): boolean {
    let today = new Date();
    let monthToday = today.getMonth();

    return (monthToday > month || this.consumeGroupBudget.value);
  }

  consumeGroupBudgetChanged(event: MatCheckboxChange) {
    this.enableSaveByConsumeCheck = this.selectedBranch.consumeGroupBudget === event.checked;

    if (event.checked) {
      let today = new Date();
      let monthToday = today.getMonth();

      for (const budget of this.budgets) {
        if (budget.month >= monthToday && budget.currentAmount > 0) {
          this.messageService.showInfoMessage(
            this.translateService.instant('button.confirm'),
            this.translateService.instant('warning.branchOfficeBudget')
          );
          break;
        }
      }
    }
  }

  showBranchBudgetChanged(event: MatCheckboxChange) {
    this.enableSaveShowBranchBudget =
      (this.budgetType.id === 1 ? this.selectedBranch.showPostsaleBudget : this.selectedBranch.showPresaleBudget) === event.checked;
  }

  save(): void {
    if (this.profiles.createBudgetManagement) {
      this.updateBranchOffice();
    }
  }

  private enableControls(enable: boolean): void {
    if (enable) {
      this.branch.enable();
      this.consumeGroupBudget.enable();
      this.showBranchBudget.enable();
    } else {
      this.branch.disable();
      this.consumeGroupBudget.disable();
      this.showBranchBudget.disable();
    }
  }

  private updateBranchOffice(): void {
    this.enableControls(false);
    this.isSaving = true;
    this.selectedBranch.consumeGroupBudget = this.consumeGroupBudget.value;

    if (this.budgetType.id === 1) {
      this.selectedBranch.showPostsaleBudget = this.showBranchBudget.value;
    } else {
      this.selectedBranch.showPresaleBudget = this.showBranchBudget.value;
    }

    this.agencyBranchOfficeService.updateBranchOffice(this.budgetType.id, this.selectedBranch).subscribe({
      next: data => {
        this.selectedBranch = data;
        this.saveBudget();
      },
      error: (error: HttpErrorResponse) => {
        this.enableControls(true);
        this.isSaving = false;
        this.messageErrorService.sendError(error, 'error.saveBranchOfficeBudget');
        console.log('error', error);
  }});
  }

  private saveBudget(): void {
    this.enableControls(false);
    this.isSaving = true;
    let budgets: Budget[] = [];
    let initialMonthlyBudget = this.initialGroupBudget;

    this.firstSemesterBudget.data[0]['amount'].slice(0, 6).forEach((value: string, key: number) => {
      let month = key;
      let addedValue = parseFloat(value) - parseFloat(initialMonthlyBudget[month].assignedValue);
      budgets.push(new Budget(
        initialMonthlyBudget[month].id, month, parseFloat(value), initialMonthlyBudget[month].spentValue, 0, addedValue)
      );
    });
    this.secondSemesterBudget.data[0]['amount'].slice(6, 12).forEach((value: string, key: number) => {
      let month = 6 + key;
      let addedValue = parseFloat(value) - parseFloat(initialMonthlyBudget[month].assignedValue);
      budgets.push(new Budget(
        initialMonthlyBudget[month].id, month, parseFloat(value), initialMonthlyBudget[month].spentValue, 0, addedValue)
      );
    });

    this.monthlyBudget.budget = budgets;
    this.monthlyBudget.market = this.market;
    this.monthlyBudget.groupId = this.selectedAgencyGroup.id;
    this.monthlyBudget.branchOfficeId = this.selectedBranch.id;

    this.budgetService.saveBudgetBranch(this.monthlyBudget, this.budgetType.id).subscribe({
      next: data => {
        this.budgetBranchSaved.emit(true);
        this.enableControls(true);
        this.isSaving = false;
        this.getBudgetByBranch(this.selectedBranch);
        this.messageService.showSuccessMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('popup.wellDone'),
          this.translateService.instant('success.editBranchOffice')
        );
      },
      error: (error: HttpErrorResponse) => {
        this.enableControls(true);
        this.isSaving = false;
        this.messageErrorService.sendError(error, 'error.saveBranchOfficeBudget');

        console.log('error', error);
  }});
  }
}
