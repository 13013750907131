import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';

import { BusinessException, Exception, SystemException } from '../../../models/Exception.model';
import { FileUploadModel } from '../../../models/file-upload.model';
import { Market } from '../../../models/market.model';
import { OsiInfo } from '../../../models/osi-info.model';
import { Profile } from '../../../models/profile.model';
import { SessionInfo } from '../../../models/SessionInfo.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';
import { Waiver } from '../../../models/waiver.model';
import { WaiverMotive } from '../../../models/waiver-motive.model';
import { WaiverStatus } from '../../../models/waiver-status.model';
import { WaiverToken } from '../../../models/waiver-token.model';
import { WaiverType } from '../../../models/waiver-type.model';

import { FileStorageService } from '../../../services/file-storage.service';
import { MarketService } from './../../../services/market.service';
import { MessageService } from '../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { WaiverPostsaleService } from '../../../services/waiver-postsale.service';

import { DetailWaiverComponent } from '../detail-waiver/detail-waiver.component';
import { WaiverPostsaleFormInterface } from '../../../interfaces/waiver-postsale-form.interface';
import { WaiverPostsaleTicketFormInterface } from '../../../interfaces/waiver-postsale-ticket-form.interface';

import { ConstantsService } from '../../../services/constants.service';
import { forkJoin } from 'rxjs';
import { MessageErrorService } from '../../../services/message-error.service';


@Component({
  selector: 'app-waiver',
  templateUrl: './create-waiver.component.html',
  styleUrls: ['./create-waiver.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger(
      'fadeInOut',
      [
        state('in', style({ opacity: 100 })),
        transition('* => void', [animate(300, style({ opacity: 50 }))])
      ]
    )
  ]
})

export class CreateWaiverComponent implements OnInit {
  @ViewChild('type') type;
  @ViewChild('motif') motif;
  @ViewChild('pnrNumber') pnrNumber;
  @ViewChild('ticket') ticket;
  @ViewChild('solicitudWaiverForm') solicitudWaiverForm;
  @ViewChild('resumenWaiverForm') resumenWaiverForm;
  @ViewChild('source') source;
  @ViewChild('destination') destination;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('tktSearchDiv') tktSearchDiv;
  @ViewChild('pnrPostSearchDiv') pnrPostSearchDiv;

  // ini adjuntar archivos
  // File extension that accepted, same as 'accept' of <input type="file" />. By the default, it's set to 'image/*'.
  @Input() accept = '.pdf, .doc, .docx, .png, .jpg, .jpeg';
  // Allow you to add handler after its completion. Bubble up response text from remote.
  @Output() complete = new EventEmitter<string>();
  private files: Array<FileUploadModel> = [];
  // fin adjuntar archivos

  infoUser: SessionInfo = new SessionInfo();
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_WAIVERS, SidebarMenuModel.SUBMENU_WAIVER_REQUEST);
  displayedColumnsR = ['pnrNumber', 'pnrType', 'bookingCreation', 'NumberOfPassengers', 'pos', 'iata', 'pcc', 'officeId'];
  displayedColumnsPPOS = ['passengerName', 'foid', 'passengerType', 'tkt', 'select'];
  displayedColumnsP = ['passengerName', 'foid', 'passengerType', 'select'];
  displayedColumnsS = ['segmentNumber', 'carrierMkt', 'flightNumberMkt', 'class', 'origin', 'destination', 'dateHourDeparture',
    'status', 'fareBasis', 'select'];
  displayedColumnsTKT = ['tktNumber', 'pnrNum', 'emisionCreationDate', 'posInfo', 'iataInfo', 'pccInfo', 'officeIdInfo'];
  displayedColumnsV = ['voucherNumber', 'carrierTkt', 'flightNumberTkt', 'classTkt', 'originTkt', 'destinationTkt', 'dateHourTkt',
    'farebasis', 'voucherStatus', 'select'];
  invalidCouponStatusList = ['USED', 'RFND', 'EXCH', 'VOID', 'F', 'R', 'V', 'E'];
  validSegmentStatusList = ['HK', 'KK'];
  allowedFileFormats = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/png',
    'image/jpeg'
  ];
  latamCarrierCodes = ['LA', 'JJ', '4M', 'XL'];
  selectedReservationInformationRowIndex: number = -1;
  selectedPassengerInformationRowIndex: number = -1;
  selectedSegmentsInformationRowIndex: number = -1;
  selectedTktInformationRowIndex: number = -1;
  selectedCouponsInformationRowIndex: number = -1;
  invalidCouponsQuantity = 0;
  segmentsWithOneCouponInvalidQuantity = 0;
  invalidStatusSegmentsQuantity = 0;
  dataReservation = new MatTableDataSource();
  dataReservationList : MatTableDataSource<WaiverPostsaleFormInterface>[]= [];
  dataPassengers = new MatTableDataSource();
  dataPassengersList : MatTableDataSource<any>[]=[];
  dataSegments = new MatTableDataSource();
  dataSegmentsList: MatTableDataSource<any>[]=[];
  dataTkt = new MatTableDataSource();
  dataTktList : MatTableDataSource<WaiverPostsaleTicketFormInterface>[]=[];
  dataVoucher = new MatTableDataSource();
  dataVoucherList : MatTableDataSource<any>[]=[]
  selectionPassengers = new SelectionModel<Element>(true, []);
  selectionSegments = new SelectionModel<Element>(true, []);
  selectionCoupons = new SelectionModel<Element>(true, []);
  passengerTicketRequestsCompleted: number = 0;
  passengerTicketRequestsWithError: number = 0;
  allPassengersInvalid: boolean = true;
  loadingUpload: boolean = false;

  waivers: Waiver[] ;
  waiver: Waiver = new Waiver();
  waiverCount = 0;
  waiverToken: string;
  waiverType: {};
  waiverModif: {};
  selectedType: WaiverType = new WaiverType(0, '', '', null);
  selectedMotif: WaiverMotive = new WaiverMotive(0, '', '', false);
  selectedTypeSearch: number;
  selectedTypeCategory: any;
  validPrePNR: boolean = false;
  validPostPNR: boolean = false;
  // validPostTKT: boolean = false;
  disablePnrTabs: boolean = true;
  disableTktTabs: boolean = true;
  validSourceDestination: boolean = false;
  loading: boolean = false;
  loadingSave: boolean = false;
  isSuccess: boolean = true;
  existPassengersWithoutTicket: boolean = true;
  message = {
    icon: 'warning',
    detail: '',
    status: 0,
    show: false
  };

  public pnrList: any[] = [{

  }]
  public ticketList: any[] = [{

  }]
  aditionalComment: string;
  valorWaiver = 'NA';
  verifyCalculateWaiver = false;

  tabNavList : any[] = [{

  }]

  resumenNavList : any[] = [{

  }]

  infantList: number[] = new Array();
  infantListByPassenger: Array<number>[] = [];
  infantCoupon = false;
  atLeastOneInfantPass = false;
  marketRequest: Market = new Market();

  isForcedManually = false;
  marketId: number;
  temp: any;

  profiles = new Profile();
  osiInfo: OsiInfo;
  pnr: any;
  booking: any;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private fileStorageService: FileStorageService,
    private marketService: MarketService,
    private messageService: MessageService,
    private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService,
    private messageErrorService: MessageErrorService,
    private constantsService :ConstantsService,
    private waiverPostsaleService: WaiverPostsaleService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.getSessionMarketById();

    if (this.sessionInfoService.getSessionInfo().marketId instanceof Number) {
      this.marketId = this.sessionInfoService.getSessionInfo().marketId as number; // no cast necessary in if-block
    }

    this.temp = this.sessionInfoService.getSessionInfo().marketId;
    this.marketId = this.temp;
    this.getTypesByMarket();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.sessionInfoService.getSessionInfoAsPromise()
      .then(
        sessionInfo => {
          this.infoUser = sessionInfo;
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
  }

  getSessionMarketById() {
    this.marketService.getSessionMarketById().subscribe({
      next: data => {
        this.marketRequest = data;
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }
  });
  }

  getTypesByMarket() {
    this.waiverPostsaleService.getTypesByMarket(this.marketId).subscribe({
      next: data => {
        this.waiverType = data.filter(type => !type.name.includes('Waiver:'));
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }
  });
  }

  isValidPostPNR(){
    if (this.dataReservationList.length === 0){
      return false;
    }
    return this.dataReservationList.find(dataReservation => dataReservation.data[0].validPostPNR === false) === undefined;
  }

  isValidPostTKT(){
    if (this.dataTktList.length === 0){
      return false;
    }
    return this.dataTktList.find(dataTicket => dataTicket.data[0].validPostTKT === false) === undefined;
  }

  isPassengerTicketRequestsCompletedPost(){
    let isAllCompleted = true;
    for (let i = 0; i < this.dataReservationList.length; i++){
      if (this.dataReservationList[i].data[0].passengerTicketRequestsCompleted !== this.dataPassengersList[i].data.length){
        return false;
      }
    }
    return isAllCompleted;
  }

  isLoading() {
    if (this.selectedType?.waiverCategory?.code === 'POST'){
      return this.loading || (this.isValidPostPNR() && !this.isPassengerTicketRequestsCompletedPost());
    }
    return this.loading
  }

  // Whether the number of selected elements matches the total number of rows
  isAllSelectedPassengers(indexReservation = null) {
    let numSelectedPassengers;
    let numRowsPassengers;
    let numInvalidPassengers = 0;

    if (indexReservation != null){
      // Post venta
      numSelectedPassengers = this.dataReservationList[indexReservation].data[0].selectionPassengers.selected.length;
      numRowsPassengers = this.dataPassengersList[indexReservation].data.length;
      for (let i = 0; i < numRowsPassengers; i++) {
        if (this.dataPassengersList[indexReservation].data[i]['invalidTkt'] || this.dataPassengersList[indexReservation].data[i]['allCouponsInvalid']) {
          numInvalidPassengers++;
      }
    }

    } else {
      // Pre venta
      numSelectedPassengers = this.selectionPassengers.selected.length;
      numRowsPassengers = this.dataPassengers.data.length;

      for (let i = 0; i < numRowsPassengers; i++) {
        if (this.dataPassengers.data[i]['invalidTkt'] || this.dataPassengers.data[i]['allCouponsInvalid']) {
          numInvalidPassengers++;
      }
    }

    }

    return numSelectedPassengers === numRowsPassengers - numInvalidPassengers;
  }

  // Selects all rows if they are not all selected; otherwise clear selection
  masterTogglePassengers() {
    this.segmentsWithOneCouponInvalidQuantity = 0;

    if (this.isAllSelectedPassengers()) {
      this.setInfantTktPropertie(true);

      for (const element of this.dataSegments.data) {
        element['selectedCoupons'] = [];
        element['invalidCoupons'] = 0;
      }

      this.selectionPassengers.clear();
    } else {
      this.handleWhenPassengersIsNotAllSelectPre();

    }
  }

  handleWhenPassengersIsNotAllSelectPre() {
    this.setInfantTktPropertie(false);

      if (this.selectedType.waiverCategory.code === 'PRE') {
        this.dataPassengers.data.forEach((row: any) => {
          this.selectionPassengers.select(row);
        });
      }

      this.selectionSegments.selected.forEach((s: any) => {
        if (!this.validSegmentStatusList.includes(s.status) || s.invalidCoupons > 0) {
          this.selectionSegments.toggle(s);
        }
      });

      for (const element of this.dataSegments.data) {
        if (this.validSegmentStatusList.includes(element['status']) && element['invalidCoupons'] > 0) {
          this.segmentsWithOneCouponInvalidQuantity++;
        }
      }
  }

  masterTogglePassengersPost(indexReservation: number) {
    this.segmentsWithOneCouponInvalidQuantity = 0;

    if (this.isAllSelectedPassengers(indexReservation)) {
      this.setInfantTktPropertiePost(true, indexReservation);

      for (const element of this.dataSegmentsList[indexReservation].data) {
        element['selectedCoupons'] = [];
        element['invalidCoupons'] = 0;
      }

      this.dataReservationList[indexReservation].data[0].selectionPassengers.clear();
    } else {
      this.handleWhenPassengersIsNotAllSelectPost(indexReservation);
      }

      this.dataReservationList[indexReservation].data[0].selectionSegments.selected.forEach((s: any) => {
        if (!this.validSegmentStatusList.includes(s.status) || s.invalidCoupons > 0) {
          this.dataReservationList[indexReservation].data[0].selectionSegments.toggle(s);
        }
      });

      for (const element of this.dataSegmentsList[indexReservation].data) {
        if (this.validSegmentStatusList.includes(element['status']) && element['invalidCoupons'] > 0) {
          this.dataReservationList[indexReservation].data[0].segmentsWithOneCouponInvalidQuantity++;
        }
      }
    }

  handleWhenPassengersIsNotAllSelectPost(indexReservation: number) {
    this.setInfantTktPropertiePost(false, indexReservation);

      if (this.selectedType.waiverCategory.code === 'POST') {
        this.dataPassengersList[indexReservation].data.forEach((row: any) => {
          if (!row.invalidTkt && !row.allCouponsInvalid) {
            this.processPassengerCoupons(indexReservation, row);

            this.dataReservationList[indexReservation].data[0].selectionPassengers.select(row);
          }
        });
    }
  }

  processPassengerCoupons(indexReservation: number, row: any) {
    for (const element of this.dataSegmentsList[indexReservation].data) {
      let coupon = this.findCouponBySourceDestination(
        row.coupons, element['source'], element['destination']
      );

      if (!coupon) {
        continue;
      }

      element['selectedCoupons'].push(coupon);

      if (this.invalidCouponStatusList.includes(coupon['status'])) {
        element['invalidCoupons']++;
      }
    }
  }

  // Whether the number of selected elements matches the total number of rows
  isAllSelectedSegments(indexReservation : number) {
    const numSelectedSegments = this.dataReservationList[indexReservation].data[0].selectionSegments.selected.length;
    const numRowsSegments = this.dataSegmentsList[indexReservation].data.length;
    let numInvalidSegments = 0;

    for (let i = 0; i < numRowsSegments; i++) {
      if (this.selectedType.waiverCategory.code === 'POST' &&
        (!this.validSegmentStatusList.includes(this.dataSegmentsList[indexReservation].data[i]['status']) || this.dataSegmentsList[indexReservation].data[i]['invalidCoupons'] > 0)) {
        numInvalidSegments++;
      } else if (this.selectedType.waiverCategory.code === 'PRE' &&
        !this.validSegmentStatusList.includes(this.dataSegments.data[i]['status'])) {
        numInvalidSegments++;
      }
    }

    return numSelectedSegments === numRowsSegments - numInvalidSegments;
  }

  isAllSelectedSegmentsPre() {
    const numSelectedSegments = this.selectionSegments.selected.length;
    const numRowsSegments = this.dataSegments.data.length;
    let numInvalidSegments = 0;

    for (let i = 0; i < numRowsSegments; i++) {
      if (this.selectedType.waiverCategory.code === 'PRE' &&
        !this.validSegmentStatusList.includes(this.dataSegments.data[i]['status'])) {
        numInvalidSegments++;
      }
    }

    return numSelectedSegments === numRowsSegments - numInvalidSegments;
  }

  // Selects all rows if they are not all selected; otherwise clear selection
  masterToggleSegments(i : number) {
    if (this.isAllSelectedSegments(i)) {
      this.dataReservationList[i].data[0].selectionSegments.clear();
    } else if (this.selectedType.waiverCategory.code === 'POST') {
      this.dataSegmentsList[i].data.forEach((row: any) => {
        if (this.validSegmentStatusList.includes(row.status) && row.invalidCoupons === 0) {
          this.dataReservationList[i].data[0].selectionSegments.select(row);
        }
      });
    }
  }

  masterToggleSegmentsPre() {
    if (this.isAllSelectedSegmentsPre()) {
      this.selectionSegments.clear();
    } else if (this.selectedType.waiverCategory.code === 'PRE') {
      this.dataSegments.data.forEach((row: any) => {
        if (this.validSegmentStatusList.includes(row.status)) {
          this.selectionSegments.select(row);
        }
      });
    }
  }

  isSelectPassengersPost(): boolean{
    for (const element of this.dataReservationList){
      if (element.data[0].selectionPassengers.selected.length === 0){
        return false;
      }
    }
    return true;
  }

  isSelectSegmentsPost(): boolean{
    for (const element of this.dataReservationList){
      if (element.data[0].selectionSegments.selected.length === 0){
        return false;
      }
    }
    return true;
  }

  isSelectCouponsPost(): boolean{
    for (const element of this.dataTktList){
      if (element.data[0].selectionCoupons.selected.length === 0){
        return false;
      }
    }
    return true;
  }

  // Whether the number of selected elements matches the total number of rows (los cupones solo los usa post venta)
  isAllSelectedCoupons(i : number) {
    const numSelectedCoupons = this.dataTktList[i].data[0].selectionCoupons.selected.length;
    const numRowsCoupons = this.dataVoucherList[i].data.length;

    return numSelectedCoupons === (numRowsCoupons - this.dataTktList[i].data[0].invalidCouponsQuantity);
  }

  // Selects all rows if they are not all selected; otherwise clear selection
  masterToggleCoupons(i : number) {
    this.isAllSelectedCoupons(i) ?
      this.dataTktList[i].data[0].selectionCoupons.clear() :
      this.dataVoucherList[i].data.forEach((row: any) => {
        if (!this.invalidCouponStatusList.includes(row.status)) {
          this.dataTktList[i].data[0].selectionCoupons.select(row);
        }
      });
  }

  /**
   * returns the waiver motifs for the selected type
   */
  getWaiverMotif(): void {
    this.dataReservation = new MatTableDataSource();
    this.dataTkt = new MatTableDataSource();
    this.dataReservationList = [];
    this.dataTktList = [];
    this.ticketList.splice(1);
    this.pnrList.splice(1);
    this.tabNavList.splice(1);
    this.resumenNavList.splice(1);
    this.selectedTypeSearch = null;
    this.waiverModif = null;
    this.pnrList.forEach(item => item.pnrNumber = null);
    this.ticketList.forEach(item => item.ticket = null);
    this.waiver.pnrNumber = null;
    this.disablePnrTabs = true;
    this.disableTktTabs = true;
    this.selectedTypeCategory = this.selectedType.waiverCategory.code;

    this.waiverPostsaleService.getMotives(this.selectedType.id).subscribe({
      next: data => {
        this.waiverModif = data;
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }
  });

    this.cleanData();
    this.hideMessage();
  }

  getTypeSearch(): void {
    this.waiver.pnrNumber = null;
    this.waiver.ticket = null;
    this.pnrList.forEach(item => item.pnrNumber = null);
    this.ticketList.forEach(item => item.ticket = null);
    this.ticketList.splice(1);
    this.pnrList.splice(1);
    this.tabNavList.splice(1);
    this.disablePnrTabs = true;
    this.disableTktTabs = true;
    this.cleanData();
  }

  /**
   * returns the information of a pre-sale type pnr
   */
  getPnrPresale(): void {
    this.loading = true;
    this.cleanData();
    this.hideMessage();

    this.waiverPostsaleService.getPnr(this.waiver.pnrNumber).subscribe({
      next: data => {
        this.dataReservation.data = [{
          pnr: data['pnr'],
          booking: data['booking']
        }];
        data['passengers'].forEach((value, index) => {
          data['passengers'][index].id = index + 1;
        });
        this.dataPassengers.data = data['passengers'];
        this.dataSegments.data = data['segments'];

        for (let i = 0; i < this.dataPassengers.data.length; i++) {
          if (this.dataPassengers.data[i]['type'] === 'INF') {
            this.atLeastOneInfantPass = true;
            this.infantList.push(i);
            this.dataPassengers.data[i]['infantTkt'] = true;
          }
        }

        this.allPassengersInvalid = this.infantList.length === this.dataPassengers.data.length;

        for (const element of this.dataSegments.data) {
          element['selectedCoupons'] = [];
          element['invalidCoupons'] = 0;

          if (!this.validSegmentStatusList.includes(element['status'])) {
            this.invalidStatusSegmentsQuantity++;
          }
        }

        this.validPrePNR = true;
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.validPrePNR = false;
        this.showMessage(error);
        this.loading = false;
        console.log('error', error);
      }
  });

  }

  getListPnrPostSale(): void {

    this.disablePnrTabs = false;
    this.disableTktTabs = true;
    this.loading = true;
    this.cleanData();
    this.hideMessage();
    let pnrsCall = [];
    for (const element of this.pnrList){
      pnrsCall.push(this.waiverPostsaleService.getPnr(element.pnrNumber));
    }

    forkJoin(pnrsCall).subscribe({
      next: data => {
        data.map( (item, indexPnr) => {
          let dataSource = new MatTableDataSource<WaiverPostsaleFormInterface>()
          dataSource.data = [{
          pnr: item['pnr'],
          booking: item['booking'],
          passengerTicketRequestsCompleted: 0,
          invalidStatusSegmentsQuantity: 0,
          existPassengersWithoutTicket: true,
          validPostPNR: false,
          atLeastOneInfantPass: false,
          allPassengersInvalid: true,
          passengerTicketRequestsWithError: 0,
          infantCoupon: false,
          selectionPassengers: new SelectionModel<Element>(true, []),
          selectionSegments: new SelectionModel<Element>(true, []),
          selectionCoupons: new SelectionModel<Element>(true, []),
          segmentsWithOneCouponInvalidQuantity: 0,
          files: [],
          aditionalComment: '',
          pnrAgency: ''
        }];

        item['passengers'].map((value, index) => {
          item['passengers'][index].id = index + 1;
        });

        let dataPassengers = new MatTableDataSource(item['passengers']);
        let dataSegments = new MatTableDataSource(item['segments']);

        this.countInvalidStatusSegment(dataSegments, dataSource);

        if (this.selectedType.waiverCategory.code === 'POST') {
          dataSource.data[0].existPassengersWithoutTicket = true;


          this.findTktInfoForPassengersAndSegments(dataSource, dataPassengers, dataSegments, indexPnr);

        }


        this.osiInfo = item['osiInfo'];
        this.pnr = item['pnr'];
        this.booking = item['booking'];
        if (this.pnr && this.osiInfo && this.booking) {
          this.showQuestionMessage(
            this.pnr.pnrNumber,
            this.booking.iata,
            this.osiInfo
            );
          } else {
            dataSource.data[0].validPostPNR = true;
          }

          dataSource.data[0].validPostPNR = true;
          this.dataReservationList.push(dataSource);
          this.dataPassengersList.push(dataPassengers);
          this.dataSegmentsList.push(dataSegments);
        this.loading = false;
      })},
      error: (error: HttpErrorResponse) => {
        this.validPostPNR = false;
        this.showMessage(error);
        this.loading = false;
        console.log('error', error);
      }

  });


  }
  countInvalidStatusSegment(dataSegments: MatTableDataSource<unknown>, dataSource: MatTableDataSource<WaiverPostsaleFormInterface>) {
    for (const element of dataSegments.data) {
      element['selectedCoupons'] = [];
      element['invalidCoupons'] = 0;
      if (!this.validSegmentStatusList.includes(element['status'])) {
        dataSource.data[0].invalidStatusSegmentsQuantity++;
      }
    }
  }

  isTypeSearchPnrOrTicket(){
    return this.selectedTypeSearch == undefined || this.selectedTypeSearch == null;
  }

  // Este metodo se puede modificar con libertad para post venta
  findTktInfoForPassengersAndSegments(
    dataSource: MatTableDataSource<WaiverPostsaleFormInterface>,
    dataPassengers: MatTableDataSource<any>,
    dataSegments: MatTableDataSource<any>,
    indexPnr: number): void {

      const infantList: Array<number> = [];
      for (let i = 0; i < dataPassengers.data.length; i++) {

        if (i < dataPassengers.data.length) {
          let l: number = i;
          ;
          dataPassengers.data[i]['allCouponsInvalid'] = true;

          if (!dataPassengers.data[i]['ticketNumber'] ||
            dataPassengers.data[i]['ticketNumber'] === '' ||
            !dataPassengers.data[i]['ticketNumber'].trim()) {
            dataPassengers.data[i]['invalidTkt'] = true;
            dataSource.data[0].passengerTicketRequestsCompleted++;
          } else if (dataPassengers.data[i]['ticketNumber'] !== '' && dataPassengers.data[i]['type'] == 'INF') {
            // Logica para los INF
            dataSource.data[0].atLeastOneInfantPass = true;
            infantList.push(l);
            dataPassengers.data[l]['infantTkt'] = true;
            dataPassengers.data[l]['allCouponsInvalid'] = false;
            dataSource.data[0].existPassengersWithoutTicket = false;
            dataSource.data[0].passengerTicketRequestsCompleted++;
          } else {
            dataSource.data[0].existPassengersWithoutTicket = false;
            this.getPnrtkt(dataPassengers, dataSource,i,l, dataSegments);

          }
        }


      }
      this.infantListByPassenger.push(infantList);

      this.addInfoInfantPassengerCoupons(this.infantListByPassenger[indexPnr], dataSource, dataPassengers);
      if (dataSource.data[0].passengerTicketRequestsCompleted === dataPassengers.data.length && dataSource.data[0].existPassengersWithoutTicket) {
        dataSource.data[0].existPassengersWithoutTicket = false;

        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.pnrWithoutTkt')
        );
      }
  }
  getPnrtkt(dataPassengers: MatTableDataSource<any>,
          dataSource: MatTableDataSource<WaiverPostsaleFormInterface>,
          i: number,
          l: number,
          dataSegments: MatTableDataSource<any> ) {
    this.waiverPostsaleService.getPnrTkt(dataPassengers.data[i]['ticketNumber'].substring(0, 13)).subscribe({
      next: data => {
        let couponsForDeleteIndexes: number[] = [];
        let carrierMarketingFoundOnSegmentList: boolean = false;

        for (let j = 0; j < data['coupons'].length; j++) {
          carrierMarketingFoundOnSegmentList = false;
          for (const element of dataSegments.data) {
            if (data['coupons'][j]['carrierMarketing'] === element['carrierMarketing']) {
              carrierMarketingFoundOnSegmentList = true;
              break;
            }
          }

          if (!carrierMarketingFoundOnSegmentList) {
            couponsForDeleteIndexes.push(j);
          }
        }

        couponsForDeleteIndexes.forEach(n => {
          data['coupons'].splice(n, 1);
        });

        for (const element of data['coupons']) {
          element['tkt'] = dataPassengers.data[l]['ticketNumber'];
          if (element['status'] === 'OK') {
            dataPassengers.data[l]['allCouponsInvalid'] = false;
            break;
          }
        }

        dataPassengers.data[l]['coupons'] = data['coupons'];

        if (!dataPassengers.data[l]['allCouponsInvalid']) {
          dataSource.data[0].allPassengersInvalid = false;
        }

        dataSource.data[0].passengerTicketRequestsCompleted++;
      },
      error: (error: HttpErrorResponse) => {
        dataSource.data[0].passengerTicketRequestsCompleted++;
        dataSource.data[0].passengerTicketRequestsWithError++;
        dataPassengers.data[l]['validTktResponse'] = false;
        this.showMessage(error, dataPassengers.data[i]['ticketNumber']);
        console.log('error', error);
      }
  });
  }


  /**
   * Metodo que permite que los infant tengan los cupones de un pasajero adulto (exlusivo post venta)
   */
  addInfoInfantPassengerCoupons(infantList, dataSource, dataPassengers) {
    if (infantList.length > 0 && dataSource.data[0].infantCoupon === false) {
      let indexPassInfo: number = -1;
      for (let pass = 0; pass < dataPassengers.data.length; pass++) {
        if (dataPassengers.data[pass]['allCouponsInvalid'] === false
          && dataPassengers.data[pass]['coupons'] != null) {
          indexPassInfo = pass;
          break;
        }
      }

      if (indexPassInfo !== -1) {
        for (const element of infantList) {
          dataPassengers.data[element]['coupons'] = dataPassengers.data[indexPassInfo]['coupons'];
        }
        dataSource.data[0].infantCoupon = true;
      }
    }
  }

  /**
   * returns the information of a tkt
   */
   getListTkt(): void {
    this.loading = true;
    this.disablePnrTabs = true;
    this.disableTktTabs = false;
    this.cleanData();
    this.hideMessage();
    let ticketsCall = [];
    for (const element of this.ticketList){
      ticketsCall.push(this.waiverPostsaleService.getTkt(element.ticket));
    }

    forkJoin(ticketsCall).subscribe({
      next: data => {
        data.map( (item, index) =>{
          let dataSource = new MatTableDataSource<WaiverPostsaleTicketFormInterface>()
          dataSource.data = [{
          tkt: item['tkt'],
          issuer: item['issuer'],
          booking: item['booking'],
          fare: item['fare'],
          passenger: item['passenger'],
          remark: item['remark'],
          invalidCouponsQuantity: 0,
          selectionCoupons: new SelectionModel<Element>(true, []),
          validPostTKT: false,
          files: [],
          aditionalComment: '',
          pnrAgency: ''
        }];

        this.dataVoucher.data = item['coupons'];
        dataSource.data[0].validPostTKT = true;
        this.loading = false;

        for (let dataVoucher of this.dataVoucher.data) {
          if (this.invalidCouponStatusList.includes(dataVoucher['status'])) { dataSource.data[0].invalidCouponsQuantity++; }
        }
        this.dataVoucherList.push(new MatTableDataSource(item['coupons']));

        this.dataTktList.push(dataSource);
      })},
      error: (error: HttpErrorResponse) => {
        this.showMessage(error);
        this.loading = false;
        console.log('error', error);
      }
   });
  }

  /**
   * method to resume info
   */
  summaryInfoWaiver(): Waiver {
    if (this.dataReservation.data.length !== 0) {
      this.summaryInfoWaiverDataReservation();

    } else if (this.dataTkt.data.length !== 0) {
      this.waiver.tkt = this.dataTkt.data[0]['tkt'];
      this.waiver.booking = this.dataTkt.data[0]['booking'];
      this.waiver.fare = this.dataTkt.data[0]['fare'];
      this.waiver.coupons = this.selectionCoupons.selected.sort(
        (a: any, b: any) => (a.couponNumber > b.couponNumber) ? 1 : ((b.couponNumber > a.couponNumber) ? -1 : 0)
      );
      this.waiver.document = 'tkt';
      this.waiver.documentNumber = this.dataTkt.data[0]['tkt']['tktNumber'];
      this.waiver.documentCreationDate = this.dataTkt.data[0]['tkt']['createDateTime'];
      this.waiver.documentPos = this.dataTkt.data[0]['issuer']['pos'];
      this.waiver.documentTktPnrReference = this.dataTkt.data[0]['tkt']['pnr'];
      this.waiver.passengers[0] = this.dataTkt.data[0]['passenger'];
      this.waiver.remark = this.dataTkt.data[0]['remark']['text'];
      this.waiver.numberOfPassenger = this.waiver.passengers.length;
    }

    this.waiver.aditionalComment = this.aditionalComment;
    this.waiver.type = this.selectedType;
    this.waiver.motive = this.selectedMotif;
    this.waiver.category = this.selectedType.waiverCategory;
    // Seteo de array de archivos subidos al file storage
    let archivos = [];
    this.files.forEach(fil => {
      archivos.push({
        name: fil.data.name.toString(),
        size: fil.data.size.toString(),
        type: fil.data.type.toString(),
        locationStorage: fil.data['locationStorage']
      });
    });
    this.waiver.files = archivos;
    this.waivers.push(this.waiver);
    return this.waiver;
  }

  summaryInfoWaiverDataReservation() {
    this.waiver.pnr = this.dataReservation.data[0]['pnr'];
      this.waiver.booking = this.dataReservation.data[0]['booking'];
      this.waiver.passengers = this.selectionPassengers.selected;
      this.waiver.segments = this.selectionSegments.selected.sort(
        (a: any, b: any) => (a.segmentNumber > b.segmentNumber) ? 1 : ((b.segmentNumber > a.segmentNumber) ? -1 : 0)
      );
      this.waiver.document = 'pnr';
      this.waiver.documentNumber = this.dataReservation.data[0]['pnr']['pnrNumber'];
      this.waiver.documentCreationDate = this.dataReservation.data[0]['pnr']['createDateTime'];
      this.waiver.documentPos = this.dataReservation.data[0]['booking']['pos'];
      this.waiver.numberOfPassenger = this.dataPassengers.data.length;
  }

  summaryInfoWaiverList(): Waiver[] {


    if (this.dataReservationList.length !== 0 && this.waivers.length === 0) {
      this.sumaryInfoWaiverListReservation();
    }

    if (this.dataTktList.length !== 0 && this.waivers.length === 0) {
      this.dataTktList.forEach((dataTkt,i) => {
        let waiver: Waiver = new Waiver();
        waiver.tkt = dataTkt.data[0]['tkt'];
        waiver.booking = dataTkt.data[0]['booking'];
        waiver.fare = dataTkt.data[0]['fare'];
        waiver.coupons = dataTkt.data[0].selectionCoupons.selected.sort(
          (a: any, b: any) => (a.couponNumber > b.couponNumber) ? 1 : ((b.couponNumber > a.couponNumber) ? -1 : 0)
        );
        waiver.document = 'tkt';
        waiver.documentNumber = dataTkt.data[0]['tkt']['tktNumber'];
        waiver.documentCreationDate = dataTkt.data[0]['tkt']['createDateTime'];
        waiver.documentPos = dataTkt.data[0]['issuer']['pos'];
        waiver.documentTktPnrReference = dataTkt.data[0]['tkt']['pnr'];
        waiver.passengers = [];
        waiver.passengers[0] = dataTkt.data[0]['passenger'];
        waiver.segments = [];
        waiver.remark = dataTkt.data[0]['remark']['text'];
        waiver.numberOfPassenger = waiver.passengers.length;
        waiver.aditionalComment = dataTkt.data[0].aditionalComment;
        waiver.pnrNumber = this.ticketList[i]?.ticket;
        waiver.pnrAgency = dataTkt.data[0].pnrAgency;

        // Seteo de array de archivos subidos al file storage
        let archivos = [];
        dataTkt.data[0].files.forEach(fil => {
          archivos.push({
            name: fil.data.name.toString(),
            size: fil.data.size.toString(),
            type: fil.data.type.toString(),
            locationStorage: fil.data['locationStorage']
          });
        });
        this.files.forEach(fil => {
          archivos.push({
            name: fil.data.name.toString(),
            size: fil.data.size.toString(),
            type: fil.data.type.toString(),
            locationStorage: fil.data['locationStorage']
          });
        });

        waiver.files = archivos;
        waiver.type = this.selectedType;
        waiver.motive = this.selectedMotif;
        waiver.category = this.selectedType.waiverCategory;

        this.waivers.push(waiver);
      });
    }

    return this.waivers;
  }

  sumaryInfoWaiverListReservation() {
    this.dataReservationList.forEach((dataReservation, i) => {

        let waiver: Waiver = new Waiver();
        waiver.pnr = dataReservation.data[0]['pnr'];
        waiver.booking = dataReservation.data[0]['booking'];
        waiver.passengers = dataReservation.data[0].selectionPassengers.selected;
        waiver.segments = dataReservation.data[0].selectionSegments.selected.sort(
          (a: any, b: any) => (a.segmentNumber > b.segmentNumber) ? 1 : ((b.segmentNumber > a.segmentNumber) ? -1 : 0)
        );
        waiver.coupons = [];
        waiver.document = 'pnr';
        waiver.documentNumber = dataReservation.data[0]['pnr']['pnrNumber'];
        waiver.documentCreationDate = dataReservation.data[0]['pnr']['createDateTime'];
        waiver.documentPos = dataReservation.data[0]['booking']['pos'];
        waiver.numberOfPassenger = this.dataPassengersList[i].data.length;
        waiver.aditionalComment = dataReservation.data[0].aditionalComment;
        waiver.pnrNumber = this.pnrList[i]?.pnrNumber;
        waiver.pnrAgency = dataReservation.data[0].pnrAgency;


        // Seteo de array de archivos subidos al file storage
        let archivos = [];
        dataReservation.data[0].files.forEach(fil => {
          archivos.push({
            name: fil.data.name.toString(),
            size: fil.data.size.toString(),
            type: fil.data.type.toString(),
            locationStorage: fil.data['locationStorage']
          });
        });
        this.files.forEach(fil => {
          archivos.push({
            name: fil.data.name.toString(),
            size: fil.data.size.toString(),
            type: fil.data.type.toString(),
            locationStorage: fil.data['locationStorage']
          });
        });
        waiver.files = archivos;
        waiver.type = this.selectedType;
        waiver.motive = this.selectedMotif;
        waiver.category = this.selectedType.waiverCategory;

        this.waivers.push(waiver);
      });
  }

  /**
   * method to save a waiver request in the database
   */
  saveWaiver(): void {
    this.loadingSave = true;

    if (this.infoUser.isAgencySession()) {
      this.waiver = this.summaryInfoWaiver();
      if (this.validateAditionalComment(this.waiver.aditionalComment) && this.validatePnrAgency(this.waiver.pnrAgency)) {
        if (this.selectedMotif.saveWis === true
          && this.waiver.category.code === 'POST'
          && this.marketRequest.waiverEnableAutoapprove
          && !this.isForcedManually) {
            this.waiverPostsaleService.saveWaiverJwt(new WaiverToken(this.waiverToken, this.waiver.aditionalComment, this.waiver.pnrAgency))
            .subscribe({
              next: data => {
                this.isSuccess = true;
                this.loadingSave = false;
                this.showSuccessMessage(true, data.status);
              },
              error: (error: HttpErrorResponse) => {
                this.isSuccess = false;
                this.messageErrorService.sendError(error, 'error.request');
                this.loadingSave = false;
                console.log('error 1:', error);
              }
          });
        } else {
          this.waiverPostsaleService.saveWaiver(this.waiver).subscribe({
            next: data => {
              this.isSuccess = true;
              this.loadingSave = false;
              this.showSuccessMessage(false, data.status);
            },
            error: (error: HttpErrorResponse) => {
              this.isSuccess = false;
              this.messageErrorService.sendError(error, 'error.request');
              this.loadingSave = false;
              console.log('error 2:', error);
            }});
        }
      } else {
        this.loadingSave = false;
      }
    } else {
      this.messageService.showErrorMessage(
        new Exception(SystemException, this.translateService.instant('notFound.waiverRequest')),
        this.translateService.instant('button.confirm')
      );

      this.loadingSave = false;
    }
  }

  /**
   * method to save a waiver request in the database
   */
   saveListWaiver(): void {
    this.loadingSave = true;

    if (this.infoUser.isAgencySession()) {
      this.waivers = this.summaryInfoWaiverList();
      if (this.validateAditionalComment(this.waiver.aditionalComment) && this.validatePnrAgency(this.waiver.pnrAgency)) {
        if (this.selectedMotif.saveWis === true
          && this.waiver.category.code === 'POST'
          && this.marketRequest.waiverEnableAutoapprove
          && !this.isForcedManually) {
          this.waiverPostsaleService.saveWaiverJwt(new WaiverToken(this.waiverToken, this.waiver.aditionalComment, this.waiver.pnrAgency))
            .subscribe({
              next: data => {
                this.isSuccess = true;
                this.loadingSave = false;
                this.showSuccessMessage(true, data.status);
              },
              error: (error: HttpErrorResponse) => {
                this.isSuccess = false;
                this.messageErrorService.sendError(error, 'error.request');
                this.loadingSave = false;
                console.log('error 1:', error);
              }
          });
        } else {
          this.waiverPostsaleService.saveListWaiver(this.waivers).subscribe({
            next: data => {
              data.forEach( item => {
              this.isSuccess = true;
              this.loadingSave = false;
              this.showSuccessMessage(false, item.status);
        })},
            error: (error: HttpErrorResponse) => {
              this.isSuccess = false;
              this.messageErrorService.sendError(error, 'error.request');
              this.loadingSave = false;
              console.log('error 2:', error);
            }});
        }
      } else {
        this.loadingSave = false;
      }
    } else {
      this.messageService.showErrorMessage(
        new Exception(SystemException, this.translateService.instant('notFound.waiverRequest')),
        this.translateService.instant('button.confirm')
      );

      this.loadingSave = false;
    }
  }

  /**
   * method to calculate a waiver request in the database
   */
  calculateWaiver(): void {

    let otherAirlineGroupCarriers: number = 0;

    if (this.selectionSegments.selected.length !== 0) {
      this.dataSegments.data.forEach((s: any) => {
        if (!this.latamCarrierCodes.includes(s.carrierMarketing)) {
          otherAirlineGroupCarriers++;
        }
      });
    } else if (this.selectionCoupons.selected.length !== 0) {
      this.dataVoucher.data.forEach((c: any) => {
        if (!this.latamCarrierCodes.includes(c.carrierMarketing)) {
          otherAirlineGroupCarriers++;
        }
      });
    }

    this.validateForceManually(otherAirlineGroupCarriers);

    this.loadingSave = true;

    if (this.infoUser.isAgencySession()) {
      if (this.isWaiverTypeAutoapprove(this.selectedType.id, this.selectedMotif.id)
        && this.marketRequest.waiverEnableAutoapprove && !this.isForcedManually) {
          this.waiver = this.summaryInfoWaiver();
          this.waiver.valorization = '0';
        this.waiverPostsaleService.calculateWaiver(this.waiver).subscribe({
          next: data => {
            let value = data['valorization'];
            this.isSuccess = true;
            this.loadingSave = false;
            this.verifyCalculateWaiver = true;
            this.waiverToken = data['waiverValorizationToken'];
            this.valorWaiver = value.toString();
            this.waiver.valorization = this.valorWaiver;

          },
          error: (error: HttpErrorResponse) => {
            this.isSuccess = false;
            this.loadingSave = false;
            this.verifyCalculateWaiver = true;
            this.valorWaiver = 'NA';
            this.showErrorMessage(error);

            console.log('error:', error);
          }});
      } else {
        this.isSuccess = false;
        this.loadingSave = false;
        this.verifyCalculateWaiver = false;
      }
    } else {
      this.messageService.showErrorMessage(
        new Exception(SystemException, this.translateService.instant('notFound.waiverRequest')),
        this.translateService.instant('button.confirm')
      );

      this.loadingSave = false;
    }

  }

  validateForceManually(otherAirlineGroupCarriers: number) {
    if (otherAirlineGroupCarriers > 0) {
      this.isForcedManually = true;
    } else {
      this.isForcedManually = false;
    }
  }

  showErrorMessage(error: HttpErrorResponse) {
    if (error.error?.type === BusinessException) {
      this.messageService.showErrorMessage(
        new Exception(
          error.error.type,
          error.error.description,
          error.status
        ),
        this.translateService.instant('button.confirm')
      );
    } else {
      this.showMessageWaiverManual();
    }
  }

  /**
   * method to calculate a waiver request in the database
   */
   calculateListWaiver(): void {
       let i : number = 0;
    let otherAirlineGroupCarriers: number = 0;
    if (this.selectionSegments.selected.length !== 0) {
      this.dataSegmentsList[i].data.forEach((s: any) => {
        if (!this.latamCarrierCodes.includes(s.carrierMarketing)) {
          otherAirlineGroupCarriers++;
        }
      });
    } else if (this.selectionCoupons.selected.length !== 0) {
      this.dataVoucher.data.forEach((c: any) => {
        if (!this.latamCarrierCodes.includes(c.carrierMarketing)) {
          otherAirlineGroupCarriers++;
        }
      });
    }

    if (otherAirlineGroupCarriers > 0) {
      this.isForcedManually = true;
    } else {
      this.isForcedManually = false;
    }

    this.loadingSave = true;
;
    if (this.infoUser.isAgencySession()) {
        this.isSuccess = false;
        this.loadingSave = false;
        this.verifyCalculateWaiver = false;
    } else {
      this.messageService.showErrorMessage(
        new Exception(SystemException, this.translateService.instant('notFound.waiverRequest')),
        this.translateService.instant('button.confirm')
      );

      this.loadingSave = false;
    }
  }

  /**
   * Este metodo permite obtener los tipos de waiver
   * @param selectedTypeId
   * @param selectedMotifId
   */
  private isWaiverTypeAutoapprove(selectedTypeId: number, selectedMotifId: number): boolean {
    return false;

  }

  /**
   * returns true at home that the string contains less than 500 characters. otherwise it returns false
   */
  validateAditionalComment(comment: string): boolean {
    if (!comment) {
      return (true);
    }

      return (comment.length <= 500);
  }

  /**
   * returns true at case that the string contains less than 11 characters. otherwise it returns false
   */
  validatePnrAgency(pnrAgency: string): boolean {
    if (!pnrAgency) {
      return (true);
    }

    return (pnrAgency.length <= 10);
  }

  /**
   * initializes the values ​​of the component
   */
  cleanData() {
    this.dataReservation.data = [];
    this.dataReservationList = []
    this.dataPassengers.data = [];
    this.dataPassengersList = [];
    this.dataSegments.data = [];
    this.dataSegmentsList = [];
    this.dataVoucher.data = [];
    this.dataVoucherList = [];
    this.validPrePNR = false;
    this.validPostPNR = false;
    this.selectionPassengers.clear();
    this.selectionSegments.clear();
    this.selectionCoupons.clear();
    this.dataTktList = [];
    this.invalidCouponsQuantity = 0;
    this.aditionalComment = '';
    this.files = [];
    this.passengerTicketRequestsCompleted = 0;
    this.passengerTicketRequestsWithError = 0;
    this.invalidStatusSegmentsQuantity = 0;
    this.infantList = [];
    this.infantCoupon = false;
    this.allPassengersInvalid = true;
    this.atLeastOneInfantPass = false;
    this.cleanWaiverObject();
  }

  /**
   * shows a message of Waiver Manual
   */
  public showMessageWaiverManual() {
    let messageCalculateSystemException = this.translateService.instant('error.notValuation');

    swal.fire({
      title: `<p>${ messageCalculateSystemException }</p>`,
      confirmButtonText: this.translateService.instant('button.confirm'),
      confirmButtonColor: '#d33',
      icon: 'error',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.isForcedManually = true;
      }
    });
  }

  /**
   * shows a message of success
   */
  showSuccessMessage(automatic: boolean, waiverStatus: WaiverStatus) {
    let messageTextWaiverSuccess: string;
    let title = this.translateService.instant('popup.wellDone');
    let type: SweetAlertIcon = 'success';
    const pending = 1;

    if (automatic) {
      if (waiverStatus.id === pending) {
        title = this.translateService.instant('attention');
        messageTextWaiverSuccess = this.translateService.instant('warning.noApprovedWaiver');
        type = 'warning';
      } else {
        messageTextWaiverSuccess = this.translateService.instant('success.request');
      }
    } else {
      messageTextWaiverSuccess = this.translateService.instant('success.sendRequest');
    }

    swal.fire({
      title: `<p>${ title }</p>`,
      html: `<p>${ messageTextWaiverSuccess }</p>`,
      confirmButtonText: this.translateService.instant('button.backList'),
      confirmButtonColor: '#d33',
      icon: type,
      allowOutsideClick: false
    }).then((result) => {
      this.navigateThenMessage(result, waiverStatus, pending);

    });
  }

  navigateThenMessage(result: SweetAlertResult<unknown>, waiverStatus: WaiverStatus, pending: number) {
    if (result.value) {
      if (this.infoUser.isAgencyGroupUser() ||  this.infoUser.isAgentSuc()) {
        if (waiverStatus.id === pending) {
          this.router.navigateByUrl('/agency/agent/waivers/list');
        } else {
          this.router.navigateByUrl('/agency/agent/waivers/list?active-tab=approved');
        }
      } else if (this.infoUser.isAdminAgencyGroupUser() || this.infoUser.isManualAgencyGroupUser() || this.infoUser.isAdminBranchOfficeUser()) {
        if (waiverStatus.id === pending) {
          this.router.navigateByUrl('/agency/waivers/list');
        } else {
          this.router.navigateByUrl('/agency/waivers/list?active-tab=approved');
        }
      }
    }
  }

  /**
   * erase previous information from waivers
   */
  private cleanWaiverObject() {
    this.waiver.pnr = null;
    this.waiver.booking = null;
    this.waiver.tkt = null;
    this.waiver.fare = null;
    this.waiver.document = null;
    this.waiver.documentNumber = null;
    this.waiver.documentPos = null;
    this.waiver.documentCreationDate = null;
    this.waiver.documentTktPnrReference = null;
    this.waiver.coupons = [];
    this.waiver.passengers = [];
    this.waiver.segments = [];
    this.waiver.aditionalComment = null;
    this.waiver.pnrAgency = null;
    this.waiver.files = null;
    this.waivers = [];
  }

  /**
   * shows an error message
   * @param e object of error / contains the error detail
   */
  public showMessage(e: HttpErrorResponse, ticket = '') {
    this.message.show = true;
    if (e.status === 404) {
      this.message.status = 2;
      swal.fire({
        title: "<p> "+this.translateService.instant('error.notDataFound')+"</p>",
        confirmButtonText: this.translateService.instant('button.confirm'),
        confirmButtonColor: '#d33',
        icon: 'error',
        allowOutsideClick: false
      })
    } else {
      if (e.message === SystemException) {
        this.message.status = 1;
        this.message.detail = '';
        swal.fire({
          title: "<p> "+this.translateService.instant('error.notFound')+"</p>",
          confirmButtonText: this.translateService.instant('button.confirm'),
          confirmButtonColor: '#d33',
          icon: 'error',
          allowOutsideClick: false
        })
      } else {
        this.message.status = 0;
        this.message.detail = "sin respuesta";
        swal.fire({
          title: "<p> "+this.translateService.instant('error.consultaTicket')+ ", ticket: " + ticket + " </p>",
          confirmButtonText: this.translateService.instant('button.confirm'),
          confirmButtonColor: '#d33',
          icon: 'error',
          allowOutsideClick: false
        })
      }
    }
  }

  /**
   * hide the message
   */
  hideMessage() {
    this.message.show = false;
    this.message.detail = '';
    this.message.status = 0;
  }

  /**
   * start the modal with the waiver detail
   */
   openDialog(i: number): void {
    let dialogRef = this.dialog.open(DetailWaiverComponent, {
      width: '90vw',
      height: '65vh',
      disableClose: true
    });

    dialogRef.componentInstance.dataReservation = this.dataReservationList[i];
    dialogRef.componentInstance.dataPassengers.data = this.dataReservationList[i]?.data[0]?.selectionPassengers.selected;
    dialogRef.componentInstance.dataSegments.data = this.dataReservationList[i]?.data[0]?.selectionSegments.selected;
    dialogRef.componentInstance.dataTkt = this.dataTktList[i];
    dialogRef.componentInstance.dataVoucher.data = this.dataTktList[i]?.data[0]?.selectionCoupons.selected;
    dialogRef.componentInstance.validPrePNR = this.validPrePNR;
    dialogRef.componentInstance.validPostPNR = this.isValidPostPNR();
    dialogRef.componentInstance.validPostTKT = this.isValidPostTKT();
    dialogRef.componentInstance.loading = this.loading;
    dialogRef.componentInstance.pnrNumber = this.pnrList[i]?.pnrNumber;
    dialogRef.componentInstance.ticket = this.ticketList[i]?.ticket;
  }

  openDialogPre(): void {
    let dialogRef = this.dialog.open(DetailWaiverComponent, {
      width: '90vw',
      height: '65vh',
      disableClose: true
    });

    dialogRef.componentInstance.dataReservation = this.dataReservation;
    dialogRef.componentInstance.dataPassengers.data = this.selectionPassengers.selected;
    dialogRef.componentInstance.dataSegments.data = this.selectionSegments.selected;
    dialogRef.componentInstance.dataTkt = this.dataTkt;
    dialogRef.componentInstance.dataVoucher.data = this.selectionCoupons.selected;
    dialogRef.componentInstance.validPrePNR = this.validPrePNR;
    dialogRef.componentInstance.validPostPNR = this.validPostPNR;
    dialogRef.componentInstance.validPostTKT = false;
    dialogRef.componentInstance.loading = this.loading;
    dialogRef.componentInstance.pnrNumber = this.waiver.pnrNumber;
    dialogRef.componentInstance.ticket = this.waiver.ticket;
  }


  highlightReservationInformationRow(event: Event, rowIndex: number) {
    this.selectedReservationInformationRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  highlightPassengerInformationRow(event: Event, rowIndex: number) {
    this.selectedPassengerInformationRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  highlightSegmentsInformationRow(event: Event, rowIndex: number) {
    this.selectedSegmentsInformationRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  highlightTktInformationRow(event: Event, rowIndex: number) {
    this.selectedTktInformationRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  highlightCouponsInformationRow(event: Event, rowIndex: number) {
    this.selectedCouponsInformationRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  toggleSelectionCouponsRow(row) {
    if (!this.invalidCouponStatusList.includes(row.status)) {
      this.selectionCoupons.toggle(row);
    }
  }

  toggleSelectionCouponsRowPost(row, i: number) {
    if (!this.invalidCouponStatusList.includes(row.status)) {
      this.dataTktList[i].data[0].selectionCoupons.toggle(row);
    }
  }

  toggleSelectionPassengerPre(row) {
    let clearData = false;

    if (!row.infantTkt) {
      if (!this.selectionPassengers.isSelected(row)) {
        if (row.type !== 'INF' && this.atLeastOneInfantPass) {
          // Logica para habilitar los campos cuando es un adulto para un inf
          this.setInfantTktPropertie(false);
        }
      } else {
        // logica de seleccion para el tipo de usuario INF
        clearData = this.toggleSelectionPassengerInfPre(row);
      }

      this.selectionPassengers.toggle(row);

      if (clearData) {
        this.selectionPassengers.clear();
      }
    }
  }

  toggleSelectionPassengerInfPre(row): boolean {
    if (this.infantList.length > 0) {
          let selectionAdlt: boolean = false;

          if (this.selectionPassengers.selected.length - 1 > 0) {
            this.selectionPassengers.selected.forEach((passenger: any) => {
              if (passenger.type !== 'INF' && passenger.ticketNumber !== row.ticketNumber) {
                selectionAdlt = true;
              }
            });

            if (selectionAdlt) {
              this.setInfantTktPropertie(false);
            } else {
              this.setInfantTktPropertie(true);
              return true;
            }
          } else {
            this.setInfantTktPropertie(true);
          }
        }
        return false;
  }

  findCouponBySourceDestination(coupons: Array<any>, src: string, dst: string) {
    return coupons.find(coupon => coupon.source == src && coupon.destination == dst);
  }

  toggleSelectionPassengerPost(indexReservation: number,row: any) {
    let clearData = false;

    if (!row.invalidTkt && !row.allCouponsInvalid && !row.infantTkt) {
      if (!this.dataReservationList[indexReservation].data[0].selectionPassengers.isSelected(row)) {
        this.processSegment(indexReservation, row);

        if (row.type !== 'INF' && this.dataReservationList[indexReservation].data[0].atLeastOneInfantPass) {
          // Logica para habilitar los campos cuando es un adulto para un inf
          this.setInfantTktPropertiePost(false, indexReservation);
        }
      } else {
        this.processSegmentFindTkt(indexReservation, row);
        // logica de seleccion para el tipo de usuario INF
        clearData = this.selectPassengerPostInf(indexReservation, clearData, row);
      }

      this.dataReservationList[indexReservation].data[0].selectionPassengers.toggle(row);
      this.dataReservationList[indexReservation].data[0].selectionSegments.selected.forEach((s: any) => {
        if (!this.validSegmentStatusList.includes(s.status) || s.invalidCoupons > 0) {
          this.dataReservationList[indexReservation].data[0].selectionSegments.toggle(s);
        }
      });
      this.dataReservationList[indexReservation].data[0].segmentsWithOneCouponInvalidQuantity = 0;

      this.countSegmentWithOneCouponInvalid(indexReservation);

      if (clearData) {
        this.dataReservationList[indexReservation].data[0].selectionPassengers.clear();
      }
    }
  }

  processSegment(indexReservation: number, row: any) {
    for (const element of this.dataSegmentsList[indexReservation].data) {
          let coupon = this.findCouponBySourceDestination(
            row.coupons, element['source'], element['destination']
          );

          if (!coupon) {
            continue;
          }

          element['selectedCoupons'].push(coupon);

          if (this.invalidCouponStatusList.includes(coupon['status'])) {
            element['invalidCoupons']++;
          }
        }
  }

  processSegmentFindTkt(indexReservation: number, row) {
    for (const element of this.dataSegmentsList[indexReservation].data) {
          let coupon = this.findCouponBySourceDestination(
            row.coupons, element['source'], element['destination']
          );

          if (!coupon) {
            continue;
          }

          element['selectedCoupons'].splice(
            element['selectedCoupons'].findIndex(c => c['tkt'] === coupon['tkt']), 1
          );

          if (this.invalidCouponStatusList.includes(coupon['status'])) {
            element['invalidCoupons']--;
          }
        }
  }

  countSegmentWithOneCouponInvalid(indexReservation: number) {
    for (let i = 0; i < this.dataSegments.data.length; i++) {
        if (this.validSegmentStatusList.includes(this.dataSegmentsList[indexReservation].data[i]['status']) && this.dataSegmentsList[indexReservation].data[i]['invalidCoupons'] > 0) {
          this.dataReservationList[indexReservation].data[0].segmentsWithOneCouponInvalidQuantity++;
        }
      }
  }

  selectPassengerPostInf(indexReservation: number, clearData: boolean, row: any) {
    if (this.infantList.length > 0) {
      let selectionAdlt: boolean = false;

      if (this.dataReservationList[indexReservation].data[0].selectionPassengers.selected.length - 1 > 0) {
        this.dataReservationList[indexReservation].data[0].selectionPassengers.selected.forEach((passenger: any) => {
          if (passenger.type !== 'INF' && passenger.ticketNumber !== row.ticketNumber) {
            selectionAdlt = true;
          }
        });

        if (selectionAdlt) {
          this.setInfantTktPropertiePost(false, indexReservation);
        } else {
          this.setInfantTktPropertiePost(true, indexReservation);
          return  true;
        }
      } else {
        this.setInfantTktPropertiePost(true, indexReservation);
      }
    }
    return false;
  }

  private setInfantTktPropertie(needDisabled: boolean) {
    if (this.infantList.length > 0 && this.atLeastOneInfantPass) {
      for (const element of this.infantList) {
        if (needDisabled) {
          this.dataPassengers.data[element]['infantTkt'] = true;
        } else {
          this.dataPassengers.data[element]['infantTkt'] = false;
        }
      }
    }
  }

  private setInfantTktPropertiePost(needDisabled: boolean, indexReservation: number) {
    if (this.infantListByPassenger[indexReservation].length > 0 && this.dataReservationList[indexReservation].data[0].atLeastOneInfantPass) {
      for (let inf = 0; inf < this.infantListByPassenger[indexReservation].length; inf++) {
        if (needDisabled) {
          this.dataPassengersList[indexReservation].data[this.infantList[inf]]['infantTkt'] = true;
        } else {
          this.dataPassengersList[indexReservation].data[this.infantList[inf]]['infantTkt'] = false;
        }
      }
    }
  }

  toggleSelectionSegmentPost(row, indexReservation: number) {
    if (this.validSegmentStatusList.includes(row.status) && row.invalidCoupons === 0) {
      this.dataReservationList[indexReservation].data[0].selectionSegments.toggle(row);
    }
  }

  toggleSelectionSegment(row){
    if (this.validSegmentStatusList.includes(row.status) && row.invalidCoupons === 0) {
      this.selectionSegments.toggle(row);
    }
  }

  onClick(i : number) {
    const fileUpload = document.getElementById('fileUpload-'+i) as HTMLInputElement;
    let sameFileCount: number;
    fileUpload.onchange = () => {
      let index = fileUpload.files.length - 1;
      const file = fileUpload.files[index];
      sameFileCount = 0;
      if (this.dataReservationList.length > 0){
        this.dataReservationList[i].data[0].files.forEach((f) => {
          if (f.data.name === file.name) {
            sameFileCount++;
          }
        });
      } else {
        this.dataTktList[i].data[0].files.forEach((f) => {
          if (f.data.name === file.name) {
            sameFileCount++;
          }
        });
      }

      if (!this.allowedFileFormats.includes(file.type)) {

        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.invalidFileFormat')
        );
      } else if (file.name.length > 100) {

        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.fileName')
        );
      } else if (file.size > 2097152) {

        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.maxSize')
        );
      } else {
        if (sameFileCount > 0) {
          this.messageService.showWarningMessage(
            this.translateService.instant('button.confirm'),
            this.translateService.instant('warning.alreadyLoadedFile')
          );
        } else {
          this.validateAndUploadFilePost(file, i);

        }
      }
    };

    fileUpload.value = '';
    fileUpload.click();
  }

  validateAndUploadFilePost(file: File, i: number) {
    if (this.dataReservationList.length > 0){
            this.dataReservationList[i].data[0].files.push({
              data: file, state: 'in', inProgress: false, progress: 0, canRetry: false, canCancel: true
            });
            let indexZ = this.dataReservationList[i].data[0].files.length - 1;
            this.uploadFilePost(this.dataReservationList[i].data[0].files[indexZ], i);
          } else {
            this.dataTktList[i].data[0].files.push({
              data: file, state: 'in', inProgress: false, progress: 0, canRetry: false, canCancel: true
            });
            let indexZ = this.dataTktList[i].data[0].files.length - 1;
            this.uploadFilePost(this.dataTktList[i].data[0].files[indexZ], i);
          }
  }

  onClickAll() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    let sameFileCount: number;
    fileUpload.onchange = () => {
      let index = fileUpload.files.length - 1;
      const file = fileUpload.files[index];
      sameFileCount = 0;
      this.files.forEach((f) => {
        if (f.data.name === file.name) {
          sameFileCount++;
        }
      });

      if (!this.allowedFileFormats.includes(file.type)) {

        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.invalidFileFormat')
        );
      } else if (file.name.length > 100) {

        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.fileName')
        );
      } else if (file.size > 2097152) {

        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.maxSize')
        );
      } else {
        if (sameFileCount > 0) {
          this.messageService.showWarningMessage(
            this.translateService.instant('button.confirm'),
            this.translateService.instant('warning.alreadyLoadedFile')
          );
        } else {
            this.files.push({
              data: file, state: 'in', inProgress: false, progress: 0, canRetry: false, canCancel: true
            });
            let indexZ = this.files.length - 1;
            this.uploadFile(this.files[indexZ]);
        }
      }
    };

    fileUpload.value = '';
    fileUpload.click();
  }

  cancelFile(file: FileUploadModel, indexReservation = 0) {
    file.deletingFile = true;

    this.fileStorageService.deleteFile(file.data['locationStorage']).subscribe({
      next: (event: any) => {
        if (this.selectedTypeCategory == 'PRE'){
          this.removeFileFromArray(file);
        } else {
          this.removeFileFromArrayPost(file, indexReservation);
        }
        file.deletingFile = false;
      },
      error: (error: HttpErrorResponse) => {
        file.deletingFile = false;
        console.log('error', error);
      }});
  }

  cancelFileAll(file: FileUploadModel) {
    file.deletingFile = true;

    this.fileStorageService.deleteFile(file.data['locationStorage']).subscribe({
      next: (event: any) => {
          this.removeFileFromArray(file);

        file.deletingFile = false;
      },
      error: (error: HttpErrorResponse) => {
        file.deletingFile = false;
        console.log('error', error);
      }});
  }

  retryFile(file: FileUploadModel, index = 0) {
    if (this.selectedTypeCategory == 'PRE'){
      this.uploadFile(file);
    } else {
      this.uploadFilePost(file, index);
    }
    file.canRetry = false;
  }

  private uploadFile(file: FileUploadModel) {
    file.inProgress = true;
    this.loadingUpload = true;

    this.fileStorageService.uploadFile(file.data).subscribe({
      next: (event: any) => {
        let indexZ = this.files.length - 1;
        this.files[indexZ].data['locationStorage'] = event.reference;
        this.loadingUpload = false;
      },
      error: (error: HttpErrorResponse) => {
        this.removeFileFromArray(file);
        console.log('error', error);
        this.loadingUpload = false;
      }});
  }

  private uploadFilePost(file: FileUploadModel, index: number) {
    this.loadingUpload = true;
    file.inProgress = true;

    this.fileStorageService.uploadFile(file.data).subscribe({
      next: (event: any) => {
        if (this.dataReservationList.length > 0){
          let indexZ = this.dataReservationList[index].data[0].files.length - 1;
          this.dataReservationList[index].data[0].files[indexZ].data['locationStorage'] = event.reference;
        } else {
          let indexZ = this.dataTktList[index].data[0].files.length - 1;
          this.dataTktList[index].data[0].files[indexZ].data['locationStorage'] = event.reference;
        }
        this.loadingUpload = false;
      },
      error: (error: HttpErrorResponse) => {
        this.removeFileFromArray(file);
        console.log('error', error);
        this.loadingUpload = false;
      }})
  }

  private removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);

    if (index > -1) {
      this.files.splice(index, 1);
    }
  }

  private removeFileFromArrayPost(file: FileUploadModel, indexReservation: number) {
    if (this.dataReservationList.length > 0){
      const index = this.dataReservationList[indexReservation].data[0].files.indexOf(file);

      if (index > -1) {
        this.dataReservationList[indexReservation].data[0].files.splice(index, 1);
      }
    } else {
      const index = this.dataTktList[indexReservation].data[0].files.indexOf(file);

      if (index > -1) {
        this.dataTktList[indexReservation].data[0].files.splice(index, 1);
      }
    }
  }

  public validateFile() {
    if (this.selectedMotif) {
      if (this.selectedMotif.attachmentFile === true && this.selectedMotif.code !== 'CRN') {
        if (this.files.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }

  public validateFilePost(files) {
    if (this.selectedMotif) {
      if (this.selectedMotif.attachmentFile === true && this.selectedMotif.code !== 'CRN') {
        if (this.pnrList?.length === 1 && this.ticketList?.length === 1){
          return this.validateFilePostOneFile(files);
        }
        if (this.files.length === 0){
          return false;
        }
        return true;
      } else {
        return true;
      }
    }
  }

  public validateFilePostOneFile(files) {
        if (this.dataReservationList.length > 0){
          for (const element of this.dataReservationList){
            if (element.data[0].files.length === 0){
              return false;
            }
          }
        } else {
          for (const element of this.dataTktList){
            if (element.data[0].files.length === 0){
              return false;
            }
          }
        }
        return true;
  }

  public validateValorization() {
    let valor = (Number(this.waiver.valorization));

    if (valor <= 0 && this.selectedMotif.saveWis && this.marketRequest.waiverEnableAutoapprove && !this.isForcedManually) {
      return false;
    } else {
      return true;
    }
  }

  private showQuestionMessage(pnrNumber: string, iata: string, osiInfo: OsiInfo) {
    swal.fire({
      title: this.translateService.instant('acceptUsePnr'),
      icon: 'question',
      html: `<div class='popup-pnr'>${
        this.translateService.instant(
          'confirmUseIata',
          {
            osiInfo: osiInfo.agencyOsi,
            waiverType: this.selectedType.name,
            pnrNumber: pnrNumber,
            iata: iata
          }
        )
      }</div>`,
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('button.yes'),
      cancelButtonText: this.translateService.instant('button.no'),
    }).then((result) => {
      if (result.value) {
        this.validPostPNR = true;
      } else {
        this.validPostPNR = false;
      }
    });
  }

  addFieldPNRTKT(){
    if(this.selectedTypeSearch == 1 && this.pnrList.length < 4){
      this.pnrList.push({
            pnrNumber: ''
      });
      this.tabNavList.push({});
    }
    if(this.selectedTypeSearch == 2 && this.ticketList.length< 4){
      this.ticketList.push({
        ticket: ''
      });
      this.tabNavList.push({});
      this.resumenNavList.push({
        ticket: this.ticket,
      })
    }
    this.waiverCount++;
  }


  removeFieldPNRTKT(fieldRemove: number){
    if(this.selectedTypeSearch == 1 && this.pnrList.length > 1){
    this.pnrList.splice(fieldRemove, 1);
    this.tabNavList.splice(fieldRemove,1);
    this.resumenNavList.splice(fieldRemove,1);
    }
    if(this.selectedTypeSearch == 2 && this.ticketList.length > 1){
    this.ticketList.splice(fieldRemove, 1);
    this.resumenNavList.splice(fieldRemove,1);
    }
  }


}
