import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';



export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-modal-farebasis',
  templateUrl: './modal-farebasis.component.html',
  styleUrls: ['./modal-farebasis.component.css']
})
export class ModalFarebasisComponent {
  test: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }



}
