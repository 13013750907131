import { DecimalPipe } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild, LOCALE_ID, SimpleChange } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';

import { DateFormatPipe } from './../../pipes/date-format.pipe';

import { BusinessException, Exception, SystemException } from '../../models/Exception.model';
import { Profile } from '../../models/profile.model';

import { WaiverListDetailComponent } from '../../modules/waiver-presale-module/waiver-list-detail/waiver-list-detail.component';

import { MessageService } from './../../services/message.service';
import { ProfilingService } from '../../services/profiling.service';
import { WaiverPresaleService } from '../../services/waiver-presale.service';
import { Filter } from '../../interfaces/filter.interface';

@Component({
  selector: 'app-waiver-list-presale',
  templateUrl: './waiver-list-presale.component.html',
  styleUrls: ['./waiver-list-presale.component.css']
})
export class WaiverListPresaleComponent implements OnInit, OnChanges {
  loading = false;
  noWaivers = false;
  dataSource = new MatTableDataSource<any>();
  selectedRowIndex = -1;
  profiles = new Profile();
  pageSize: number = 10;
  pageNumber: number = 0;
  totalElements: number = 0;
  params: HttpParams = new HttpParams()
  .set('id', 'n')
  .set('pnr', '')
  .set('valueWaiver', '')
  .set('statusDateFrom', 'n')
  .set('statusDateTo', 'n')
  .set('executive', '')
  .set('agency', '')
  .set('applicant', '')
  .set('type', '')

  filter: Filter = {
    id: true,
    agency: true,
    applicant: true,
    statusDateFrom: false,
    statusDateTo: false,
    flightDateFrom: false,
    flightDateTo: false,
    executive: true,
    pnr: true,
    type: true,
    valueWaiver: true
  }

  locale: string;

  @Input() displayedColumns: string[];
  @Input() tab: string;
  @Input() searchApproved: boolean = false;
  @Input() searchIssued: boolean = false;
  @Input() searchExpired: boolean = false;
  @Input() searchRejected: boolean = false;
  @Input() searchPending: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  noWaiversFound = (index: number, item: { noData: boolean }) => item.noData;
  errorLoadingWaivers = (index: number, item: { errorLoadingData: boolean }) => item.errorLoadingData;

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private dialog: MatDialog,
    private messageService: MessageService,
    private profilesService: ProfilingService,
    private translateService: TranslateService,
    private waiverPresaleService: WaiverPresaleService) {
      this.locale = locale;
  }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    if (this.tab === 'approved' && this.searchApproved) {
      this.getWaiversPresale(4, 'approved', this.pageSize, this.pageNumber, this.params);
    } else if (this.tab === 'issued' && this.searchIssued) {
      this.getWaiversPresale(5, 'issued', this.pageSize, this.pageNumber,  this.params);
    } else if (this.tab === 'expired' && this.searchExpired) {
      this.getWaiversPresale(6, 'expired', this.pageSize, this.pageNumber,  this.params);
    } else if (this.tab === 'rejected' && this.searchRejected) {
      this.getWaiversPresale(7, 'rejected', this.pageSize, this.pageNumber,  this.params);
    } else if (this.tab === 'pending' && this.searchPending){
      this.getWaiversPresale(9, 'pending', this.pageSize, this.pageNumber, this.params);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.pageSize === 0) {
      this.pageSize = 10;
    }

    if (!this.isFirstChange(changes.searchApproved) && changes?.searchApproved?.currentValue) {
      this.getWaiversPresale(4, 'approved', this.pageSize, this.pageNumber, this.params);
    } else if (!this.isFirstChange(changes.searchIssued) && changes?.searchIssued?.currentValue) {
      this.getWaiversPresale(5, 'issued', this.pageSize, this.pageNumber, this.params);
    } else if (!this.isFirstChange(changes.searchExpired) && changes?.searchExpired?.currentValue) {
      this.getWaiversPresale(6, 'expired', this.pageSize, this.pageNumber, this.params);
    } else if (!this.isFirstChange(changes.searchRejected) && changes?.searchRejected?.currentValue) {
      this.getWaiversPresale(7, 'rejected', this.pageSize, this.pageNumber, this.params);
    } else if (!this.isFirstChange(changes.searchPending) && changes?.searchPending?.currentValue) {
      this.getWaiversPresale(9, 'pending', this.pageSize, this.pageNumber, this.params);
    }
  }

  private isFirstChange(change: SimpleChange): boolean {
    return change?.firstChange;
  }

  updateList(pageEvent: PageEvent){
    console.log(this.tab);
    console.log(this.searchIssued);
    if (this.tab === 'approved') {
      this.getWaiversPresale(4, 'approved', pageEvent.pageSize, pageEvent.pageIndex, this.params);
    } else if (this.tab === 'issued') {
      this.getWaiversPresale(5, 'issued', pageEvent.pageSize, pageEvent.pageIndex,  this.params);
    } else if (this.tab === 'expired') {
      this.getWaiversPresale(6, 'expired', pageEvent.pageSize, pageEvent.pageIndex,  this.params);
    } else if (this.tab === 'rejected') {
      this.getWaiversPresale(7, 'rejected', pageEvent.pageSize, pageEvent.pageIndex,  this.params);
    } else if (this.tab === 'pending') {
      this.getWaiversPresale(9, 'pending', pageEvent.pageSize, pageEvent.pageIndex,  this.params);
    }
  }

  getWaiversPresale(statusId: number, statusName: string, pageSizeRQ: number, pageNumberRQ: number, params: HttpParams): void {
    this.loading = true;
    console.log('hey');
    this.waiverPresaleService.getWaiversPresale(statusId, statusName, pageSizeRQ, pageNumberRQ, params).subscribe({
      next: data => {
        if (data.content.length === 0) {
          this.noWaivers = true;
          this.pageSize = 0
          this.pageNumber = 0;
          this.totalElements = 0;
          this.dataSource.data = [{ noData: true }];
          this.dataSource.paginator = null;
          this.dataSource.sort = this.sort;
        } else {
          this.noWaivers = false;
          this.pageSize = data.size;
          this.pageNumber = data.number;
          this.totalElements = data.totalElements;
          this.dataSource.data = data.content;
          this.dataSource.sort = this.sort;
        }
        console.log(this.dataSource.data);
        this.initWaiversPagination();
        this.loading = false;
        console.log(this.loading);
      },
      error: (error: HttpErrorResponse) => {
        this.noWaivers = true;
        this.pageSize = 0;
        this.pageNumber = 0;
        this.totalElements = 0;
        this.dataSource.data = [{ errorLoadingData: true }];
        this.dataSource.paginator = null;
        this.dataSource.sort = this.sort;
        let messageError = '';

        if (statusName === 'approved') {
          messageError = this.translateService.instant('error.loadingAprovedWaivers');
        } else if (statusName === 'issued') {
          messageError = this.translateService.instant('error.loadingIssuedWaivers');
        } else if (statusName === 'expired') {
          messageError = this.translateService.instant('error.loadingExpiredWaivers');
        } else if (statusName === 'rejected') {
          messageError = this.translateService.instant('error.loadingDeniedWaivers');
        } else if (statusName === 'pending') {
          messageError = this.translateService.instant('error.loadingPendingWaivers');
        }

        if (error.error !== null) {
          this.messageService.showErrorMessage(
            new Exception(
              error.error.type,
              error.error.type !== BusinessException ? messageError : error.error.description,
              error.status
            ),
            this.translateService.instant('button.confirm')
          );
        } else {
          this.messageService.showErrorMessage(
            new Exception(SystemException, messageError, error.status),
            this.translateService.instant('button.confirm'));
        }

        this.loading = false;
        console.log('error', error);
      }});
  }

  initWaiversPagination(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data, header) => {
      return (!isNaN(data[header]) || data[header] == null) ? data[header] : data[header].toLocaleLowerCase();
    };

    this.dataSource.filterPredicate = this.customFilterPredicate(this.locale, this.tab);
  }

  private customFilterPredicate(locale: string, currentTab: string) {
    const filterPredicate = (data: any, filter: string) => {
      const idFound = this.isIdFound(data, filter);
      const typeFound = this.isTypeFound(data, filter);
      const documentNumberFound = this.isDocumentNumberFound(data, filter);
      const agencyFound = this.isAgencyFound(data, filter);
      const salesExecutiveFound = this.isSalesExecutiveFound(data, filter);
      const agentFound = this.isAgentFound(data, filter);
      const dateFound = this.isDateFound(data, filter, currentTab, locale);
      const documentCloserDepartureDateFound = this.isDocumentCloserDepartureDateFound(data, filter, locale);
      const valorizationFound = this.isValorizationFound(data, filter, locale);

      return idFound || typeFound || documentNumberFound || agencyFound || salesExecutiveFound || agentFound || dateFound ||
        documentCloserDepartureDateFound || valorizationFound;
    };

    return filterPredicate;
  }

  isValorizationFound(data: any, filter: string, locale: string) {
    const valorizationFormat = data.valorization !== null ?
        new DecimalPipe(locale).transform(data.valorization, '1.2-2', 'en-US') : null;

      return (valorizationFormat === null && filter === '') ||
        (valorizationFormat !== null && valorizationFormat.toString().trim().toLowerCase().indexOf(filter) !== -1);
  }

  isDocumentCloserDepartureDateFound(data: any, filter: string, locale: string) {
    const documentCloserDepartureDateFormat = data.documentCloserDepartureDate !== null ?
        new DateFormatPipe(locale).transform(data.documentCloserDepartureDate, 'DD-MMM-YY') : null;

      return (documentCloserDepartureDateFormat === null && filter === '') ||
        (documentCloserDepartureDateFormat !== null &&
          documentCloserDepartureDateFormat.toString().trim().toLowerCase().indexOf(filter) !== -1);
  }

  isDateFound(data: any, filter: string, currentTab: string, locale: string) {
    let dateFound = false;

      if (currentTab === 'approved') {
        const creationDateFormat = data.creationDate !== null ?
          new DateFormatPipe(locale).transform(data.creationDate, 'DD-MMM-YY HH:mm') : null;

        dateFound = (creationDateFormat === null && filter === '') ||
          (creationDateFormat !== null && creationDateFormat.toString().trim().toLowerCase().indexOf(filter) !== -1);
      } else {
        const statusDateFormat = data.statusDate !== null ?
          new DateFormatPipe(locale).transform(data.statusDate, 'DD-MMM-YY HH:mm') : null;

        dateFound = (statusDateFormat === null && filter === '') ||
          (statusDateFormat !== null && statusDateFormat.toString().trim().toLowerCase().indexOf(filter) !== -1);
      }
      return dateFound;
  }
  isAgentFound(data: any, filter: string) {
    return (data.agent === null && filter === '') ||
    (data.agent !== null && data.agent.toString().trim().toLowerCase().indexOf(filter) !== -1);
  }
  isSalesExecutiveFound(data: any, filter: string) {
    return (data.salesExecutive === null && filter === '') ||
    (data.salesExecutive !== null && data.salesExecutive.toString().trim().toLowerCase().indexOf(filter) !== -1);
  }

  isAgencyFound(data: any, filter: string) {
    return (data.branchOffice === null && filter === '') ||
    (data.matrix === null && filter === '') ||
    ((data.branchOffice !== null || data.matrix !== null) &&
      (data.branchOffice ? data.branchOffice.toString().trim().toLowerCase().indexOf(filter) :
        data.matrix.toString().trim().toLowerCase().indexOf(filter)) !== -1);
  }

  isDocumentNumberFound(data: any, filter: string) {
    return (data.documentNumber === null && filter === '') ||
    (data.documentNumber !== null &&
      data.documentNumber.toString().trim().toLowerCase().indexOf(filter) !== -1);
  }

  private isIdFound(data: any, filter: string): boolean {
    return (data.id === null && filter === '') ||
    (data.id !== null && data.id.toString().trim().toLowerCase().indexOf(filter) !== -1);
  }

  private isTypeFound(data: any, filter: string){
    return (data.type === null && filter === '') ||
    (data.type !== null && data.type.toString().trim().toLowerCase().indexOf(filter) !== -1);
  }

  highlightRow(event: Event, rowIndex: number) {
    this.selectedRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  public applyFilterWaiver(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog(tab: string, idWaiver: number): void {
    let dialogRef = this.dialog.open(WaiverListDetailComponent, { width: '90vw', height: '65vh', disableClose: true });

    dialogRef.componentInstance.tab = tab;
    dialogRef.componentInstance.idWaiver = idWaiver;
  }

 sendFilterData(paramsFilter: HttpParams){
    this.params = paramsFilter;
    if (this.tab === 'approved' && this.searchApproved) {
      this.getWaiversPresale(4, 'approved', 10, 0, paramsFilter);
    } else if (this.tab === 'issued' && this.searchIssued) {
      this.getWaiversPresale(5, 'issued', 10, 0,  paramsFilter);
    } else if (this.tab === 'expired' && this.searchExpired) {
      this.getWaiversPresale(6, 'expired', 10, 0,  paramsFilter);
    } else if (this.tab === 'rejected' && this.searchRejected) {
      this.getWaiversPresale(7, 'rejected', 10, 0,  paramsFilter);
    }  else if (this.tab === 'pending' && this.searchPending) {
      this.getWaiversPresale(9, 'pending', 10, 0,  paramsFilter);
    }
  }

  cleanFilters(){
      const paramsClean = new HttpParams()
      .set('id', 'n')
      .set('pnr', '')
      .set('valueWaiver', '')
      .set('statusDateFrom', 'n')
      .set('statusDateTo', 'n')
      .set('executive', '')
      .set('agency', '')
      .set('applicant', '')
      .set('type', '');

      if (this.tab === 'approved' && this.searchApproved) {
        this.getWaiversPresale(4, 'approved', 10, 0, paramsClean);
      } else if (this.tab === 'issued' && this.searchIssued) {
        this.getWaiversPresale(5, 'issued', 10, 0,  paramsClean);
      } else if (this.tab === 'expired' && this.searchExpired) {
        this.getWaiversPresale(6, 'expired', 10, 0,  paramsClean);
      } else if (this.tab === 'rejected' && this.searchRejected) {
        this.getWaiversPresale(7, 'rejected', 10, 0,  paramsClean);
      } else if (this.tab === 'pending' && this.searchPending) {
        this.getWaiversPresale(9, 'pending', 10, 0,  paramsClean);
      }
  }
}
