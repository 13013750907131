import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouteReuseStrategy } from '@angular/router';
import { CsvModule } from '@ctrl/ngx-csv';

import { ShareModule } from '../share-module/share.module';
import { UserModule } from '../user-module/user.module';

import { AgencyGroupBudgetComponent } from './budget/agency-group-budget/agency-group-budget.component';
import { BranchOfficeBudgetComponent } from './budget/branch-office-budget/branch-office-budget.component';
import { BranchOfficeMatrixComponent } from '../agency-group-module/branch-office-matrix/branch-office-matrix.component';
import { BulkLoadLatamComponent} from './bulk-load/bulk-load-latam.component';
import { CreateLatamUserComponent } from './latam-user/create-latam-user/create-latam-user.component';
import { EditLatamUserComponent } from './latam-user/edit-latam-user/edit-latam-user.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { GlobalBudgetBranchOfficeComponent } from './budget/global-budget-branch-office/global-budget-branch-office.component';
import { GlobalBudgetManagementComponent } from './budget/global-budget-management/global-budget-management.component';
import { BudgetBulkLoadLatamComponent} from './budget/budget-bulk-load/budget-bulk-load-latam.component';
import { DialogBulkLoadBudgetComponent} from './budget/budget-bulk-load/dialog-budget/dialog-bulk-load-budget.component';  
import { LatamComponentsReuseStrategy } from './latam-components-reuse-strategy';
import { ListLatamUsersComponent } from './latam-user/list-latam-users/list-latam-users.component';
import { MonthlyBudgetComponent } from './budget/monthly-budget/monthly-budget.component';
import { ProfileAgencyGroupComponent } from '../agency-group-module/profile-agency-group/profile-agency-group.component';
import { UserProfileLatamComponent } from './latam-user/user-profile-latam/user-profile-latam.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { WaiverAutomationComponent } from './waiver-automation/waiver-automation.component';
import { WaiverFarediffManagementComponent } from './waiver-farediff-management/waiver-farediff-management.component';
import { WaiverTypeManagementComponent } from './waiver-type-management/waiver-type-management.component';
import { DownloadSalesReportComponent } from './bda-portal/download-sales-report/download-sales-report.component';
import { WaiverManualManagementComponent } from './waiver-manual/waiver-manual-management/waiver-manual-management.component';
import { ModalWaiverManualComponent } from './waiver-manual/modal-waiver-manual/modal-waiver-manual.component';

import { AgencyBranchOfficeService } from './../../services/agency-branch-office.service';
import { AgencyGroupService } from '../../services/agency-group.service';
import { BudgetService } from '../../services/budget.service';
import { BulkLoadService } from '../../services/bulkload.service';
import { FeedbackService } from '../../services/feedback.service';
import { LatamService } from '../../services/latam.service';
import { MarketService } from './../../services/market.service';
import { WaiverPostsaleService } from './../../services/waiver-postsale.service';
import { WaiverPresaleService } from './../../services/waiver-presale.service';
import { LatamOutsourcingService } from './../../services/latam-outsourcing.service';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    AgencyGroupBudgetComponent,
    BranchOfficeBudgetComponent,
    BranchOfficeMatrixComponent,
    BulkLoadLatamComponent,
    CreateLatamUserComponent,
    EditLatamUserComponent,
    FeedbackComponent,
    GlobalBudgetBranchOfficeComponent,
    GlobalBudgetManagementComponent,
    BudgetBulkLoadLatamComponent,
    DialogBulkLoadBudgetComponent,
    ListLatamUsersComponent,
    MonthlyBudgetComponent,
    ProfileAgencyGroupComponent,
    UserProfileLatamComponent,
    UserProfileComponent,
    WaiverAutomationComponent,
    WaiverFarediffManagementComponent,
    WaiverTypeManagementComponent,
    DownloadSalesReportComponent,
    WaiverManualManagementComponent,
    ModalWaiverManualComponent
  ],
  imports: [
    CommonModule,
    CsvModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    ReactiveFormsModule,
    ShareModule,
    UserModule,
    TranslateModule.forChild()  
  ],
  providers: [
    AgencyBranchOfficeService,
    AgencyGroupService,
    BudgetService,
    BulkLoadService,
    FeedbackService,
    LatamService,
    MarketService,
    WaiverPostsaleService,
    WaiverPresaleService,
    LatamOutsourcingService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter
    },
    {
      provide: RouteReuseStrategy,
      useClass: LatamComponentsReuseStrategy
    }
  ]
})
export class LatamModule { }
