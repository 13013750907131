import { Component, OnInit, ViewChild } from '@angular/core';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';
import { MatStepper } from '@angular/material/stepper';
import { BudgetService } from '../../../services/budget.service';
import { WaiverPostsaleService } from '../../../services/waiver-postsale.service';
import { WaiverReservationDetailTableInterface } from '../../../interfaces/waiver-reservation-detail-table.interface';
import { SearchReservationComponent } from '../search-reservation/search-reservation.component';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionInfoService } from '../../../services/session-info.service';
import { WaiverType } from '../../../models/waiver-type.model';
import Swal from 'sweetalert2';
import { WaiverMotive } from '../../../models/waiver-motive.model';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';


import { Waiver } from '../../../models/waiver.model';
import { MessageErrorService } from '../../../services/message-error.service';
import { TranslateService } from '@ngx-translate/core';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';

@Component({
  selector: 'app-create-policy-waiver',
  templateUrl: './create-policy-waiver.component.html',
  styleUrls: ['./create-policy-waiver.component.css'],
})
export class CreatePolicyWaiverComponent implements OnInit {


  @ViewChild('stepper') private readonly waiverRequestStepper: MatStepper;
  @ViewChild(SearchReservationComponent) searchReservation!: SearchReservationComponent;
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_WAIVERS, SidebarMenuModel.SUBMENU_WAIVER_POLICY_REQUEST);
  stepped: boolean = false;
  reservationInfo: WaiverReservationDetailTableInterface;
  reservationInfoObtained: boolean = false;
  validatingPassengers: boolean = false;
  dataWaiverResult = new MatTableDataSource();

  selectedPassengers: SelectionModel<Element>;
  passengersSelected: boolean = false;
  segmentsSelected: boolean = false;
  acceptedDocument: boolean = false;
  documentationNeeded: boolean = false;
  waiverTypes: WaiverType[] = [];
  waiverMotives: WaiverMotive[] = [];


  constructor(private readonly waiverPostsaleService: WaiverPostsaleService,
    private readonly sessionInfoService: SessionInfoService,
    private readonly sidebarShareDataService: SidebarShareDataService,
    private readonly translateService: TranslateService,
    private readonly messageErrorService: MessageErrorService,
    private readonly router: Router
  ){ }

  ngOnInit(): void {
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.getTypes();
    this.getMotives();
  }


  handleReservationInfo(data: WaiverReservationDetailTableInterface){
    this.reservationInfo = data;
    this.reservationInfoObtained = true;
    this.waiverRequestStepper.next();
  }

  handlePassengersValidation(passengers: SelectionModel<Element>){
    this.selectedPassengers = passengers;
    this.passengersSelected = true;
    this.segmentsSelected = true;
    this.validatingPassengers = true;
    const request = {passengers: this.selectedPassengers.selected.map(p=> ({
      foid: p['foid'],
      documentCountry: p['documentCountry'],
      documentNumber: p['documentNumber'],
      email: p['email'],
      firstName: p['firstName'],
      lastName: p['lastName'],
      paxNumber: p['paxNumber'],
      ticketNumber: p['ticketNumber'],
      type: p['type']
    }))};

    this.waiverPostsaleService.getDocumentationNeeded(request).subscribe({
      next: data => {
        if (data['documentationNeeded']){
          const style = document.createElement('style');
          style.textContent = `
            .swal2-popup .swal2-actions {
              justify-content: space-between !important;
              padding-left: 5rem !important;
              padding-right: 5rem !important;
              margin-bottom: 0px !important;
              margin-right: 0px !important;
              margin-top: 30px !important;
            }
          `;
          document.head.appendChild(style);

          const title = this.translateService.instant('attention');
          const text = this.translateService.instant('waiverPolicy.documentationNeeded');

          Swal.fire({
            title: title,
            text: text,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: this.translateService.instant('button.cancel'),
            cancelButtonColor: "#d33",
            confirmButtonText: this.translateService.instant('button.confirm'),
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.documentationNeeded = true;
              this.validatingPassengers = false;
              this.acceptedDocument = true;
              this.waiverRequestStepper.next();
            } else {
              this.validatingPassengers = false;
              this.acceptedDocument = false;
            }
          });
        } else {
          this.validatingPassengers = false;
          this.documentationNeeded = false;
          this.acceptedDocument = true;
          this.waiverRequestStepper.next();
        }

      },
      error: error => {
        if (error?.error?.type === "PassengerNotRecognizedException"){
          this.messageErrorService.sendError(error, error.error.description);
          this.validatingPassengers = false;
          this.acceptedDocument = false;
        }
      }
    });;

  }

  stepperBack(step: string){

    if (step === 'reservation-details'){
      this.reservationInfoObtained = false;
      this.validatingPassengers = false;
      this.searchReservation.cleanData();
      this.searchReservation.cleanRecordLocator();
      this.searchReservation.checkIfReservationInfoisReady();
    }

    this.waiverRequestStepper.previous();
  }

  getTypes() {
    const marketId: any = this.sessionInfoService.getSessionInfo().marketId;
    this.waiverPostsaleService.getTypesByMarket(marketId).subscribe({
      next: data => {
        this.waiverTypes = data.filter(type => type.name.includes('Waiver:'));
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }
    });
  }

  getMotives() {
    this.waiverPostsaleService.getMotives(20).subscribe({
      next: data => {
        this.waiverMotives = data.filter(type => type.code === '383');
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }
    });
  }

  newRequest(){
    this.router.navigate([this.router.url]).then(() => {
      this.waiverRequestStepper.reset();
      this.reservationInfoObtained = false;
      this.validatingPassengers = false;
      this.searchReservation.cleanData();
      this.searchReservation.cleanRecordLocator();
      this.searchReservation.checkIfReservationInfoisReady();
    });
  }

  handleRequest(event: MatTableDataSource<Waiver, MatPaginator>){
    this.dataWaiverResult = event;
    this.waiverRequestStepper.next();
  }
}
