import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { BusinessException, Exception, SystemException } from './../../../models/Exception.model';
import { LatamRole } from '../../../models/latam-role.model';
import { SessionInfo } from '../../../models/SessionInfo.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';
import { UserLatam } from '../../../models/user-latam.model';
import { UserLatamConfigurations } from '../../../models/user-latam-configurations.model';

import { LatamService } from '../../../services/latam.service';
import { MessageService } from './../../../services/message.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { MessageErrorService } from '../../../services/message-error.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  loading: boolean = true;
  updating: boolean = false;

  selectedPosRowIndex: number = -1;

  selectedPos: String = 'POS';

  infoUser$: Promise<SessionInfo> = this.sessionInfoService.getSessionInfoAsPromise();
  latamRoles: LatamRole[];
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_LATAM, SidebarMenuModel.SUBMENU_USER_PROFILE);
  userLatam: UserLatam = new UserLatam();
  userLatamConfigurations: UserLatamConfigurations;

  constructor(
    private latamService: LatamService,
    private messageService: MessageService,
    private sessionInfoService: SessionInfoService,
    private messageErrorService: MessageErrorService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.loadConfigurations();
  }

  private loadConfigurations() {
    this.latamService.getUserLatamConfigurations().subscribe({
      next: data => {
        this.userLatamConfigurations = data;
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.request');

        this.loading = false;
        console.log('error', error);
      }
  });
  }

  updateNotifications() {
    this.updating = true;
    this.spinner.show();
    console.log(this.userLatamConfigurations.notifications);

    this.latamService.updateUserLatamNotifications(this.userLatamConfigurations).subscribe({
      next: data => {
        this.updating = false;
        this.spinner.hide();
      },
      error: (error: HttpErrorResponse) => {
        this.userLatamConfigurations.notifications = !this.userLatamConfigurations.notifications;
        this.messageErrorService.sendError(error, 'error.request');
        this.updating = false;
        this.spinner.hide();
        console.log('error', error);
      }
  });
  }

  enableAgenciesNotificationByMarket(market: string) {
    this.updateAgenciesNotificationsByMarket(market, true);
  }

  disableAgenciesNotificationsByMarket(market: string) {
    this.updateAgenciesNotificationsByMarket(market, false);
  }

  updateAgenciesNotificationsByMarket(market: string, status: boolean) {
    this.updating = true;

    this.sessionInfoService.getSessionInfoAsPromise()
      .then(
        session => {
          if (session.market.includes(market)) {
            this.latamService.updateUserLatamNotificationsByMarket(market, status).subscribe({
              next: data => {
                this.updating = false;

                this.messageService.showSuccessMessage(
                  this.translateService.instant('button.confirm'),
                  this.translateService.instant('popup.wellDone'),
                  this.translateService.instant('success.msg')
                );
              },
              error: (error: HttpErrorResponse) => {
                this.messageErrorService.sendError(error, 'error.request');

                this.updating = false;
                console.log('error', error);
              }
          });
          }
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
  }
}
