import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { FileStorageService } from '../../../../services/file-storage.service';
import { TranslateService } from '@ngx-translate/core';

import { FileUploadModel } from '../../../../models/file-upload.model';
import { WaiverCategory } from '../../../../models/waiver-category.model';
import swal from 'sweetalert2';
import { MessageService } from '../../../../services/message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { WaiverUserManualFile } from '../../../../models/waiver-user-manual-file-model';
import { MessageErrorService } from '../../../../services/message-error.service';
import { ManualUtilService } from '../../../../services/manual-util.service';


@Component({
  selector: 'app-modal-waiver-manual',
  templateUrl: './modal-waiver-manual.component.html',
  styleUrls: ['./modal-waiver-manual.component.css']
})


export class ModalWaiverManualComponent implements OnInit {
  file: File;
  encodedFile: string;
  languages: WaiverCategory[];
  categories: WaiverCategory[];
  types: WaiverCategory[];
  waiverUserManualFile: WaiverUserManualFile = new WaiverUserManualFile();
  dataNotfound = (index, item) => item.noData;
  selectedRowIndex: number = -1;
  downloading = false;
  downloadingSuccessfully = false;

  fileList: WaiverUserManualFile[];

  noFilesFound = false;
  textFile: File;
  loading: boolean = true;
  validCsvFormat: boolean = false;
  result3: string[];
  typeFileId: number;
  format: string = "";
  errores: string = "";

  /** Allow you to add handler after its completion. Bubble up response text from remote. */
  @Output() complete = new EventEmitter<string>();
  public files: Array<FileUploadModel> = [];

  allowedFileFormats = ['application/pdf', 'text/pdf', 'application/vnd.ms-excel'];
  loadSalesSuccessfully: boolean = false;
  loadSalesProcess: boolean = false;
  operationfinishOK: boolean = false;

  languageNames = {
    es: {
      es: "Español",
      en: "Inglés",
      pt: "Portugués"
    },
    en: {
      es: "Spanish",
      en: "English",
      pt: "Portuguese"
    },
    pt: {
      es: "Espanhol",
      en: "Inglês",
      pt: "Português"
    }
  };

  @Output() success = new EventEmitter();

  constructor(
    private dialogRef: MatDialogRef<ModalWaiverManualComponent>,
    private fileStorageService: FileStorageService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private manualUtilService: ManualUtilService
  ) { }

  ngOnInit() {
    const language = localStorage.getItem('language');
    this.fillLanguageByLanguage(language);
    this.fillCategoriesByLanguage(language);

    this.types = [
      { id: 1, name: "NDC", code: "ndc" },
      { id: 2, name: "FareMatch", code: "farematch"},
    ]
  }

  fillCategoriesByLanguage(language: string){

    if (language === 'es') {
      this.categories = [
        { id: 1, name: "Pre-Venta", code: "PRE" },
        { id: 2, name: "Post-Venta", code: "POST" },
      ];
    } else if (language === 'en') {
      this.categories = [
        { id: 1, name: "Pre-Sale", code: "PRE" },
        { id: 2, name: "Post-Sale", code: "POST" },
      ];
    }else if (language === 'pt') {
    this.categories = [
      { id: 1, name: "Pré-venda", code: "PRE" },
      { id: 2, name: "Pós-venda", code: "POST" },
    ];
    }
  }

  fillLanguageByLanguage(language: string){
    const names = this.languageNames[language] || this.languageNames['en'];
    this.languages = [
      { id: 1, name: names.es, code: "es" },
      { id: 2, name: names.en, code: "en" },
      { id: 3, name: names.pt, code: "pt" }
    ];
  }

/**
   * Metodo para subir el archivo
   */
validateFormat() {
  const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
  this.manualUtilService.validateFormat(fileUpload, this.files);
  this.refreshComponent();
}

  /**
 * @param file
 */
  cancelFile(file: any) {
    this.manualUtilService.cancelFile(file, this.files);
    this.validCsvFormat = false;
  }

  /**
   * Metodo que inicia el proceso de carga masiva
   */
  public initBulkLoad() {

    const file = this.files[0].data;
    swal.fire({
      title: this.translateService.instant('bda.wait'),
      html: this.translateService.instant('bda.process'),// add html attribute if you want or remove
      allowOutsideClick: false,
      allowEscapeKey: false,
      heightAuto: false,
      onBeforeOpen: () => {
        swal.showLoading()
      },

    });

    this.fileStorageService.uploadWaiverUserManual(this.waiverUserManualFile.language, this.waiverUserManualFile.waiverCategory,
      this.waiverUserManualFile.waiverType, file).subscribe({

      next: data => {

        swal.fire({
          html: '<p>' + this.translateService.instant('success.loadSuccess') + '</p>',
          confirmButtonText: this.translateService.instant('button.confirm'),
          confirmButtonColor: '#d33',
          icon: 'success',
          allowOutsideClick: false
        }).then((result) => {
          if (result.value) {
            //Success
            this.dialogRef.close();
            this.refreshComponent();


          }
        });
      },
      error: (error: HttpErrorResponse) => {
        if (this.messageErrorService.sendErrorSecondVersion(error)){
          this.refreshComponent();
        }
        console.log('error', error);
      }

  });

    this.cancelFile(this.files[0]);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

/**
  * Metodo que sirve para refrescar la pagina
  */
refreshComponent() {
  if (this.operationfinishOK) {
    this.loadSalesSuccessfully = false;
    this.loadSalesProcess = false;
    this.operationfinishOK = false;
    this.loading = true;
    this.fileList = [];
    this.files = [];
    this.ngOnInit();
  }
}





}
