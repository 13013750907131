<div class="modal-header">
  <div class="title">
    <h2 class="terminos" id="termsH2">{{ 'terms' | translate }}</h2>
    <h1 class="condiciones" id="conditionsH1">{{ 'conditions' | translate }}</h1>
  </div>
</div>
<div id="modalContent" (scroll)="onScroll()" class="modal-content">
  <div>

    <ngx-extended-pdf-viewer
        [src]="pdfSrc"
        [height]="'90vh'"
        [showToolbar]="false"
        [showSidebarButton]="false"
        [showFindButton]="false"
        [showPagingButtons]="false"
        [showZoomButtons]="false"
        [showPresentationModeButton]="false"
        [showOpenFileButton]="false"
        [showPrintButton]="false"
        [showDownloadButton]="false"
        [showSecondaryToolbarButton]="false"
        [showRotateButton]="false"
        [showHandToolButton]="false"
        [showSpreadButton]="false"
        [showPropertiesButton]="false"
      >
    </ngx-extended-pdf-viewer>
    <div class="latam-welcome mb-5">
      <h1 class="latam-trade mr-5">{{ 'latamTrade' | translate }}</h1>
      <h3 class="bienvenida ml-5">{{ 'welcomeMessage' | translate }}</h3>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="row">
    <div class="col-6">
      <button mat-raised-button id="backButton" (click)="logout()"
        class="reject-button background-gray">{{ 'button.exit' | translate }}</button>
    </div>
    <div class="col-6">
      <button mat-raised-button id="acceptButton"
        [disabled]="(scrollTop + clientHeight) <= scrollHeight*0.98" (click)="agreeTermsConditions()"
        class="accept-button background-red">
        <span class="mat-button-wrapper" id="acceptButtonSpan">{{ 'button.iAgree' | translate }}</span>
        <div class="mat-button-ripple mat-ripple" matripple=""></div>
        <div class="mat-button-focus-overlay"></div>
      </button>
    </div>
  </div>
</div>