import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { Booking } from '../../models/booking.model';
import { Pnr } from '../../models/pnr.model';

import { BookingInfoInterface } from '../../interfaces/booking-info.interface';


@Component({
  selector: 'app-booking-info',
  templateUrl: './booking-info.component.html',
  styleUrls: ['./booking-info.component.css']
})
export class BookingInfoComponent implements OnInit {
  dataSource = new MatTableDataSource();

  @Input() numberPassengers: number;
  @Input() pnr: Pnr;
  @Input() booking: Booking;
  @Input() displayedColumns: string[];



  ngOnInit() {
    if (this.pnr && this.booking) {
      const bookingInfo: BookingInfoInterface = {
        pnrNumber: this.pnr.pnrNumber,
        type: this.pnr.type,
        createDateTime: this.pnr.createDateTime,
        pos: this.booking.pos,
        iata: this.booking.iata,
        ppc: this.booking.ppc,
        officeId: this.booking.officeId,
        numberPassengers: this.numberPassengers,
        accountCode: this.booking.accountCode
      };

      this.dataSource.data.push(bookingInfo);
    }
  }

}
