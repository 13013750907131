import { HttpErrorResponse } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation, LOCALE_ID } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { Exception, SystemException } from '../../../../models/Exception.model';
import { FileUploadModel } from '../../../../models/file-upload.model';
import { WaiverUserManualFile } from '../../../../models/waiver-user-manual-file-model';
import { SidebarMenuModel } from '../../../../models/SidebarMenu.model';
import { MessageService } from '../../../../services/message.service';
import { BudgetService } from '../../../../services/budget.service';
import { SidebarShareDataService } from '../../../../services/sidebar-share-data.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BudgetType } from '../../../../models/budget-type.model';

import { MessageErrorService } from '../../../../services/message-error.service';
import { ManualUtilService } from '../../../../services/manual-util.service';
import { DialogBulkLoadBudgetComponent } from './dialog-budget/dialog-bulk-load-budget.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
@Component({
  selector: 'app-budget-bulk-load-latam',
  templateUrl: './budget-bulk-load-latam.component.html',
  styleUrls: ['./budget-bulk-load-latam.component.css'],  animations: [
    trigger(
      'fadeInOut',
      [
        state('in', style({ opacity: 100 })),
        transition('* => void', [animate(300, style({ opacity: 0 }))])
      ]
    )
  ],
  encapsulation: ViewEncapsulation.None
})

export class BudgetBulkLoadLatamComponent implements OnInit {
  @ViewChild('budgetBulkLoadForm') budgetBulkLoadForm: NgForm;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  encodedFile: string;
  budgetType: BudgetType = new BudgetType(null, null);
  budgetTypes: BudgetType[] = [];
  isBudgetTypeSelected = false;
  bulkLoadSuccessfully: boolean = false;
  budgetBulkLoadProcessing: boolean = false;

  budgetTypeRequired = false;

  dataSource = new MatTableDataSource<any>();
  public displayedColumns: string[] = ['budgetType','executionDate','userCreation','status','errorDesc' ];
  dataNotfound = (index, item) => item.noData;
  selectedRowIndex: number = -1;

  fileList: WaiverUserManualFile[];
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_LATAM, SidebarMenuModel.SUBMENU_LATAM_BUDGET_BULK_LOAD);


  noBulkLoadListFound = false;
  textFile: File;
  bulkLoadListloading: boolean = true;
  validCsvFormat: boolean = false;
  result3: string[];
  typeFileId: number;
  format: string = "";
  errores: string = "";
  language: string;

  /** File extension that accepted, same as 'accept' of <input type='file' />. By the default, it's set to 'image/*'. */
  @Input() accept = '.csv';
  /** Allow you to add handler after its completion. Bubble up response text from remote. */
  @Output() complete = new EventEmitter<string>();
  public filesAttached: Array<FileUploadModel> = [];

  allowedFileFormats = ['application/csv', 'text/csv', 'application/vnd.ms-excel'];

  // fin adjuntar archivos
  constructor(
    @Inject(LOCALE_ID) locale: string,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private manualUtilService: ManualUtilService,
    private budgetService: BudgetService,
    public dialog: MatDialog
  ) {
    this.language = locale;
   }

  ngOnInit() {
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.getBudgetTypes(this.language);
    this.getBudgetBulkLoadList(0,10);
  }

  getBudgetTypes(language: string): void {
    this.budgetTypes = this.budgetService.getBudgetTypes();

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.budgetTypeId && this.budgetTypes.length > 0) {
        this.budgetType = this.budgetTypes.find(f => f.id === +params.budgetTypeId);
        this.isBudgetTypeSelected = true;
        this.budgetBulkLoadProcessing = false;
      }

    },
    (error: HttpErrorResponse) => {
      console.log('Error loading budgetTypes', error);
    }
  );
  }

/**
   * Metodo para subir el archivo
   */
validateFormat() {
  const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
  const reader = new FileReader();

  reader.onload = () => {
    let myRegex = /^([a-zA-Z0-9 ._-]+)$/;
    const fileName = this.filesAttached[0].data.name;
    let nameCaract = myRegex.test(fileName);
    let nameSize = fileName.length;
    let error = false;
    this.errores = "";

    const binaryString = reader.result;
    reader.readAsBinaryString
    this.encodedFile = btoa(binaryString.toString());
    let result1 = reader.result as string;
    let result2 = result1.split("\r\n");

    this.result3 = result2[0].split(";");

    if (this.result3[this.result3.length - 1] == "") {
      this.result3.pop();
    }

    error = this.validateFile(nameSize, error, nameCaract, fileName);


    if(error){
      this.manualUtilService.showError(this.errores);
      this.refreshComponent();
    }
  }


  fileUpload.onchange = () => {

    let index = fileUpload.files.length - 1;
    const file = fileUpload.files[index];


    if (!this.allowedFileFormats.includes(file.type) || file.size > 200097152 ) {
      this.messageService.showWarningMessage(
        this.translateService.instant('button.confirm'),
        this.translateService.instant('warning.invalidFileFormat')
      );
    } else {
      this.filesAttached.push({
        data: file, state: 'in', inProgress: false, progress: 0, canRetry: false, canCancel: true
      });
      reader.readAsBinaryString(this.filesAttached[0].data);
    }
  };
  fileUpload.value = '';
  fileUpload.click();
}


  private showError() {
    swal.fire({
      title: '<p>' + this.translateService.instant('info.fileValidate') + '</p>',
      html: '<p>' + this.errores + '</p>',
      confirmButtonText: this.translateService.instant('button.confirm'),
      confirmButtonColor: '#d33',
      icon: 'warning',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.refreshComponent();
      }
    });
  }

  private validateFile(nameSize: number, error: boolean, nameCaract: boolean, fileName: string) {
    if (nameSize >= 50) {
      this.errores = this.errores.concat(this.translateService.instant('info.fileNameSize') + "<br>");
      this.cancelFile(this.filesAttached[0]);
      error = true;
    }

    if (!nameCaract || fileName.indexOf('\.') != fileName.lastIndexOf('\.')) {
      this.errores = this.errores.concat(this.translateService.instant('info.fileNameCaract') + "<br>");
      this.cancelFile(this.filesAttached[0]);
      error = true;
    }
    return error;
  }

  selectionBudgetTypeChange(event: MatOptionSelectionChange): void {
    this.isBudgetTypeSelected = true;
    this.budgetTypeRequired = false;
    this.budgetType = <BudgetType> event.source.value;
  }

  /**
   * Metodo que inicia el proceso de carga masiva del presupuesto
   */
  public startBulkLoadBudget() {
    if (this.isBudgetTypeSelected != null && this.filesAttached.length > 0 && this.budgetBulkLoadProcessing === false) {
      this.budgetBulkLoadProcessing = true;
      this.budgetService.saveBudgetsPostSale(this.budgetType.id, this.encodedFile).subscribe(
        data => {
          // console.log(data);
          this.showSuccessMessage(this.budgetType.id);
          this.budgetBulkLoadProcessing = false;
          this.bulkLoadSuccessfully = true;

        },
        (error: HttpErrorResponse) => {

          this.proccessBulkLoadError(error);
        }
      );

    } else {
      console.log('Error archivo');
    }


  }

  private proccessBulkLoadError(error: HttpErrorResponse) {
    if (error.error !== null && error.status == 400) {
  
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.height = '60vh';
      dialogConfig.width = '60vw';

      const dialogRef = this.dialog.open(DialogBulkLoadBudgetComponent, dialogConfig);
      console.log('error.error.data', error.error.data);
      dialogRef.componentInstance.dataSource.data = error.error;;
    } else {
      this.messageService.showErrorMessage(
        new Exception(SystemException, this.translateService.instant('error.bulkLoad'), error.status),
        this.translateService.instant('button.confirm'));
    }
    this.budgetBulkLoadProcessing = false;
    console.log('error', error);
  }

  /**
   * Metodo para subir el archivo
   */
  onClickAttachFile() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    const reader = new FileReader();
    reader.onload = () => {
      const binaryString = reader.result;
      this.encodedFile = btoa(binaryString.toString());
    };
    fileUpload.onchange = () => {

      let index = fileUpload.files.length - 1;
      const file = fileUpload.files[index];

      if (!this.allowedFileFormats.includes(file.type)) {
        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.invalidFileFormat')
        );
      } else if (file.name.length > 100) {
        this.messageService.showWarningMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('warning.fileName')
        );
      } else {
        this.filesAttached.push({
          data: file, state: 'in', inProgress: false, progress: 0, canRetry: false, canCancel: true
        });
        reader.readAsBinaryString(this.filesAttached[0].data);
      }
    };
    fileUpload.value = '';
    fileUpload.click();
  }


  /**
   * @param file
   */
  cancelFile(file: FileUploadModel) {
    const index = this.filesAttached.indexOf(file);
    if (index > -1) {
      this.filesAttached.splice(index, 1);
    }
    this.validCsvFormat = false;
  }



  public getBudgetBulkLoadList(pageIndex: number, pageSize: number) {
    this.bulkLoadListloading = true;
    this.budgetService.listBudgetBulkLoad(pageIndex, pageSize).subscribe({

      next: data => {
        if (data === undefined || data.length === 0) {
          this.noBulkLoadListFound = true;
          this.dataSource.data = [{ noData: true }];
          this.dataSource.paginator = null;
        } else {

            this.dataSource.data =  data.content;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            //this.paginator.length = data.totalElements;
            //this.paginator.pageIndex = data.pageable.pageNumber;
            //this.paginator.pageSize = data.pageable.pageSize;

            this.bulkLoadListloading = false;
          }
      },

      error: (error: HttpErrorResponse) => {

        if (error.status == 404) {
          this.noBulkLoadListFound = true;
          this.dataSource.data = [{ noData: true }];
          this.dataSource.paginator = null;

        }
        swal.fire({
          html: '<p>' + this.translateService.instant('error.loading') + '</p>',
          confirmButtonText: this.translateService.instant('button.confirm'),
          confirmButtonColor: '#d33',
          icon: 'error',
          allowOutsideClick: false
        }).then((result) => {
          if (result.value) {
            //Success
            this.refreshComponent();
            this.bulkLoadListloading = false;
          }
        });

        console.log('error', error);

      }

  });

  }

    /**
   * shows a message of success
   */
    private showSuccessMessage(idBulkLoad: number) {
      let messageTextUserSuccess = '';

      messageTextUserSuccess = this.translateService.instant('success.updateBudget');

      swal.fire({
        title: '<p>' + this.translateService.instant('popup.wellDone') + '</p>',
        html: '<p>' + messageTextUserSuccess + '</p>',
        confirmButtonText: this.translateService.instant('button.confirm'),
        confirmButtonColor: '#d33',
        icon: 'success',
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          this.refreshComponent();
        }
      });
    }

  /**
  * Metodo que sirve para refrescar la pagina
  */
  refreshComponent() {
    this.bulkLoadListloading = false;
    this.fileList = [];
    this.filesAttached = [];

  }



  highlightRow(event: Event, rowIndex: number) {
    this.selectedRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }
  pageEvent(event: PageEvent): void {

    this.getBudgetBulkLoadList(event.pageIndex, event.pageSize);

  }




}
