
import {share} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import * as jwt_decode from 'jwt-decode';

import { SessionInfo } from '../models/SessionInfo.model';

import { UserService } from './user.service';


@Injectable()
export class SessionInfoService {
  private token: any;
  private decoded_token: any;
  private sessionInfo: SessionInfo;
  private readonly SESSION_TOKEN: string = 'sessionToken';
  private userService: UserService;

  constructor(
    private injector: Injector,
    private router: Router,
    private translateService: TranslateService) { }

  /**
   * Crea el token que contiene la información de sesión de un usuario y
   * almacena dicho token en el local storage para su uso posterior
   */
  createSessionInfoToken(): Observable<Object> {
    this.userService = this.injector.get(UserService);
    let userInfoObservable = this.userService.getUserSessionToken().pipe(share());

    userInfoObservable.subscribe(
      data => {
        this.initServices(String(data['sessionToken']));
      },
      error => {
        if (error.status === 403) {
          this.setSessionInfo();
          this.router.navigateByUrl('/403', { skipLocationChange: true });
        }

        if (error.status === 404) {
          // Redirecciona al 401 cuando no existe el usuario en la BD
          this.router.navigateByUrl('/401', { skipLocationChange: true });
        }

        console.log('error', error);
      }
    );

    return userInfoObservable;
  }

  createOutsourcingSessionInfoToken(): Observable<Object> {
    this.userService = this.injector.get(UserService);
    let userInfoObservable = this.userService.getOutsourcingUserSessionToken().pipe(share());

    userInfoObservable.subscribe(
      data => {
        this.initServices(String(data['sessionToken']));
      },
      error => {
        if (error.status === 403) {
          this.setSessionInfo();
          this.router.navigateByUrl('/403', { skipLocationChange: true });
        }

        if (error.status === 404) {
          // Redirecciona al 401 cuando no existe el usuario en la BD
          this.router.navigateByUrl('/401', { skipLocationChange: true });
        }

        console.log('error', error);
      }
    );

    return userInfoObservable;
  }

  private initServices(token?: any) {
    if (!token && this.sessionInfo) {
      console.log('session exist');
      return null;
    }

    let storageToken = this.getTokenFromLocalStorage();
    let tokenTemp;
    let decodedTokenTemp;

    if (!token && !storageToken && !this.sessionInfo) {
      console.log('cant build session yet, possible initial state, no token found');
      return null;
    }

    if (token) {
      tokenTemp = token;
    } else if (storageToken && !this.sessionInfo) { // find token from localStorage
      console.log('trying to build session from localStorage');
      tokenTemp = storageToken;
    } else {
      console.log('invalid token, cant build session');
      return null;
    }

    decodedTokenTemp = jwt_decode(tokenTemp);

    if (!decodedTokenTemp) {
      console.log('no session loaded, failed decoding token');
      return null;
    }

    this.token = tokenTemp;
    localStorage.setItem(this.SESSION_TOKEN, this.token);
    this.decoded_token = decodedTokenTemp;
    this.sessionInfo = new SessionInfo(
      this.decoded_token['user_name'],
      this.decoded_token['user_position'],
      this.decoded_token['user_role'],
      this.decoded_token['user_secondary_role'],
      this.decoded_token['user_role_name'],
      this.decoded_token['user_secondary_role_name'],
      this.decoded_token['association_name'],
      this.decoded_token['user_market'],
      this.decoded_token['user_language'],
      this.decoded_token['user_id'],
      this.decoded_token['agency_id'],
      this.decoded_token['market_id'],
      this.decoded_token['allowed_actions'],
      this.decoded_token['token_type'],
      this.decoded_token['matrix_id'],
      this.decoded_token['branch_office_id'],
      this.decoded_token['is_matrix_user'],
      this.decoded_token['latam_role_size'],
      this.decoded_token['is_agree_termsconditions'],
      this.decoded_token['is_self_managed']
    );
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', this.sessionInfo.language);
      this.translateService.use(this.sessionInfo.language);
    }
  }

  cleanSession() {
    this.token = null;
    this.decoded_token = null;
    this.sessionInfo = null;
    localStorage.clear();
  }

  /**
   * Retorna la información de sesión del usuario logeadoW
   * @returns {SessionInfo}
   */
  getSessionInfo(): SessionInfo {
    return this.sessionInfo;
  }

  setSessionInfo() {
    return this.sessionInfo = null;
  }

  getSessionInfoAsPromise(): Promise<SessionInfo> {
    let promise: Promise<SessionInfo>;

    if (this.sessionInfo) {
      promise = new Promise<SessionInfo>(
        (resolve, reject) => {
          resolve(this.sessionInfo);
        }
      );
    }

    if (this.getTokenFromLocalStorage()) {
      promise = new Promise<SessionInfo>(
        (resolve, reject) => {
          this.initServices(this.getTokenFromLocalStorage());
          resolve(this.sessionInfo);
        }
      );
    } else {
      promise = new Promise<SessionInfo>(
        (resolve, reject) => {
          this.createSessionInfoToken().subscribe(
            () => {
              resolve(this.sessionInfo);
            },
            error => {
              console.log('Error while trying to build session by requesting session data');
              reject(error);
            }
          );
        }
      );
    }

    return promise;
  }

  /**
   * Obtiene el token que contiene la informacion de sesion del usuario logueado desde el local storage
   * @returns {string}
   */
  getToken(): string {
    return this.token;
  }

  private getTokenFromLocalStorage() {
    let token = localStorage.getItem(this.SESSION_TOKEN);
    return token ? token : null;
  }

  redirectTypeOfSession() {
    this.getSessionInfoAsPromise()
    .then(
      sessionInfo => {
        if (sessionInfo.isLatamSession()) {

          this.router.navigate(['/latam/home']).then(success => {
            window.location.reload();
          }).catch(error => {
            console.error('Navigation error:', error);
          });
        }

        if (sessionInfo.isAgencySession()) {
          this.router.navigate(['/agency/home']).then(success => {
            window.location.reload();
          }).catch(error => {
            console.error('Navigation error:', error);
          });
        }
      }
    )
    .catch(
      error => {
        console.log('error', error);
        this.router.navigate(['/login']);
      }
    );
  }

  validateRolePath(router: ActivatedRouteSnapshot , state: RouterStateSnapshot, infoUserRole: string) {
    const routePermissions = router.data['routePermissions'];

    let rolePath;

    routePermissions.filter(
      (routerPermission: Object) => {
        if (routerPermission['path'].includes(state.url)) {
          rolePath = routerPermission['role'];
        }
      }
    );


    if (rolePath && rolePath.indexOf(infoUserRole.toUpperCase()) === -1 ) {
      this.router.navigateByUrl('/401',  { skipLocationChange: true });
    }
  }
}
