import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

import { BusinessException, Exception, SystemException } from '../../../../models/Exception.model';
import { Market } from '../../../../models/market.model';
import { MonthlyBudgetGeneric } from '../../../../models/monthly-budget-generic.model';
import { Profile } from '../../../../models/profile.model';

import { BudgetService } from '../../../../services/budget.service';
import { MessageService } from '../../../../services/message.service';
import { ProfilingService } from '../../../../services/profiling.service';
import { MessageErrorService } from '../../../../services/message-error.service';


@Component({
  selector: 'app-agency-group-budget',
  templateUrl: './agency-group-budget.component.html',
  styleUrls: ['./agency-group-budget.component.css']
})
export class AgencyGroupBudgetComponent implements OnInit, OnChanges {
  loading = false;
  errorLoadingData = false;
  validateSave = false;
  validateBudget: MonthlyBudgetGeneric;

  newTotalCurrentAmount = -1; // se inicializa en -1 para deshabilitar el boton guardar

  budget: MonthlyBudgetGeneric[] = [];
  // displayedColumns: string[] = ['agencyGroupName', 'assignedValue', 'currentAmount', 'spentValue', 'differenceValue', 'actions'];
  displayedColumns: string[] = ['agencyGroupName', 'assignedValue', 'currentAmount', 'spentValue', 'actions'];
  private profiles = new Profile();

  @Input() market: Market;
  @Input() budgetTypeId: number;

  constructor(
    private budgetService: BudgetService,
    private messageService: MessageService,
    private profilesService: ProfilingService,
    private messageErrorService: MessageErrorService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.getAgencyGroupBudget(this.market.id, this.budgetTypeId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.budgetTypeId) {
      if (!changes.budgetTypeId.firstChange) {
        this.getAgencyGroupBudget(this.market.id, changes.budgetTypeId.currentValue);
      }
    }

    if (changes.market) {
      if (!changes.market.firstChange) {
        this.getAgencyGroupBudget(changes.market.currentValue.id, this.budgetTypeId);
      }
    }
  }

  private getAgencyGroupBudget(marketId: number, budgetTypeId: number): void {
    this.loading = true;
    this.errorLoadingData = false;

    this.budgetService.getAgencyGroupBudget(marketId, budgetTypeId).subscribe(
      data => {
        if (data.length > 0) {
          this.budget = data;
        } else {
          this.budget = [];
        }

        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        this.errorLoadingData = true;
        this.messageErrorService.sendError(error, 'error.loadingBudget');

        this.loading = false;
        console.log('error', error);
      }
    );
  }

  saveBudget(): void {
    if (this.profiles.createBudgetManagement) {
      if (this.newTotalCurrentAmount > 0) {
        this.showWarningMessage();
      } else if (this.newTotalCurrentAmount === 0) {
        this.saveAgencyGroupBudget(this.market.id, this.budgetTypeId, this.budget);
      }
    }
  }

  private saveAgencyGroupBudget(marketId: number, budgetTypeId: number, budget: MonthlyBudgetGeneric[]): void {
    this.loading = true;

    this.budgetService.saveAgencyGroupBudget(marketId, budgetTypeId, budget).subscribe(
      data => {
        this.loading = false;
        this.messageService.showSuccessMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('popup.wellDone'),
          this.translateService.instant('success.updateBudget')
        );
        this.getAgencyGroupBudget(this.market.id, this.budgetTypeId);
      },
      (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.saveBudget');

        this.loading = false;
        console.log('error', error);
      }
    );
  }

  private showWarningMessage(): void {
    swal.fire({
      html: `<p>${ this.translateService.instant('warning.budgetGreaterThan') }</p>`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('button.yes'),
      cancelButtonText: this.translateService.instant('button.cancel'),
      reverseButtons: true,
      customClass: {
        confirmButton: 'confirm-button',
        cancelButton: 'cancel-button'
      }
    }).then((result) => {
      if (result.value) {
        this.saveAgencyGroupBudget(this.market.id, this.budgetTypeId, this.budget);
      }
    });
  }

  refreshBudget(event: boolean): void {
    if (event) {
      this.getAgencyGroupBudget(this.market.id, this.budgetTypeId);
    }
  }

  setBudget(event: MonthlyBudgetGeneric[]) {
    this.budget = event;
  }

  setNewTotalCurrentAmount(event: number) {
    this.newTotalCurrentAmount = event;
  }
  setValidateSave(event: boolean){
    this.validateSave = event;
  }
}
