import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, LOCALE_ID } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { MonthlyBudgetGeneric } from '../../../../models/monthly-budget-generic.model';

import { MessageService } from '../../../../services/message.service';

@Component({
  selector: 'app-monthly-budget',
  templateUrl: './monthly-budget.component.html',
  styleUrls: ['./monthly-budget.component.css']
})
export class MonthlyBudgetComponent implements OnInit {
  noDataFound = false;
  month: number;
  newTotalCurrentAmountAux = 0;
  selectedRowIndex: number = -1;
  totalCurrentAmount: number;
  year: number;
  validateBudget: MonthlyBudgetGeneric;
  dataSource = new MatTableDataSource();
  private controls: UntypedFormArray;

  @Input() agencyGroupCurrentAmount: number = 0;
  @Input() errorLoadingData: boolean = false;
  @Input() budget: MonthlyBudgetGeneric[] = [];
  @Input() marketId: number;
  @Input() marketName: string;
  @Input() budgetTypeId: number;
  @Input() displayedColumns: string[] = [];

  @Output() budgetAux: EventEmitter<MonthlyBudgetGeneric[]> = new EventEmitter();
  @Output() newTotalCurrentAmount: EventEmitter<number> = new EventEmitter();
  @Output() refreshingBudget: EventEmitter<boolean> = new EventEmitter();
  @Output() validatesave: EventEmitter<boolean> = new EventEmitter();

  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loadingBudgetDataError = (index: number, item: { errorLoadingData: boolean }) => item.errorLoadingData;
  noBudgetDataFound = (index: number, item: { noData: boolean }) => item.noData;

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private translateService: TranslateService) { }

  ngOnInit() {
    if (!this.errorLoadingData) {
      this.dataSource.paginator = null;

      if (this.budget.length > 0) {
        this.month = this.budget[0].month;
        this.year = this.budget[0].year;
        // tslint:disable-next-line: max-line-length
        this.totalCurrentAmount = this.budget.reduce((accumulator, currentValue) => accumulator + currentValue.currentAmount, this.agencyGroupCurrentAmount);
        this.dataSource.data = this.budget;
        setTimeout(() => {
          // this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (dataAux, header) => {
            return (!isNaN(dataAux[header]) || dataAux[header] == null) ? dataAux[header] : dataAux[header].toLocaleLowerCase();
          };
        });

        this.buildFormArray();
      } else {
        this.noDataFound = true;
        this.dataSource.data = [{ noData: true }];
      }
    } else {
      this.dataSource.data = [{ errorLoadingData: true }];
    }
  }
  private buildFormArray(): void {
    const toGroups = this.budget.map(entity => {
      const formG = new UntypedFormGroup({ });
      formG.addControl(('assignedValue' + entity.id), new UntypedFormControl(entity.assignedValue));

      return formG;
    });

    this.controls = new UntypedFormArray(toGroups);
  }

  updateField(id: number, field: string): void {
    const control = this.getControl(field);

    if (control.valid) {
      this.update(id, 'assignedValue', control.value ? +control.value : 0);
      this.newTotalCurrentAmountAux =
        this.totalCurrentAmount - this.budget.reduce((accumulator, currentValue) => accumulator + currentValue.currentAmount, 0);
      this.dataSource.data = this.budget;
      this.budgetAux.emit(this.budget);
      this.newTotalCurrentAmount.emit(this.newTotalCurrentAmountAux);
      this.validateBudget = this.budget.find(x => x.id === id);
      if (this.validateBudget.assignedValue >= this.validateBudget.spentValue) {
         this.validatesave.emit(true);
       } else {
        this.validatesave.emit(false);
       }

      }
   }

  getControl(fieldName: string): UntypedFormControl {
    let control: UntypedFormControl;

    Object.keys(this.controls.controls).forEach(key => {
      if (this.controls.get(key).get(fieldName)) {
        control = this.controls.get(key).get(fieldName) as UntypedFormControl;
      }
    });

    return control;
  }

  private update(id: number, field: string, value: number): void {
    this.budget = this.budget.map((e, i) => {
      if (e.id === id) {
        return {
          ...e,
          ['assignedValueAux']: isNaN(e.assignedValueAux) ? e.assignedValue : e.assignedValueAux,
          [field]: value,
          ['currentAmount']: value - e.spentValue,
          ['differenceValue']: isNaN(e.assignedValueAux) ? value - e.assignedValue : value - e.assignedValueAux
        };
      }
      return e;
    });
  }

  refreshBudget(): void {
    this.refreshingBudget.emit(true);
  }

  highlightRow(event: Event, row: MonthlyBudgetGeneric): void {
    this.selectedRowIndex = event.type === 'mouseover' ? row.id : -1;
  }

  goNext(agencyGroupBudget: MonthlyBudgetGeneric) {
    if (agencyGroupBudget.differenceValue) {
      this.messageService.showWarningMessage(
        this.translateService.instant('button.confirm'),
        this.translateService.instant('warning.budgetChanges')
      );
    } else {
      this.goToBranchOfficeBudget(agencyGroupBudget.id);
    }
  }

  goToBranchOfficeBudget(agencyGroupId: number): void {
    this.router.navigate(
      ['/latam/budget/branch-office'],
      { relativeTo: this.activatedRoute,
        queryParams: { marketId: this.marketId, marketName: this.marketName, budgetTypeId: this.budgetTypeId, agencyGroupId: agencyGroupId }
      }
    );
  }

}
