import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AgencyGroup } from '../../../models/agency-group.model';
import { BranchOffice } from '../../../models/branch-office.model';
import { Profile } from '../../../models/profile.model';

import { AgencyBranchOfficeService } from './../../../services/agency-branch-office.service';
import { AgencyGroupService } from '../../../services/agency-group.service';
import { MessageService } from '../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { MessageErrorService } from '../../../services/message-error.service';


@Component({
  selector: 'app-create-branch-office',
  templateUrl: './create-branch-office.component.html',
  styleUrls: ['./create-branch-office.component.css']
})
export class CreateBranchOfficeComponent implements OnInit {
  @ViewChild('createBranchOfficeForm') createBranchOfficeForm: NgForm;
  group: AgencyGroup = new AgencyGroup();
  branchOffice: BranchOffice = new BranchOffice(null, null, null, true, false, false);
  loading: boolean = false;
  prefix: string;
  branchOfficeName: string;
  isCreatingBranchOffice: boolean = true;
  profiles = new Profile();

  constructor(
    public dialogRef: MatDialogRef<CreateBranchOfficeComponent>,
    private activatedRoute: ActivatedRoute,
    private agencyBranchOfficeService: AgencyBranchOfficeService,
    private agencyGroupService: AgencyGroupService,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private profilesService: ProfilingService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.activatedRoute.queryParams.subscribe(params => {
      this.loading = true;
      this.group.id = params.id;
      this.agencyGroupService.getAgencyGroupById(this.group.id).subscribe({
        next: data => {
          this.group = data;
          this.prefix = this.group.name + '-';
          this.branchOffice.agencyGroup = {
            id: this.group.id,
            name: null,
            agencyTier: null,
            agencyChannel: null,
            market: null,
            pptoWaiver: null,
            pptoAd: null,
            showBudget: null,
            branchOfficesNumber: null,
            userOfficesNumber: null,
            adminSales: null,
            salesExecutive: null,
            showPresaleBudget: null,
            allowPrivateFare: null,
            allowPreSaleModule: null,
            allowPostSaleModule: null,
            selfManaged: null,
            allowWaiverModule: null,
            mainIata: ''
          },
          this.loading = false;
          this.isCreatingBranchOffice = false;
        },
        error: (error: HttpErrorResponse) => {
          this.loading = false;
          this.isCreatingBranchOffice = false;
          console.log('error', error);
        }
    });
    });
  }

  /**
   * metodo para cerrar la modal
   */
  closeDialog() {
    this.dialogRef.close();
  }

  public saveBranchOffice(): void {
    this.isCreatingBranchOffice = true;
    this.branchOffice.name = `${this.prefix}${this.branchOfficeName}`;
    this.agencyBranchOfficeService.createBranchOffice(this.branchOffice).subscribe({
      next: data => {
        this.loading = false;
        this.isCreatingBranchOffice = false;

        this.messageService.showSuccessMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('popup.wellDone'),
          this.translateService.instant('success.createBranchOffice')
        );

        this.closeDialog();
      },
      error: (error: HttpErrorResponse) => {
        this.isCreatingBranchOffice = false;
        this.messageErrorService.sendError(error, 'error.createBranchOffice');

        this.loading = false;
        console.log('error', error);
      }
  });
  }
}
