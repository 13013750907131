import { Component, OnInit } from "@angular/core";
import { SidebarMenuModel } from "../../models/SidebarMenu.model";
import { SidebarShareDataService } from "../../services/sidebar-share-data.service";

@Component({
  selector: "app-form-cases",
  templateUrl: "./form-cases.component.html",
  styleUrls: ["./form-cases.component.css"],
})
export class FormCasesComponent implements OnInit{

  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_CASE_FORM, SidebarMenuModel.MENU_CASE_FORM);
  
  name = 'Dynamic Form Action Demo';
  url = 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8';
  tipoCaso = '';
  lenguajeSeleccionado = '';

  constructor(private sidebarService: SidebarShareDataService) {

  }


  ngOnInit(): void {
    this.sidebarService.changePanelOpened(this.sidebarMenu);
  }

  




}
