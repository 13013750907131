import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';

import { AssociatedIatasListComponent } from './associated-iatas/associated-iatas-list.component';
import { BookingInfoComponent } from './booking-info/booking-info.component';
import { BudgetAgencyGroupComponent } from './budget-agency-group/budget-agency-group.component';
import { DownloadListComponent } from './download-list/download-list.component';
import { BudgetPopupComponent } from './budget-popup/budget-popup.component';
import { EditableComponent } from './editable/editable.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { LoaderComponent } from './loader/loader.component';
import { MenuComponent } from './menu/menu.component';
import { HeaderComponent } from './header/header.component';
import { PassengerInfoComponent } from './passenger-info/passenger-info.component';
import { SegmentInfoComponent } from './segment-info/segment-info.component';
import { WaiverInfoComponent } from './waiver-info/waiver-info.component';
import { WaiverListPresaleComponent } from './waiver-list-presale/waiver-list-presale.component';
import { WaiverRequestInfoComponent } from './waiver-request-info/waiver-request-info.component';

import { PipesModule } from './../pipes/pipes.module';
import { LoadSalesReportComponent } from './BDA-portal/load-sales-report/load-sales-report.component';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { StatusTicketSearchComponent } from './status-ticket/status-ticket-search/status-ticket-search.component';
import { ByTicketComponent} from './status-ticket/by-ticket/by-ticket.component';
import { ByCsvComponent } from './status-ticket/by-csv/by-csv.component';
import { ByDateComponent } from './status-ticket/by-date/by-date.component';
import { FilterComponent } from './filter/filter.component';
import { FormCasesComponent } from './form-cases/form-cases.component';
import { ModalFarebasisComponent } from './status-ticket/modal-farebasis/modal-farebasis.component';
import { MatDialogModule} from '@angular/material/dialog';
import { LatamReportBaseComponent } from './latam-report-base/latam-report-base.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CsvModule } from '@ctrl/ngx-csv';
import { CountryImagesComponent } from './country-images/country-images.component';
import { WaiverListPostsaleComponent } from './waiver-list-postsale/waiver-list-postsale.component';
import { ListAgencyHeaderComponent } from './list-agency-header/list-agency-header.component';
import { ListWaiverAgentBudgetComponent } from './list-waiver-agent-budget/list-waiver-agent-budget.component';
import { ListWaiverAgentComponent } from '../modules/waiver-postsale-module/list-waiver-agent/list-waiver-agent.component';
import { WaiverLatamHeaderComponent } from './waiver-latam-header/waiver-latam-header.component';
import { WaiverLatamTermsComponent } from './waiver-latam-terms/waiver-latam-terms.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { WaiverRejectionDialogComponent } from './waiver-rejection-dialog/waiver-rejection-dialog.component';
import { HomeCourtesyWaiverComponent } from './home/home-courtesy/home-courtesy.component';
import { HomePolicyWaiverComponent } from './home/home-policy/home-policy.component';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
    declarations: [
        AssociatedIatasListComponent,
        BookingInfoComponent,
        BudgetAgencyGroupComponent,
        BudgetPopupComponent,
        DownloadListComponent,
        EditableComponent,
        FooterComponent,
        HomeComponent,
        LoaderComponent,
        MenuComponent,
        HeaderComponent,
        WaiverLatamHeaderComponent,
        PassengerInfoComponent,
        CountryImagesComponent,
        LatamReportBaseComponent,
        WaiverListPostsaleComponent,
        ListAgencyHeaderComponent,
        ListWaiverAgentBudgetComponent,
        SegmentInfoComponent,
        WaiverInfoComponent,
        WaiverListPresaleComponent,
        WaiverRequestInfoComponent,
        LoadSalesReportComponent,
        StatusTicketSearchComponent,
        ByTicketComponent,
        ByCsvComponent,
        ByDateComponent,
        FilterComponent,
        FormCasesComponent,
        ModalFarebasisComponent,
        LatamReportBaseComponent,
        CountryImagesComponent,
        WaiverListPostsaleComponent,
        ListAgencyHeaderComponent,
        ListWaiverAgentBudgetComponent,
        WaiverLatamHeaderComponent,
        WaiverLatamTermsComponent,
        WaiverRejectionDialogComponent,
        HomeCourtesyWaiverComponent,
        HomePolicyWaiverComponent
    ],
    imports: [
        BrowserModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        CommonModule,
        FormsModule,
        CsvModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        ReactiveFormsModule,
        RouterModule,
        PipesModule,
        TranslateModule,
        MatButtonModule,
        MatRadioModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatTabsModule,
        MatDialogModule,
        MatMenuModule
    ],
    exports: [
        AssociatedIatasListComponent,
        BookingInfoComponent,
        BudgetAgencyGroupComponent,
        BudgetPopupComponent,
        DownloadListComponent,
        EditableComponent,
        FooterComponent,
        HomeComponent,
        LoaderComponent,
        MenuComponent,
        HeaderComponent,
        WaiverLatamHeaderComponent,
        PassengerInfoComponent,
        CountryImagesComponent,
        LatamReportBaseComponent,
        SegmentInfoComponent,
        WaiverInfoComponent,
        WaiverListPostsaleComponent,
        ListAgencyHeaderComponent,
        ListWaiverAgentBudgetComponent,
        WaiverListPresaleComponent,
        WaiverRequestInfoComponent,
        MatRadioModule,
        MatNativeDateModule,
        MatDatepickerModule,
        FilterComponent,
        HomeCourtesyWaiverComponent,
        HomePolicyWaiverComponent
    ]
})
export class ComponentsModule { }
