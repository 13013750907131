import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { AgencyChannel } from '../../../models/agency-channel.model';
import { AgencyGroup } from '../../../models/agency-group.model';
import { AgencyTier } from '../../../models/agency-tier.model';
import { Market } from '../../../models/market.model';
import { Profile } from '../../../models/profile.model';
import { SessionInfo } from '../../../models/SessionInfo.model';
import { UserSales } from '../../../models/user-sales.mode';

import { AgencyGroupService } from '../../../services/agency-group.service';
import { AgencyService } from '../../../services/agency.service';
import { MarketService } from './../../../services/market.service';
import { MessageService } from '../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { MessageErrorService } from '../../../services/message-error.service';
import { AgencyGroupUtilService } from '../../../services/agency-group-util.service';


@Component({
  selector: 'app-edit-agency-group',
  templateUrl: './edit-agency-group.component.html',
  styleUrls: ['./edit-agency-group.component.css']
})
export class EditAgencyGroupComponent implements OnInit {
  channelList: AgencyChannel[] = [];
  groupId: number;
  group: AgencyGroup = new AgencyGroup();
  tierList: AgencyTier[] = [];
  markets: Market[] = [];
  loading: boolean = false;

  adminSalesList: UserSales[] = [];
  salesExecutiveList: UserSales[] = [];

  profiles = new Profile();
  infoUser$: Promise<SessionInfo> = this.sessionInfoService.getSessionInfoAsPromise();

  constructor(
    public dialogRef: MatDialogRef<EditAgencyGroupComponent>,
    private agencyGroupService: AgencyGroupService,
    private agencyService: AgencyService,
    private marketService: MarketService,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private translateService: TranslateService,
    private agencyGroupUtil: AgencyGroupUtilService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.loadAgencyGroupProfile();
    this.loadTiers();
    this.loadChannels();
    this.loadMarkets();
    this.loadAdminSaless();
    this.loadSalesExecutives();
  }

  private loadAgencyGroupProfile() {
    this.loading = true;
    this.agencyGroupService.getAgencyGroupById(this.groupId).subscribe({
      next: data => {
        this.group = data;
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
        console.log('error', error);
      }
  });
  }

  /**
   * Funcion que carga los tiers de la BD
   */
  private loadTiers() {
    this.agencyGroupUtil.loadTiers(this);
  }

  /**
   * metodo para cerrar la modal
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Funcion que carga las opciones del comboBox channel
   */
  private loadChannels() {
    this.agencyGroupUtil.loadChannels(this);
  }

  private loadMarkets() {
    this.marketService.getMarkets().subscribe({
      next: data => {
        this.markets = data;
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }
  });
  }

  /**
   * Funcion que carga las opciones del comboBox adminSales
   */
  private loadAdminSaless() {
    this.agencyGroupUtil.loadAdminSales(this);
  }

  /**
   * Funcion que carga las opciones del comboBox salesExecutive
   */
  private loadSalesExecutives() {
    this.agencyGroupUtil.loadSalesExecutives(this);
  }

  public saveGroup(): void {
    this.loading = true;
    let agencyGroup: AgencyGroup = new AgencyGroup();

    if (this.group.id !== this.groupId) {
      this.loading = false;
      console.log('something goes wrong the ids are not equal');
      return;
    }

    agencyGroup.name = this.group.name;
    agencyGroup.agencyChannel = this.group.agencyChannel;
    agencyGroup.agencyTier = this.group.agencyTier;
    agencyGroup.adminSales = this.group.adminSales;
    agencyGroup.salesExecutive = this.group.salesExecutive;
    agencyGroup.allowPrivateFare = this.group.allowPrivateFare;
    agencyGroup.allowPreSaleModule = this.group.allowPreSaleModule;
    agencyGroup.allowPostSaleModule = this.group.allowPostSaleModule;
    agencyGroup.selfManaged = this.group.selfManaged;
    agencyGroup.allowWaiverModule = this.group.allowWaiverModule;
    agencyGroup.mainIata= this.group.mainIata;

    this.agencyGroupService.updateAgencyGroup(this.groupId, agencyGroup)
      .subscribe({
        next: data => {
          this.loading = false;

          this.messageService.showSuccessMessage(
            this.translateService.instant('button.confirm'),
            this.translateService.instant('popup.wellDone'),
            this.translateService.instant('success.editAgencyGroup')
          );

          this.closeDialog();
        },
        error: (error: HttpErrorResponse) => {
          this.messageErrorService.sendError(error, 'error.editAgencyGroup');

          this.loading = false;
          console.log('error', error);
        }
  });
  }

  compareTier(tier1: AgencyTier, tier2: AgencyTier) {
    return tier1 && tier2 ? tier1.id === tier2.id : tier1 === tier2;
  }

  compareMarket(market1: Market, market2: Market) {
    return market1 && market2 ? market1.id === market2.id : market1 === market2;
  }

  compareChannel(channel2_1: AgencyChannel, channel2_2: AgencyChannel) {
    return channel2_1 && channel2_2 ? channel2_1.id === channel2_2.id : channel2_1 === channel2_2;
  }

  compareAdminSales(adminSales_1: UserSales, adminSales_2: UserSales) {
    return adminSales_1 && adminSales_2 ? adminSales_1.id === adminSales_2.id : adminSales_1 === adminSales_2;
  }

  compareSalesExecutive(salesExecutive_1: UserSales, salesExecutive_2: UserSales) {
    return salesExecutive_1 && salesExecutive_2 ? salesExecutive_1.id === salesExecutive_2.id : salesExecutive_1 === salesExecutive_2;
  }

}
