import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router, ActivatedRoute } from '@angular/router';

import { Profile } from '../../../models/profile.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';

import { ProfilingService } from '../../../services/profiling.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { SessionInfo } from '../../../models/SessionInfo.model';


@Component({
  selector: 'app-waiver-list',
  templateUrl: './waiver-list.component.html',
  styleUrls: ['./waiver-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WaiverListComponent implements OnInit {
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_WAIVERS, SidebarMenuModel.SUBMENU_PRESALE_WAIVER_MANAGEMENT);
  displayedColumnsPending = ['id', 'type', 'documentNumber', 'matrixBranchOffice', 'salesExecutive', 'agent', 'creationDate',
    'documentCloserDepartureDate', 'valorization', 'actions'];
  displayedColumnsApproved = ['id', 'type', 'documentNumber', 'matrixBranchOffice', 'salesExecutive', 'agent', 'creationDate',
  'documentCloserDepartureDate', 'valorization', 'actions'];
  displayedColumnsExpired = ['id', 'type',  'documentNumber', 'matrixBranchOffice', 'salesExecutive', 'agent', 'statusDate',
   'documentCloserDepartureDate', 'valorization', 'actions'];
  displayedColumnsIssued = ['id', 'type', 'documentNumber', 'matrixBranchOffice', 'salesExecutive', 'agent', 'statusDate',
    'documentCloserDepartureDate', 'valorization', 'actions'];
  displayedColumnsRejected = ['id', 'type', 'documentNumber', 'matrixBranchOffice', 'salesExecutive', 'agent', 'statusDate',
    'documentCloserDepartureDate', 'valorization', 'actions'];
  searchApproved = true;
  searchIssued = false;
  searchExpired = false;
  searchRejected = false;
  searchPending = false;
  groupId: number;
  branchOfficeId: number;
  reload: false;
  sessionInfo: SessionInfo = new SessionInfo();
  profiles = new Profile();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService) {
      this.sessionInfoService.getSessionInfoAsPromise().then((currentUser) => {
        this.groupId = currentUser.matrix_id;
        this.branchOfficeId = currentUser.branch_office_id;
      }).catch(error => {
        console.log('error', error);
      });
  }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.sessionInfo = this.sessionInfoService.getSessionInfo();
  }

  tabChanged(event: MatTabChangeEvent): void {
    this.searchApproved = false;
    this.searchIssued = false;
    this.searchExpired = false;
    this.searchRejected = false;
    this.searchPending = false;

    if (event.index === 0) {
      this.searchApproved = true;
    } else if (event.index === 1) {
      this.searchIssued = true;
    } else if (event.index === 2) {
      this.searchExpired = true;
    } else if (event.index === 3) {
      this.searchRejected = true;
    } else if (event.index === 4) {
      this.searchPending = true;
    }
  }

  /*
   * Metodo que redirecciona a la creación de nueva solicitud de waiver
   */
  nuevaSolicitud() {
    this.router.navigate(['../presale'], { relativeTo: this.activatedRoute });
  }

}
