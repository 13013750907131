import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-budget',
  templateUrl: './dialog-bulk-load-budget.component.html',
  styleUrls: ['./dialog-bulk-load-budget.component.css']
})
export class DialogBulkLoadBudgetComponent{
  constructor(public dialogRef: MatDialogRef<DialogBulkLoadBudgetComponent>) { }

  dataSource = new MatTableDataSource();
  displayedColumns = ['rowNumber','columnName','providedValue','errorMessage'];
 
  closeDialog() {
    this.dialogRef.close();
  }

}
