import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

import { Segment } from '../../models/segment.model';

import { SegmentInfoInterface } from '../../interfaces/segment-info.interface';
import { WaiverPresaleType } from '../../models/waiver-presale-type.model';

@Component({
  selector: 'app-segment-info',
  templateUrl: './segment-info.component.html',
  styleUrls: ['./segment-info.component.css']
})
export class SegmentInfoComponent implements OnInit, AfterViewChecked {
  dataSource = new MatTableDataSource();
  selectedRow: number = -1;
  ticketDesignators = [];

  @ViewChild('segmentInfoForm') segmentInfoForm: NgForm;

  @Input() segments: Segment[];
  @Input() isValuated: boolean;
  @Input() isPrivate: boolean;
  @Input() waiverType: WaiverPresaleType;
  @Input() step: number;
  @Output() formValid: EventEmitter<boolean> = new EventEmitter();
  @Input() displayedColumns: string[];



  ngOnInit() {

    for (let i = 0; i <= 20; i++) {
      const dd = i > 9 ? i : `0${i}`;
      this.ticketDesignators.push(`DD${dd}`);
    }

    if (this.segments) {
      this.setSegtmentInfo();
    }
  }

  ngAfterViewChecked() {
    this.formValid.emit(this.segmentInfoForm.valid);
  }

  setSegtmentInfo() {
    for (const segment of this.segments) {
      const segmentInfo: SegmentInfoInterface = {
        segmentNumber: segment.segmentNumber,
        carrierMarketing: segment.carrierMarketing,
        flightNumber: segment.flightNumber,
        class: segment.class,
        source: segment.source,
        destination: segment.destination,
        departureDateTime: segment.departureDateTime,
        fareBasis: segment.fareBasis,
        status: segment.status,
        ticketDesignator: segment.ticketDesignator
      };

      this.dataSource.data.push(segmentInfo);
    }
  }

  highlightRow(event: Event, rowIndex: number) {
    this.selectedRow = event.type === 'mouseover' ? rowIndex : -1;
  }

  changeSegments(row: Segment) {
    for (let segment of this.segments) {
      if (segment.segmentNumber === row.segmentNumber) {
        if (row.fareBasis != null) {
          segment.fareBasis = row.fareBasis.toUpperCase();
        }
        segment.ticketDesignator = row.ticketDesignator;
        break;
      }
    }
  }

  setMaxLength() {
    if (this.isPrivate && this.waiverType && (this.waiverType.code !== 'FRD')) {
      return '13';
    } else {
      return '8';
    }
  }

}
