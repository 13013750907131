import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { TermsConditionsComponent } from './../../modules/terms-conditions-module/terms-conditions/terms-conditions.component';

import { SessionInfoService } from './../../services/session-info.service';
import { TermsConditionsService } from './../../services/terms-conditions.service';
import { SessionInfo } from '../../models/SessionInfo.model';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  termsConditions: string = '';
  session: SessionInfo;

  constructor(
    private dialog: MatDialog,
    private sessionInfoService: SessionInfoService,
    private termsConditionsService: TermsConditionsService) { }

  ngOnInit() {
    this.sessionInfoService.getSessionInfoAsPromise()
      .then(
        session => {
          this.session = session;
          if (session.isAgencySession()) {
            this.getTermsConditions();
          }
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
  }

  private getTermsConditions(): void {
    this.termsConditionsService.getTermsConditionsUrl().subscribe(
      data => {
        this.termsConditions = data.url;
        if (!this.session.is_agree_termsconditions && this.termsConditions) {
          this.openDialog(this.termsConditions);
        }
      },
      (error: HttpErrorResponse) => {
        console.log('error', error);
      }
    );
  }

  redirect(): void {
    this.sessionInfoService.redirectTypeOfSession();

  }

  private openDialog(pdfSrc: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '90vw';
    dialogConfig.height = '800px';
    let dialogRef = this.dialog.open(TermsConditionsComponent, dialogConfig);
    dialogRef.componentInstance.pdfSrc = pdfSrc;
  }
}
