import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation, LOCALE_ID } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observer ,  Observable ,  forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { AgencyGroup } from '../../../../models/agency-group.model';
import { Budget } from '../../../../models/budget.model';
import { BudgetType } from '../../../../models/budget-type.model';
import { BusinessException, Exception, SystemException } from '../../../../models/Exception.model';
import { Market } from '../../../../models/market.model';
import { MonthlyBudget } from '../../../../models/monthly-budget.model';
import { Profile } from '../../../../models/profile.model';
import { SessionInfo } from '../../../../models/SessionInfo.model';
import { SidebarMenuModel } from '../../../../models/SidebarMenu.model';

import { GlobalBudgetBranchOfficeComponent } from '../global-budget-branch-office/global-budget-branch-office.component';

import { AgencyGroupService } from '../../../../services/agency-group.service';
import { BudgetService } from '../../../../services/budget.service';
import { MarketService } from './../../../../services/market.service';
import { MessageService } from '../../../../services/message.service';
import { ProfilingService } from '../../../../services/profiling.service';
import { SessionInfoService } from '../../../../services/session-info.service';
import { SidebarShareDataService } from '../../../../services/sidebar-share-data.service';
import { MessageErrorService } from '../../../../services/message-error.service';


@Component({
  selector: 'app-global-budget-management',
  templateUrl: './global-budget-management.component.html',
  styleUrls: ['./global-budget-management.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GlobalBudgetManagementComponent implements OnInit {
  agencyGroupBudgetLoaded = false;
  budgetTypeRequired = false;
  initialShowBudget: boolean;
  isBudgetTypeSelected = false;
  loading = false;
  loadingAgencyGroupBudget = false;

  language: string;
  position = 'below';
  selectedPos: string;

  agencyGroup = new UntypedFormControl();
  budgetType: BudgetType = new BudgetType(null, null);
  budgetTypes: BudgetType[] = [];
  filteredAgencyGroups: Observable<AgencyGroup[]>;
  firstSemesterBudgetGroup = new MatTableDataSource();
  firstSemesterColumns = ['position', 'ene', 'feb', 'mar', 'abr', 'may', 'jun'];
  firstSemesterGlobalBudget = new MatTableDataSource();
  infoUser$: Promise<SessionInfo> = this.sessionInfoService.getSessionInfoAsPromise();
  initialGroupBudgetFirstSemester = [];
  initialGroupBudgetSecondSemester = [];
  JSON = JSON;
  marketInfo: Market;
  monthlyBudget: MonthlyBudget = new MonthlyBudget();
  profiles = new Profile();
  secondSemesterBudgetGroup = new MatTableDataSource();
  secondSemesterColumns = ['position', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic', 'total'];
  secondSemesterGlobalBudget = new MatTableDataSource();
  selectedAgencyGroup: AgencyGroup;

  private initialGroupBudget = {};
  private selectedMarketAgencyGroups: AgencyGroup[] = [];
  private sidebarMenu: SidebarMenuModel = new SidebarMenuModel(
    SidebarMenuModel.MENU_LATAM, SidebarMenuModel.SUBMENU_LATAM_BUDGET_MANAGEMENT
  );

  @ViewChild('globalBudgetBranchOfficeComponent') globalBudgetBranchOfficeComponent: GlobalBudgetBranchOfficeComponent;

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private activatedRoute: ActivatedRoute,
    private agencyGroupService: AgencyGroupService,
    private budgetService: BudgetService,
    private marketService: MarketService,
    private messageErrorService: MessageErrorService,
    private messageService: MessageService,
    private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService) {
      this.language = locale;
    }

  ngOnInit(): void {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.infoUser$
      .then(
        sessionInfo => {
          this.marketInfo = new Market(sessionInfo.marketId[0], sessionInfo.market[0]);
          this.selectedPos = this.marketInfo.name;
          this.getBudgetTypes(this.language);
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
  }

  getBudgetTypes(language: string): void {
    this.budgetTypes = this.budgetService.getBudgetTypes();

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.budgetTypeId && this.budgetTypes.length > 0) {
        this.budgetType = this.budgetTypes.find(f => f.id === +params.budgetTypeId);
        this.isBudgetTypeSelected = true;
      }

      if (params.marketId && params.marketName) {
        this.marketInfo = new Market(params.marketId, params.marketName);
        this.selectedPos = params.marketName;
      }
    });
  }

  selectionBudgetTypeChange(event: MatOptionSelectionChange): void {
    this.isBudgetTypeSelected = true;
    this.budgetTypeRequired = false;
    this.budgetType = <BudgetType> event.source.value;

    this.infoUser$
      .then(
        sessionInfo => {
          if (!(sessionInfo.isAdminSalesLatam() || sessionInfo.isUserSalesLatam())) {
            this.getMarketBySelectedPos(this.selectedPos);
          }
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
  }

  /**
   * Carga la informacion del presupuesto global y la lista de grupos agencia
   * @param selectedPos
   */
  getMarketBySelectedPos(selectedPos: string): void {
    this.agencyGroup.setValue(new AgencyGroup());
    this.selectedAgencyGroup = null;

    if (this.budgetType.id !== null) {
      this.infoUser$
        .then(
          sessionInfo => {
            if (!this.isSalesLatam(sessionInfo)) {
              this.loadMarketGlobalBudget();
            }

            this.marketService.getMarketByName(selectedPos).subscribe({
              next: data => {
                this.marketInfo = data;

                if (!this.isSalesLatam(sessionInfo) && this.marketInfo.name != null) {
                    this.loadAgencyGroups(this.marketInfo.id);
                } else {
                  this.selectedMarketAgencyGroups = [];
                }
              },
              error: (error: HttpErrorResponse) => {
                this.messageErrorService.sendError(error, 'error.loadingPos');

                console.log('error', error);
              }
          });
          }
        )
        .catch(
          error => {
            console.log('error', error);
          }
        );
    } else {
      this.budgetTypeRequired = true;
    }
  }

  isSalesLatam(sessionInfo: SessionInfo) {
    return sessionInfo.isAdminSalesLatam() || sessionInfo.isUserSalesLatam();
  }

  private loadMarketGlobalBudget(): void {
    this.loading = true;
    this.agencyGroupBudgetLoaded = false;
    this.initializeGlobalBudgetTable();
    this.initializeAgencyGroupBudgetTable();
    this.getMarketGlobalBudgetForCurrentYear(this.selectedPos);
  }

  private loadAgencyGroups(marketId: number): Observable<AgencyGroup[]> {
    this.agencyGroup.disable();
    let loadGroups: Observable<AgencyGroup[]> = this.agencyGroupService.getAgencyGroupListByMarket(marketId);

    loadGroups.subscribe({
      next: data => {
        this.selectedMarketAgencyGroups = data;
        this.filteredAgencyGroups = this.agencyGroup.valueChanges.pipe(
          startWith<string | AgencyGroup>(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filter(name) : this.selectedMarketAgencyGroups.slice())
        );
        this.agencyGroup.enable();
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingAgencies');

        console.log('error', error);
      }
  });

    return loadGroups;
  }

  initializeGlobalBudgetTable(): void {
    this.firstSemesterGlobalBudget.data[0] = {};
    this.firstSemesterGlobalBudget.data[1] = {};
    this.firstSemesterGlobalBudget.data[2] = {};
    this.firstSemesterGlobalBudget.data[3] = {};
    this.firstSemesterGlobalBudget.data[4] = {};
    this.firstSemesterGlobalBudget.data[0]['rowName'] = 'Transferido';
    this.firstSemesterGlobalBudget.data[1]['rowName'] = 'Cargado';
    this.firstSemesterGlobalBudget.data[2]['rowName'] = 'Por distribuir';
    this.firstSemesterGlobalBudget.data[3]['rowName'] = 'Gastado';
    this.firstSemesterGlobalBudget.data[4]['rowName'] = 'Disponible';
    this.firstSemesterGlobalBudget.data[0]['inputTooltip'] = 0;
    this.firstSemesterGlobalBudget.data[1]['inputTooltip'] = 1;
    this.firstSemesterGlobalBudget.data[2]['inputTooltip'] = 2;
    this.firstSemesterGlobalBudget.data[3]['inputTooltip'] = 3;
    this.firstSemesterGlobalBudget.data[4]['inputTooltip'] = 4;
    this.firstSemesterGlobalBudget.data[0]['amount'] = [];
    this.firstSemesterGlobalBudget.data[1]['amount'] = [];
    this.firstSemesterGlobalBudget.data[2]['amount'] = [];
    this.firstSemesterGlobalBudget.data[3]['amount'] = [];
    this.firstSemesterGlobalBudget.data[4]['amount'] = [];

    this.secondSemesterGlobalBudget.data[0] = {};
    this.secondSemesterGlobalBudget.data[1] = {};
    this.secondSemesterGlobalBudget.data[2] = {};
    this.secondSemesterGlobalBudget.data[3] = {};
    this.secondSemesterGlobalBudget.data[4] = {};
    this.secondSemesterGlobalBudget.data[0]['rowName'] = 'Transferido';
    this.secondSemesterGlobalBudget.data[1]['rowName'] = 'Cargado';
    this.secondSemesterGlobalBudget.data[2]['rowName'] = 'Por distribuir';
    this.secondSemesterGlobalBudget.data[3]['rowName'] = 'Gastado';
    this.secondSemesterGlobalBudget.data[4]['rowName'] = 'Disponible';
    this.secondSemesterGlobalBudget.data[0]['inputTooltip'] = 0;
    this.secondSemesterGlobalBudget.data[1]['inputTooltip'] = 1;
    this.secondSemesterGlobalBudget.data[2]['inputTooltip'] = 2;
    this.secondSemesterGlobalBudget.data[3]['inputTooltip'] = 3;
    this.secondSemesterGlobalBudget.data[4]['inputTooltip'] = 4;
    this.secondSemesterGlobalBudget.data[0]['amount'] = [];
    this.secondSemesterGlobalBudget.data[1]['amount'] = [];
    this.secondSemesterGlobalBudget.data[2]['amount'] = [];
    this.secondSemesterGlobalBudget.data[3]['amount'] = [];
    this.secondSemesterGlobalBudget.data[4]['amount'] = [];
  }

  initializeAgencyGroupBudgetTable(): void {
    this.firstSemesterBudgetGroup.data[0] = {};
    this.firstSemesterBudgetGroup.data[1] = {};
    this.firstSemesterBudgetGroup.data[2] = {};
    this.firstSemesterBudgetGroup.data[0]['input'] = true;
    this.firstSemesterBudgetGroup.data[1]['input'] = false;
    this.firstSemesterBudgetGroup.data[2]['input'] = false;
    this.firstSemesterBudgetGroup.data[0]['rowName'] = 'Cargado';
    this.firstSemesterBudgetGroup.data[1]['rowName'] = 'Gastado';
    this.firstSemesterBudgetGroup.data[2]['rowName'] = 'Disponible';
    this.firstSemesterBudgetGroup.data[0]['amount'] = [];
    this.firstSemesterBudgetGroup.data[1]['amount'] = [];
    this.firstSemesterBudgetGroup.data[2]['amount'] = [];

    this.secondSemesterBudgetGroup.data[0] = {};
    this.secondSemesterBudgetGroup.data[1] = {};
    this.secondSemesterBudgetGroup.data[2] = {};
    this.secondSemesterBudgetGroup.data[0]['input'] = true;
    this.secondSemesterBudgetGroup.data[1]['input'] = false;
    this.secondSemesterBudgetGroup.data[2]['input'] = false;
    this.secondSemesterBudgetGroup.data[0]['rowName'] = 'Cargado';
    this.secondSemesterBudgetGroup.data[1]['rowName'] = 'Gastado';
    this.secondSemesterBudgetGroup.data[2]['rowName'] = 'Disponible';
    this.secondSemesterBudgetGroup.data[0]['amount'] = [];
    this.secondSemesterBudgetGroup.data[1]['amount'] = [];
    this.secondSemesterBudgetGroup.data[2]['amount'] = [];
  }

  private getMarketGlobalBudgetForCurrentYear(market: string): void {
    this.budgetService.getMarketGlobalBudgetForCurrentYear(market, this.budgetType.id).subscribe({
      next: data => {
        data.slice(0, 6).forEach(b => {
          this.firstSemesterGlobalBudget.data[0]['amount'][b.month] = b.transferValue;
          this.firstSemesterGlobalBudget.data[1]['amount'][b.month] = b.assignedValue;
          this.firstSemesterGlobalBudget.data[2]['amount'][b.month] = b.toBeDistributedValue;
          this.firstSemesterGlobalBudget.data[3]['amount'][b.month] = b.spentValue;
          this.firstSemesterGlobalBudget.data[4]['amount'][b.month] = b.availableValue;
        });
        data.slice(6, 12).forEach(b => {
          this.secondSemesterGlobalBudget.data[0]['amount'][b.month] = b.transferValue;
          this.secondSemesterGlobalBudget.data[1]['amount'][b.month] = b.assignedValue;
          this.secondSemesterGlobalBudget.data[2]['amount'][b.month] = b.toBeDistributedValue;
          this.secondSemesterGlobalBudget.data[3]['amount'][b.month] = b.spentValue;
          this.secondSemesterGlobalBudget.data[4]['amount'][b.month] = b.availableValue;
        });
        this.secondSemesterGlobalBudget.data[0]['total'] =
          this.firstSemesterGlobalBudget.data[0]['amount'].
            concat(this.secondSemesterGlobalBudget.data[0]['amount']).reduce((a: number, b: number) => a + b);
        this.secondSemesterGlobalBudget.data[1]['total'] =
          this.firstSemesterGlobalBudget.data[1]['amount'].
            concat(this.secondSemesterGlobalBudget.data[1]['amount']).reduce((a: number, b: number) => a + b);
        this.secondSemesterGlobalBudget.data[2]['total'] =
          this.firstSemesterGlobalBudget.data[2]['amount'].
            concat(this.secondSemesterGlobalBudget.data[2]['amount']).reduce((a: number, b: number) => a + b);
        this.secondSemesterGlobalBudget.data[3]['total'] =
          this.firstSemesterGlobalBudget.data[3]['amount'].
            concat(this.secondSemesterGlobalBudget.data[3]['amount']).reduce((a: number, b: number) => a + b);
        this.secondSemesterGlobalBudget.data[4]['total'] =
          this.firstSemesterGlobalBudget.data[4]['amount'].
            concat(this.secondSemesterGlobalBudget.data[4]['amount']).reduce((a: number, b: number) => a + b);
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.secondSemesterGlobalBudget.data[0]['total'] = 0.0;
        this.secondSemesterGlobalBudget.data[1]['total'] = 0.0;
        this.secondSemesterGlobalBudget.data[2]['total'] = 0.0;
        this.secondSemesterGlobalBudget.data[3]['total'] = 0.0;
        this.secondSemesterGlobalBudget.data[4]['total'] = 0.0;

        this.messageErrorService.sendError(error, 'error.loadingBudget');

        this.loading = false;
        console.log('error', error);
      }});
  }

  /**
   * Set editable input for the amount
   * true = disabled
   * false = enabled
   * 0=January, 1=February
   * @param month
   */
  disabledMonthlyAmountInput(month: number): boolean {
    let today = new Date();
    let monthToday = today.getMonth();
    return monthToday > month;
  }

  displayFn(agencyGroup?: AgencyGroup): string | undefined {
    return agencyGroup ? agencyGroup.name : undefined;
  }

  private _filter(name: string): AgencyGroup[] {
    const filterValue = name.toLowerCase();
    return this.selectedMarketAgencyGroups.filter(option => option.name.toLowerCase().startsWith(filterValue));
  }

  onSelectionAgencyGroupChange(event: MatOptionSelectionChange, agencyGroup: AgencyGroup): void {
    if (event.source.selected) {
      this.selectedAgencyGroup = agencyGroup;
      this.getBudgetByAgencyGroup(this.selectedAgencyGroup);
    }
  }

  private getBudgetByAgencyGroup(selectedAgencyGroup: AgencyGroup): void {
    this.agencyGroupBudgetLoaded = false;
    this.loadingAgencyGroupBudget = true;

    if (this.budgetType.id === 1) {
      this.budgetService.getBudgetPostSaleByAgencyGroup(selectedAgencyGroup.id).subscribe({
        next: data => {
          this.setBudgetAgencyGroupData(data, selectedAgencyGroup);
          this.agencyGroupBudgetLoaded = true;
          this.loadingAgencyGroupBudget = false;
        },
        error: (error: HttpErrorResponse) => {
          this.messageErrorService.sendError(error, 'error.loadingBudget');

          this.loadingAgencyGroupBudget = false;
          console.log('error', error);
        }});
    } else if (this.budgetType.id === 2) {
      this.budgetService.getBudgetPresaleByAgencyGroup(selectedAgencyGroup.id).subscribe({
        next: data => {
          this.setBudgetAgencyGroupData(data, selectedAgencyGroup);
          this.agencyGroupBudgetLoaded = true;
          this.loadingAgencyGroupBudget = false;
        },
        error: (error: HttpErrorResponse) => {
          this.messageErrorService.sendError(error, 'error.loadingBudget');

          this.loadingAgencyGroupBudget = false;
          console.log('error', error);
        }});
    }
  }

  private setBudgetAgencyGroupData(data: Budget[], selectedAgencyGroup: AgencyGroup): void {
    this.monthlyBudget.groupId = selectedAgencyGroup.id;
    this.monthlyBudget.showBudget = selectedAgencyGroup.showBudget;
    this.monthlyBudget.showPresaleBudget = selectedAgencyGroup.showPresaleBudget;
    this.initialShowBudget = this.budgetType.id === 1 ? selectedAgencyGroup.showBudget : selectedAgencyGroup.showPresaleBudget;
    data.slice(0, 6).forEach(b => {
      this.firstSemesterBudgetGroup.data[0]['amount'][b.month] = parseFloat(b.assignedValue.toString()).toFixed(2);
      this.firstSemesterBudgetGroup.data[1]['amount'][b.month] = b.spentValue;
      this.firstSemesterBudgetGroup.data[2]['amount'][b.month] = b.currentAmount;

    });
    data.slice(6, 12).forEach(b => {
      this.secondSemesterBudgetGroup.data[0]['amount'][b.month] = parseFloat(b.assignedValue.toString()).toFixed(2);
      this.secondSemesterBudgetGroup.data[1]['amount'][b.month] = b.spentValue;
      this.secondSemesterBudgetGroup.data[2]['amount'][b.month] = b.currentAmount;
    });
    this.initialGroupBudget = data;
    this.initialGroupBudgetFirstSemester = this.firstSemesterBudgetGroup.data[0]['amount'].slice();
    this.initialGroupBudgetSecondSemester = this.secondSemesterBudgetGroup.data[0]['amount'].slice();
    this.secondSemesterBudgetGroup.data[0]['total'] =
      this.firstSemesterBudgetGroup.data[0]['amount'].concat(
        this.secondSemesterBudgetGroup.data[0]['amount']).reduce((a, b) => parseFloat(a) + parseFloat(b)
        );
    this.secondSemesterBudgetGroup.data[1]['total'] =
      this.firstSemesterBudgetGroup.data[1]['amount'].concat(this.secondSemesterBudgetGroup.data[1]['amount']).reduce((a, b) => a + b);
    this.secondSemesterBudgetGroup.data[2]['total'] =
      this.firstSemesterBudgetGroup.data[2]['amount'].concat(this.secondSemesterBudgetGroup.data[2]['amount']).reduce((a, b) => a + b);
  }

  save(): void {
    if (this.profiles.createBudgetManagement) {
      this.loading = true;

      forkJoin([
        this.saveBudget(),
        this.updateShowBudgetAgencyGroup()
      ]).subscribe({
        next: data => {
          this.messageService.showSuccessMessage(
            this.translateService.instant('button.confirm'),
            this.translateService.instant('popup.wellDone'),
            this.translateService.instant('success.editAgencyGroup')
          );
          this.reloadAllSelectedData();
          this.loading = false;
        },
        error: error => {
          this.loading = false;
          console.log('error', error);
        }
    });
    }
  }

  private saveBudget(): Observable<MonthlyBudget> {
    return new Observable((observer: Observer<MonthlyBudget>) => {
      let budgets: Budget[] = [];
      let initialMonthlyBudget = this.initialGroupBudget;

      this.firstSemesterBudgetGroup.data[0]['amount'].slice(0, 6).forEach((value: string, key: number) => {
        let month = key;
        let addedValue = parseFloat(value) - parseFloat(initialMonthlyBudget[month].assignedValue);
        budgets.push(new Budget(
          initialMonthlyBudget[month].id, month, parseFloat(value), initialMonthlyBudget[month].spentValue, 0, addedValue)
        );
      });
      this.secondSemesterBudgetGroup.data[0]['amount'].slice(6, 12).forEach((value: string, key: number) => {
        let month = 6 + key;
        let addedValue = parseFloat(value) - parseFloat(initialMonthlyBudget[month].assignedValue);
        budgets.push(new Budget(
          initialMonthlyBudget[month].id, month, parseFloat(value), initialMonthlyBudget[month].spentValue, 0, addedValue)
        );
      });

      this.monthlyBudget.budget = budgets;
      this.monthlyBudget.market = this.marketInfo;

      if (this.budgetType.id === 1) {
        this.budgetService.saveBudgetPostSale(this.monthlyBudget).subscribe({
          next: data => {
            observer.next(null); // se añade para que el forkJoin entre en el bloque next
            observer.complete();
          },
          error: (error: HttpErrorResponse) => {
            observer.error('error salvando presupuestos');
            this.messageErrorService.sendError(error, 'error.saveBudget');

            console.log('error', error);
          }});
      } else if (this.budgetType.id === 2) {
        this.budgetService.saveBudgetPresale(this.monthlyBudget).subscribe({
          next: data => {
            observer.next(null); // se añade para que el forkJoin entre en el bloque next
            observer.complete();
          },
          error: (error: HttpErrorResponse) => {
            observer.error('error salvando presupuestos');
            this.messageErrorService.sendError(error, 'error.saveBudget');

            console.log('error', error);
          }});
      }
    });
  }

  private updateShowBudgetAgencyGroup(): Observable<AgencyGroup> {
    return new Observable((observer: Observer<AgencyGroup>) => {
      let selectedGroup: AgencyGroup = new AgencyGroup();
      selectedGroup.id = this.monthlyBudget.groupId;
      selectedGroup.showBudget = this.monthlyBudget.showBudget;
      selectedGroup.showPresaleBudget = this.monthlyBudget.showPresaleBudget;

      this.agencyGroupService.updateShowBudgetAgencyGroup(selectedGroup, this.budgetType.id).subscribe({
        next: data => {
          observer.next(null); // se añade para que el forkJoin entre en el bloque next
          observer.complete();
        },
        error: (error: HttpErrorResponse) => {
          observer.error('error salvando "mostrar presupuesto"');

          this.messageErrorService.sendError(error, 'error.saveBudget');

          console.log('error', error);
        }});
    });
  }

  private reloadAllSelectedData(): void {
    this.getMarketGlobalBudgetForCurrentYear(this.selectedPos);
    this.getBudgetByAgencyGroup(this.selectedAgencyGroup);
    this.loadAgencyGroups(this.marketInfo.id).subscribe({
      next: data => {
        this.selectedAgencyGroup = data.find((group: AgencyGroup) => {
          return group.id === this.selectedAgencyGroup.id;
        });
        this.monthlyBudget.showBudget = this.selectedAgencyGroup.showBudget;
        this.monthlyBudget.showPresaleBudget = this.selectedAgencyGroup.showPresaleBudget;
        this.initialShowBudget =
          this.budgetType.id === 1 ? this.selectedAgencyGroup.showBudget : this.selectedAgencyGroup.showPresaleBudget;
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }
  });
  }

  reloadBudget(event: boolean): void {
    if (event) {
      this.reloadAllSelectedData();
    }
  }

  compareBudgetType(budgetType1: BudgetType, budgetType2: BudgetType) {
    return budgetType1 && budgetType2 ? budgetType1.id === budgetType2.id : budgetType1 === budgetType2;
  }
}
