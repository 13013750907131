import { HttpErrorResponse } from '@angular/common/http';
import { OnInit, Component, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

import { AgencyGroup } from '../../../models/agency-group.model';
import { Profile } from '../../../models/profile.model';
import { SessionInfo } from '../../../models/SessionInfo.model';
import { User } from '../../../models/user.model';

import { CreateEditUserComponent } from '../create-edit-user/create-edit-user.component';

import { MessageService } from '../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { UserService } from '../../../services/user.service';
import { MessageErrorService } from '../../../services/message-error.service';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit, OnChanges {
  loading = false;
  loadUsersNoDataError = false;
  usersDataFound = false;

  selectedRowIndex: number = -1;
  private maxLength = 10;

  infoUser$: Promise<SessionInfo> = this.sessionInfoService.getSessionInfoAsPromise();
  dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = ['association', 'name', 'lastname', 'email', 'position', 'role', 'actions'];

  profiles = new Profile();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() matrixId: number;
  @Input() branchOfficeId: number;
  @Input() reloadUsers: boolean = false;
  @Input() typeUser: string = '';

  loadingUsersDataError = (index: number, item: { errorLoadingData: boolean }) => item.errorLoadingData;
  noUsersDataFound = (index: number, item: { noData: boolean }) => item.noData;

  constructor(
    private dialog: MatDialog,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private translateService: TranslateService,
    private userService: UserService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.getUsers(0, this.maxLength);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reloadUsers.currentValue) {
      this.getUsers(0, this.paginator ? this.paginator.pageSize : this.maxLength);
    }
  }

  private getUsers(pageIndex: number, pageSize: number): void {
    this.loading = true;
    this.usersDataFound = false;
    this.loadUsersNoDataError = false;

    if (this.typeUser === 'matrix') {
      this.handleMatrixUser(pageIndex, pageSize);

    } else {
      this.handleBranchUser(pageIndex, pageSize);
    }
  }

  handleBranchUser(pageIndex: number, pageSize: number) {
    this.userService.getBranchOfficeUsers(this.branchOfficeId, pageIndex, pageSize, 'name').subscribe({
      next: this.success(),
      error: this.failure()
  });
  }

  success(){
    return data => {
      if (data.users.length === 0) {
        this.dataSource.data = [{ noData: true }];
      } else {
        this.usersDataFound = true;

        setTimeout(() => {
          this.paginator.length = data.pagination.totalElements;
          this.paginator.pageIndex = data.pagination.pageIndex;
          this.paginator.pageSize = data.pagination.pageSize;
          this.dataSource.data = data.users;
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = this.sortDataSource();
        });

      }

      this.loading = false;
    };
  }

  failure(){
    return (error: HttpErrorResponse) => {
      this.loadUsersNoDataError = true;
      this.dataSource.data = [{ errorLoadingData: true }];
      this.loading = false;
      console.log('error', error);
    };
  }

  handleMatrixUser(pageIndex: number, pageSize: number) {
    this.userService.getAgencyGroupUsers(this.matrixId, pageIndex, pageSize).subscribe({
      next: this.success(),
      error: this.failure()
  });
  }

  sortDataSource(): (data: any, sortHeaderId: string) => string | number {
    return (dataAux, header) => {
      if (header == 'role'){
        let name = '';

          for (const role of dataAux.roles) {
            name = name + role.name + ', ';
          }

          return name.slice(0, name.length - 2).toLocaleLowerCase();
      }
      else {
        return (!isNaN(dataAux[header]) || dataAux[header] == null) ? dataAux[header] : dataAux[header].toLocaleLowerCase();
      }
    };
  }

  refreshAgencyUsers(): void {
    this.getUsers(0, this.paginator ? this.paginator.pageSize : this.maxLength);
  }

  highlightRow(event: Event, row: User): void {
    this.selectedRowIndex = event.type === 'mouseover' ? row.id : -1;
  }

  openEditUserDialog(user: User): void {
    let agencyGroup = new AgencyGroup();
    const dialogRef = this.dialog.open(CreateEditUserComponent);

    if (this.matrixId) {
      agencyGroup.id = this.matrixId;
    } else {
      agencyGroup = null;
    }

    dialogRef.componentInstance.user = new User();
    dialogRef.componentInstance.user = user;
    dialogRef.componentInstance.user.agencyGroup = agencyGroup;
    dialogRef.componentInstance.isEdit = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.getUsers(0, this.paginator ? this.paginator.pageSize : this.maxLength);
      }
    });
  }

  showUserDeleteConfirmation(user: User): void {
    swal.fire({
      html: '<p>' + this.translateService.instant('popup.deleteUser') + '</p>',
      icon: 'question',
      confirmButtonText: this.translateService.instant('button.yesSure'),
      confirmButtonColor: '#d33',
      showCancelButton: true,
      cancelButtonText: this.translateService.instant('button.cancel'),
      allowOutsideClick: false,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.loading = true;

        this.userService.deleteUser(user).subscribe({
          next: data => {
            this.messageService.showSuccessMessage(
              this.translateService.instant('button.confirm'),
              this.translateService.instant('popup.wellDone'),
              this.translateService.instant('success.deleteUser')
            );

            this.loading = false;

            this.getUsers(0, this.paginator ? this.paginator.pageSize : this.maxLength);
          },
          error: (error: HttpErrorResponse) => {
            this.messageErrorService.sendError(error, 'error.deleteUser');

            this.loading = false;
            console.log('error', error);
          }
      });
      }
    });
  }

  pageEvent(event: PageEvent): void {
    this.getUsers(event.pageIndex, event.pageSize);
  }
}
