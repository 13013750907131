export class Profile {

  roleAgent?: boolean;
  roleAgency?: boolean;
  roleBranchOffice?: boolean;
  latamManagement?: boolean;
  agencyManagment?: boolean;
  waiverManagment?: boolean;
  bdaManagment?: boolean;
  createUserManagement?: boolean;
  readUserManagement?: boolean;
  updateUserManagment?: boolean;
  deleteUserManagement?: boolean;
  readFeedback?: boolean;
  createBudgetManagement?: boolean;
  readBudgetManagement?: boolean;
  updateBudgetManagement?: boolean;
  createBudgetBulkLoad?: boolean;
  readWaiverAutomation?: boolean;
  updateWaiverAutomation?: boolean;
  readWaiverTypeActivation?: boolean;
  updateWaiverTypeActivation?: boolean;
  readFarediffManagement?: boolean;
  updateFarediffManagement?: boolean;
  createBulkLoad?: boolean;
  readBulkLoad?: boolean;
  createActivateAgency?: boolean;
  readActivateAgency?: boolean;
  createAgencyRegistration?: boolean;
  readAgencyRegister?: boolean;
  updateAgencyRegister?: boolean;
  deleteAgencyRegister?: boolean;
  createAgencyGroupManagment?: boolean;
  readAgencyGroupManagement?: boolean;
  updateAgencyGroupManagement?: boolean;
  deleteAgencyGroupManagment?: boolean;
  readWaiverPostSaleManagement?: boolean;
  updateWaiverPostSaleManagement?: boolean;
  readWaiverPreSaleManagmenet?: boolean;
  updateWaiverPreSaleManagement?: boolean;
  readReports?: boolean;
  updateReports?: boolean;
  createParameters?: boolean;
  readParameters?: boolean;
  updateParameters?: boolean;
  deleteParameters?: boolean;
  createAgencyProfileLt?: boolean;
  readAgencyProfileLT?: boolean;
  updateAgencyProfileLT?: boolean;
  deleteAgencyProfileLt?: boolean;
  readAgencyReports?: boolean;
  readAgencyWaiverPostSaleManagment?: boolean;
  createAgencyWaiverPreSaleManagement?: boolean;
  readAgencyGroupProfileLT?: boolean;
  createAgencyGroupUserManagementLT?: boolean;
  readAgencyGroupUserManagementLT?: boolean;
  updateAgencyGroupUserManagementLT?: boolean;
  deleteAgencyGroupUserManagementLT?: boolean;
  createPreSaleRequest?: boolean;
  readPreSaleRequest?: boolean;
  createPostSaleRequest?: boolean;
  readPostSaleRequest?: boolean;
  readWaiverPostSaleManagementAgency?: boolean;
  readWaiverPreSaleManagementAgency?: boolean;
  readAgencyProfile?: boolean;
  createBranchOfficeUser?: boolean;
  readBranchOfficeUser?: boolean;
  updateBranchOfficeUser?: boolean;
  deleteBranchOfficeUser?: boolean;
  readAgencyGroupProfile?: boolean;
  createAgencyGroupUserManagement?: boolean;
  readAgencyGroupUserManagement?: boolean;
  updateAgencyGroupUserManagement?: boolean;
  deleteAgencyGroupUserManagement?: boolean;
  readDownloadUserListLT?: boolean;
  downloadAgencyListLT?: boolean;
  createBranchOfficeLT?: boolean;
  readBranchOfficeProfile?: boolean;
  readUserProfile?: boolean;
  readBranchOfficeProfileLT?: boolean;
  createBranchOfficeUserLT?: boolean;
  readBranchOfficeUserLT?: boolean;
  updateBranchOfficeUserLT?: boolean;
  deleteBranchOfficeUserLT?: boolean;
  reprocessBdaLT?: boolean;
  waiverManualManagement?: boolean;
  isSelfManaged?: boolean;
  waiverApproveRejectManual?: boolean;
  readWaiverRequest?: boolean;
  createWaiverRequest?: boolean;
  readWaiverManagementAgency?: boolean;
  readFareRegulation?: boolean;
  readCorrectName?: boolean;

  constructor() {
    this.roleAgent = false;
    this.roleAgency = false;
    this.roleBranchOffice = false;
    this.latamManagement = false;
    this.agencyManagment = false;
    this.waiverManagment = false;
    this.bdaManagment = false;
    this.createUserManagement = false;
    this.readUserManagement = false;
    this.updateUserManagment = false;
    this.deleteUserManagement = false;
    this.readFeedback = false;
    this.createBudgetManagement = false;
    this.readBudgetManagement = false;
    this.updateBudgetManagement = false;
    this.readWaiverAutomation = false;
    this.updateWaiverAutomation = false;
    this.readWaiverTypeActivation = false;
    this.updateWaiverTypeActivation = false;
    this.readFarediffManagement = false;
    this.updateFarediffManagement = false;
    this.createBulkLoad = false;
    this.readBulkLoad = false;
    this.createBudgetBulkLoad = false;
    this.createActivateAgency = false;
    this.readActivateAgency = false;
    this.createAgencyRegistration = false;
    this.readAgencyRegister = false;
    this.updateAgencyRegister = false;
    this.deleteAgencyRegister = false;
    this.createAgencyGroupManagment = false;
    this.readAgencyGroupManagement = false;
    this.updateAgencyGroupManagement = false;
    this.deleteAgencyGroupManagment = false;
    this.readWaiverPostSaleManagement = false;
    this.updateWaiverPostSaleManagement = false;
    this.readWaiverPreSaleManagmenet = false;
    this.updateWaiverPreSaleManagement = false;
    this.readReports = false;
    this.updateReports = false;
    this.createParameters = false;
    this.readParameters = false;
    this.updateParameters = false;
    this.deleteParameters = false;
    this.createAgencyProfileLt = false;
    this.readAgencyProfileLT = false;
    this.updateAgencyProfileLT = false;
    this.deleteAgencyProfileLt = false;
    this.readAgencyReports = false;
    this.readAgencyWaiverPostSaleManagment = false;
    this.createAgencyWaiverPreSaleManagement = false;
    this.readAgencyGroupProfileLT = false;
    this.createAgencyGroupUserManagementLT = false;
    this.readAgencyGroupUserManagementLT = false;
    this.updateAgencyGroupUserManagementLT = false;
    this.deleteAgencyGroupUserManagementLT = false;
    this.createPreSaleRequest = false;
    this.readPreSaleRequest = false;
    this.createPostSaleRequest = false;
    this.readPostSaleRequest = false;
    this.readWaiverPostSaleManagementAgency = false;
    this.readWaiverPreSaleManagementAgency = false;
    this.readAgencyProfile = false;
    this.createBranchOfficeUser = false;
    this.readBranchOfficeUser = false;
    this.updateBranchOfficeUser = false;
    this.deleteBranchOfficeUser = false;
    this.readAgencyGroupProfile = false;
    this.createAgencyGroupUserManagement = false;
    this.readAgencyGroupUserManagement = false;
    this.readDownloadUserListLT = false;
    this.readBranchOfficeProfile = false;
    this.readUserProfile = false;
    this.readBranchOfficeProfileLT = false;
    this.createBranchOfficeUserLT = false;
    this.readBranchOfficeUserLT = false;
    this.updateBranchOfficeUserLT = false;
    this.deleteBranchOfficeUserLT = false;
    this.reprocessBdaLT = false;
    this.waiverManualManagement= false;
    this.isSelfManaged = false;
    this.waiverApproveRejectManual = false;
    this.readWaiverRequest = false;
    this.createWaiverRequest = false;
    this.readWaiverManagementAgency = false;
    this.readFareRegulation = false;
    this.readCorrectName = false;
  }
}
