import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { WaiverLatamPostsaleInterface } from '../../../../interfaces/waiver-latam-postsale.interface';

@Component({
  selector: 'app-waiver-list-table',
  templateUrl: './waiver-list-table.component.html',
  styleUrls: ['./waiver-list-table.component.css']
})

export class WaiverListTableComponent implements OnInit {
   @Output() watchDetailsEvent = new EventEmitter<any>();
   @Output() pageEvent = new EventEmitter<any>();
   @Output() reloadTable = new EventEmitter<any>();

   @Input() totalRows: number;
   @Input() currentPage: number;
   @Input() pageSize: number;
   @Input() waiverData: WaiverLatamPostsaleInterface[];
   @Input() tabNumber: number;

   @ViewChild(MatPaginator) paginator: MatPaginator;
   @ViewChild(MatSort) sort: MatSort;
   pageSizeOptions: number[] = [10, 30, 50];

   isEmptyData = (index: number, item: { noData: boolean }) => item.noData;
   errorLoading = (index: number, item: { errorLoadingData: boolean }) => item.errorLoadingData;

   dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
   displayedColumns = ['id', 'marketName', 'agency', 'agencyIata', 'executive', 'type',
      'motive', 'documentNumber', 'statusDate', 'documentCloserDepartureDate', 'actions'];




   ngOnInit() {
      this.insertDataSource();
   }

   insertDataSource(){
      if (this.waiverData !==  null && this.waiverData !== undefined){
         if (this.waiverData.length == 0){
            this.dataSource.data = [{noData: true}];
         } else{
            this.dataSource.data = this.waiverData;
            this.dataSource.sort = this.sort;
            this.paginator.length = this.totalRows;
            this.paginator.pageSize = this.pageSize;
            this.paginator.pageIndex = this.currentPage;
         }
      }
      else{
         this.dataSource.data = [{errorLoadingData: true}];
      }
   }

   pageChanged(event: PageEvent){
      this.pageEvent.emit(event);
   }

   reloadTableEvent(){
      this.reloadTable.emit(this.tabNumber);
   }

   getErrorMessage(){
      return this.tabNumber == 1 ? 'error.loadingPendingWaivers'
         : this.tabNumber == 2 ? 'error.loadingAprovedWaivers'
         : this.tabNumber == 3 ? 'error.loadingDeniedWaivers'
         : '';
   }

   getTab(){
      return this.tabNumber == 1 ? 'pendientes'
         : this.tabNumber == 2 ? 'aprobados'
         : this.tabNumber == 3 ? 'rechazados'
         : '';
   }

   getTableHeader(){
      return this.tabNumber == 1 ? 'field.requestDateHour'
         : this.tabNumber == 2 ? 'field.approvalDateHour'
         : this.tabNumber == 3 ? 'field.denialDateHour'
         : '';
   }

   openDialog(tab: string, object: any){
      this.watchDetailsEvent.emit({tab, object});
   }
}
