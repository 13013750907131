import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

import { AgencyGroup } from '../../../models/agency-group.model';
import { AgencyProfile } from '../../../models/agency-profile.model';
import { AgencyResume } from '../../../models/agency-resume.model';
import { AgencyTier } from '../../../models/agency-tier.model';
import { BranchOffice } from '../../../models/branch-office.model';
import { BusinessException, Exception, SystemException } from '../../../models/Exception.model';
import { PiaAgencyType } from '../../../models/pia-agency-type.model';
import { Profile } from '../../../models/profile.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';

import { AgencyBranchOfficeService } from './../../../services/agency-branch-office.service';
import { AgencyGroupService } from '../../../services/agency-group.service';
import { AgencyPiaService } from './../../../services/agency-pia.service';
import { AgencyService } from '../../../services/agency.service';
import { MessageService } from '../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { MessageErrorService } from '../../../services/message-error.service';


@Component({
  selector: 'app-profile-agency',
  templateUrl: './profile-agency.component.html',
  styleUrls: ['./profile-agency.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileAgencyComponent implements OnInit {
  @ViewChild('profileAgencyForm') profileAgencyForm: NgForm;
  agencyIsActive: boolean;
  controlDisabled: boolean;
  generatePiaCode = false;
  loading = true;
  updatedSuccessfully = false;
  updating = false;
  private modified = false;

  agencyGroupId: number = 0;

  agencyProfile: AgencyProfile = new AgencyProfile();
  branchOfficeList: BranchOffice[] = [];
  groupList: AgencyGroup[] = [];
  piaAgencyTypeList: PiaAgencyType[] = [];
  profiles = new Profile();
  selectedPiaAgencyType: PiaAgencyType;
  private sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_AGENCIES, SidebarMenuModel.SUBMENU_AGENCY_REGISTER);

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private agencyBranchOfficeService: AgencyBranchOfficeService,
    private agencyGroupService: AgencyGroupService,
    private agencyPiaService: AgencyPiaService,
    private agencyService: AgencyService,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();

    this.agencyProfile.agencyGroup = {
      id: 0,
      name: '',
      agencyTier: new AgencyTier(null, null),
      agencyChannel: null,
      market: null,
      pptoWaiver: null,
      pptoAd: null,
      showBudget: false,
      branchOfficesNumber: null,
      userOfficesNumber: null,
      adminSales: null,
      salesExecutive: null,
      showPresaleBudget: false,
      allowPrivateFare: false,
      allowPreSaleModule: false,
      allowPostSaleModule: false,
      selfManaged: false,
      allowWaiverModule: false,
      mainIata: ''
    };

    this.intBranchOffice();

    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.agencyProfile.agencyTier = new AgencyTier(null, null);

    this.activatedRoute.queryParams.subscribe(params => {
      this.agencyIsActive = params.active;

      this.controlDisabled = this.profiles.updateAgencyProfileLT;

      if (this.profiles.readAgencyProfileLT || this.profiles.readAgencyProfile) {
        this.agencyProfile.id = params.agencyId;

        if (params.groupId != null) {
          this.agencyGroupId = params.groupId;
        }
      } else {
        this.router.navigateByUrl('/401', { skipLocationChange: true });
      }

      this.getAgencyPiaTypesList();
      this.getAgencyProfile();
    });
  }

  private getAgencyProfile() {
    this.loading = true;

    this.agencyService.getAgencyProfile(this.agencyProfile.id).subscribe(
      data => {
        if (data.agencyTier == null) {
          data.agencyTier = {
            id: 0,
            name: '',
            level: 0
          };
        }

        if (data.agencyGroup == null) {
          data.agencyGroup = {
            id: 0,
            name: '',
            agencyTier: new AgencyTier(null, null),
            agencyChannel: null,
            market: null,
            pptoWaiver: null,
            pptoAd: null,
            showBudget: false,
            branchOfficesNumber: null,
            userOfficesNumber: null,
            adminSales: null,
            salesExecutive: null,
            showPresaleBudget: false,
            allowPrivateFare: false,
            allowPreSaleModule: false,
            allowPostSaleModule: false,
            selfManaged: false,
            allowWaiverModule: false,
            mainIata: ''
          };
        }

        data.officeId = data.officeId ? data.officeId : '';
        data.pcc = data.pcc ? data.pcc : '';
        this.agencyProfile = data;

        if (this.agencyProfile.branchOffice === null) {
          this.intBranchOffice();
        }

        if (this.agencyProfile.piaAgencyType) {
          this.selectedPiaAgencyType = this.piaAgencyTypeList.find(f => f.code === this.agencyProfile.piaAgencyType);
        }

        this.getAgencyGroupList(this.agencyProfile.marketId);
        this.getBranchOfficeList(this.agencyProfile.agencyGroup.id);
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingAgency');

        this.goBack();
        this.loading = false;
        console.log('error', error);
      }
    );

  }

  private getAgencyPiaTypesList(): void {
    if (this.profiles.readAgencyProfileLT) {
      this.agencyPiaService.getAgencyPiaTypesList().subscribe({
        next: data => {
          this.piaAgencyTypeList = data;
        },
        error: (error: HttpErrorResponse) => {
          this.messageErrorService.sendError(error, 'error.loadingPiaAgencies');

          console.log('error', error);
        }
    });
    }
  }

  /**
   * Funcion que carga los grupos de la BD
   */
  private getAgencyGroupList(marketId: number): void {
    const centralAmericaId = this.getCentralAmericaId();
    if (centralAmericaId.has(marketId)){
      const mexicoMarketId = 13;
      marketId = mexicoMarketId;
    }

    if (this.profiles.readAgencyProfileLT) {
      this.agencyGroupService.getAgencyGroupListByMarket(marketId).subscribe({
        next: data => {
          this.groupList = data;
        },
        error: (error: HttpErrorResponse) => {
          this.messageErrorService.sendError(error, 'error.loadingAgenciesGroup');

          console.log('error', error);
        }
    });
    }
  }

  private getCentralAmericaId(): Map<number, string>{
    const centralAmericaId = new Map<number, string>();
    centralAmericaId.set(20, "panamaMarkedId");
    centralAmericaId.set(21, "costaRicaMarketId");
    centralAmericaId.set(22, "puertoRicoMarketId");
    return centralAmericaId;
  }

  updateAgency() {
    this.updating = true;
    this.updatedSuccessfully = false;
    this.modified = false;
    const agencyResume = this.setAgencyResume();

    this.agencyService.updateAgency(agencyResume).subscribe({
      next: result => {
        this.updating = false;
        this.updatedSuccessfully = true;
        this.modified = true;
        this.getAgencyProfile();
        setTimeout(() => {
          this.updatedSuccessfully = false;
        }, 2000);
        if (this.updatedSuccessfully) {
        this.messageService.showSuccessMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('popup.wellDone'),
          this.translateService.instant('success.update')
        );
        }

      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.update');

        this.updating = false;
        console.log('error', error);
      }
  });
  }

  setAgencyResume(): AgencyResume {
    let agencyResume = <AgencyResume> {
      id: this.agencyProfile.id,
      agencyCode: this.agencyProfile.agencyCode,
      agencyBranch: this.agencyProfile.agencyBranch,
      officeId: this.agencyProfile.officeId,
      pcc: this.agencyProfile.pcc,
      agencyGroup: this.agencyProfile.agencyGroup,
      agencyBranchOffice: this.agencyProfile.branchOffice,
      piaAgencyCode: this.agencyProfile.piaAgencyCode,
      piaAgencyType: this.agencyProfile.piaAgencyType,
    };

    return agencyResume;
  }

  goBack() {
    this.sessionInfoService.getSessionInfoAsPromise()
      .then(
        session => {
          if (session.isLatamSession()) {
            if (this.agencyGroupId > 0) {
              this.router.navigate(
                ['/latam/agencies/group/profile'],
                { relativeTo: this.activatedRoute, queryParams: { id: this.agencyGroupId } }
              );
            } else {
              this.router.navigate(['../register'], { relativeTo: this.activatedRoute, queryParams: { modified: this.modified } });
            }
          } else if (session.isAdminAgencyGroupUser() || session.isManualAgencyGroupUser()) {
            this.router.navigate(['/agency/group/profile'], { relativeTo: this.activatedRoute });
          } else if (session.isAdminBranchOfficeUser()) {
            this.router.navigate(['/agency/branch-office/profile'], { relativeTo: this.activatedRoute });
          }
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
  }

  /**
   * Funcion que carga las sucursales desde la BD
   */
  private getBranchOfficeList(agencyGrupId: number) {
    if (this.profiles.readAgencyProfileLT && agencyGrupId != null) {
      this.agencyBranchOfficeService.getBranchOfficeList(agencyGrupId, null).subscribe({
        next: data => {
          this.branchOfficeList = data;
        },
        error: (error: HttpErrorResponse) => {
          this.messageErrorService.sendError(error, 'error.loadingBranchOffice');

          console.log('error', error);
        }
    });
    }
  }

  intBranchOffice(): void {
    this.agencyProfile.branchOffice = {
      id: 0,
      name: '',
      agencyGroup: new AgencyGroup(),
      consumeGroupBudget: true,
      showPostsaleBudget: false,
      showPresaleBudget: false
    };
  }

  setAgencyGroup(event: MatSelectChange): void {
    this.intBranchOffice();
    this.agencyProfile.agencyGroup = this.groupList.find(f => f.id === +event.value);
    this.getBranchOfficeList(this.agencyProfile.agencyGroup.id);
  }

  setBranchOffice(event: MatSelectChange): void {
    if (+event.value !== 0) {
      this.agencyProfile.branchOffice = this.branchOfficeList.find(f => f.id === +event.value);
    } else {
      this.intBranchOffice();
    }
  }

  checkedPia(event: MatCheckboxChange): void {
    if (!event.checked) {
      this.selectedPiaAgencyType = null;
      this.agencyProfile.piaAgencyType = null;
    }
  }

  changePiaAgencyType(event: MatSelectChange) {
    this.agencyProfile.piaAgencyType = event.value.code;
  }

  updateAgencyData(): void {
    if (this.generatePiaCode) {
      this.showQuestionMessage();
    } else {
      this.updateAgency();
    }
  }

  private showQuestionMessage(): void {
    swal.fire({
      html: `<p>${ this.translateService.instant('warning.generatePiaCode') }</p>`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('button.yes'),
      cancelButtonText: this.translateService.instant('button.cancel'),
      reverseButtons: true,
      customClass: {
        confirmButton: 'confirm-button',
        cancelButton: 'cancel-button'
      },
    }).then((result) => {
      if (result.value) {
        this.updateAgency();
      }
    });
  }
}
