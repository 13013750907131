import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { WaiverTermsConditionsComponent } from './waiver-terms-conditions/waiver-terms-conditions.component';
import { DialogManualTypeComponent } from './dialog-manual-type/dialog-manual-type.component';

import { ShareModule } from './../share-module/share.module';

import { FileStorageService } from './../../services/file-storage.service';
import { LoginAgencyService } from './../../services/login-agency.service';
import { TermsConditionsService } from '../../services/terms-conditions.service';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select'; 
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';


@NgModule({
  declarations: [
    TermsConditionsComponent,
    WaiverTermsConditionsComponent,
    DialogManualTypeComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    ShareModule,
    MatTableModule,
    MatSelectModule,
    MatIconModule,
    FormsModule,
    NgxExtendedPdfViewerModule
  ],
  exports: [
    WaiverTermsConditionsComponent
  ],
  providers: [
    FileStorageService,
    LoginAgencyService,
    TermsConditionsService
  ]
})
export class TermsConditionsModule { }
