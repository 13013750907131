import { Component, Input } from '@angular/core';

import { WaiverUserManualFile } from '../../..//models/waiver-user-manual-file-model';
import { TermsConditions } from '../../../models/terms-conditions.model';

import { FileStorageService } from '../../../services/file-storage.service';
import { DialogManualTypeComponent } from '../dialog-manual-type/dialog-manual-type.component';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { WaiverCategory } from '../../../models/waiver-category.model';

@Component({
  selector: 'app-waiver-terms-conditions',
  templateUrl: './waiver-terms-conditions.component.html',
  styleUrls: ['./waiver-terms-conditions.component.css']
})
export class WaiverTermsConditionsComponent {
  @Input() waiverCategory: string;
  selectedWaiverType: string;
  showDropdown = false;
  termsConditions: TermsConditions = new TermsConditions(null);
  waiverUserManualFiles: WaiverUserManualFile[] = [];
  showModal = false;
  types: WaiverCategory[];

  constructor(
    private fileStorageService: FileStorageService,
    private translateService: TranslateService,
    private dialog: MatDialog) { }

  getWaiverManual() {
    const language = localStorage.getItem('language');

    this.fileStorageService.listWaiverUserManual().subscribe({
      next: (files: WaiverUserManualFile[]) => {
        this.waiverUserManualFiles = files.filter(
          (file: WaiverUserManualFile) => file.language === language && file.waiverCategory === this.waiverCategory
        );
        // Recorre this.waiverUserManualFiles y asigna los valores a types
        this.types = this.waiverUserManualFiles.map((file, index) => ({
          id: index,
          name: file.waiverType,
          code: file.waiverType
        }));

        if(this.waiverUserManualFiles.length>0){
          if(this.waiverUserManualFiles.length===1){
            let locationStorage: string= this.waiverUserManualFiles[0].locationStorage;
            this.fileStorageService.downloadWaiverUserManual(locationStorage, language).subscribe({
              next: this.fileStorageService.downloadWaiverUserManualSuccess(),
              error: this.fileStorageService.downloadWaiverUserManualError()});

          }else {
            this.openModal();
          }
        }else{
          swal.fire({
            html: '<p>' + this.translateService.instant('error.notDataFound') + '</p>',
            confirmButtonText: this.translateService.instant('button.confirm'),
            confirmButtonColor: '#d33',
            icon: 'warning',
            allowOutsideClick: false
          }).then((result) => {
            if (result.value) {
              //Success
            }
          });
        }

      },
      error: this.fileStorageService.listWaiverUserManualError(null)
  });
  }
  openModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.height = '30vh';
    dialogConfig.width = '40vw';
    dialogConfig.panelClass = 'small-modal';
    dialogConfig.data = { waiverCategory: this.waiverCategory, types: this.types};
    this.dialog.open(DialogManualTypeComponent, dialogConfig);
}

}
