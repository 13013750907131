import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AgencyGroupInterface } from '../../../interfaces/agency-group.interface';

import { BusinessException, Exception, SystemException } from '../../../models/Exception.model';
import { Profile } from '../../../models/profile.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';

import { CreateAgencyGroupComponent } from '../create-agency-group/create-agency-group.component';
import { EditAgencyGroupComponent } from '../edit-agency-group/edit-agency-group.component';

import { AgencyGroupService } from '../../../services/agency-group.service';
import { MessageService } from '../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { MessageErrorService } from '../../../services/message-error.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-list-agency-group',
  templateUrl: './list-agency-group.component.html',
  styleUrls: ['./list-agency-group.component.css']
})
export class ListAgencyGroupComponent implements OnInit {
  agenciesGroupsDataFound = false;
  loading = false;
  updating = false;

  selectedRowIndex: number = -1;

  agencyTierNameFilter = new UntypedFormControl();
  branchOfficesNumberFilter = new UntypedFormControl();
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [
    'name', 'agencyTierName', 'branchOfficesNumber', 'marketName', 'pptoWaiver', 'pptoAd', 'notifications', 'actions'
  ];
  filteredValues = { name: '', agencyTierName: '', branchOfficesNumber: '', marketName: '', pptoWaiver: '', pptoAd: '' };
  marketNameFilter = new UntypedFormControl();
  nameFilter = new UntypedFormControl();
  pptoAdFilter = new UntypedFormControl();
  pptoWaiverFilter = new UntypedFormControl();
  profiles = new Profile();

  private maxLength = 0;
  private sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_AGENCIES, SidebarMenuModel.SUBMENU_AGENCY_GROUP);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loadingAgenciesGroupsDataError = (index: number, item: { errorLoadingData: boolean }) => item.errorLoadingData;
  noAgenciesGroupsDataFound = (index: number, item: { noData: boolean }) => item.noData;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private messageErrorService: MessageErrorService,
    private router: Router,
    private agencyGroupService: AgencyGroupService,
    private messageService: MessageService,
    private profilesService: ProfilingService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
    this.loading = true;
    this.countAgencyGroup();
    this.filterWaiver();
    this.dataSource.filterPredicate = this.customFilterPredicate();
  }

  private countAgencyGroup() {
    this.loading = true;

    this.agencyGroupService.countAgencyGroup().subscribe({
      next: data => {
        this.maxLength = data;
        this.getAgencyGroupList(0, this.maxLength);
      },
      error: (error: HttpErrorResponse) => {
        this.agenciesGroupsDataFound = false;
        this.dataSource.data = [{ errorLoadingData: true }];

        this.messageErrorService.sendError(error, 'error.loadingAgencies');

        this.loading = false;
        console.log('error', error);
      }});
  }

  private filterWaiver(): void {
    this.nameFilter.valueChanges.subscribe((nameFilterValue) => {
      this.updateFilters('name', nameFilterValue);
    });
    this.agencyTierNameFilter.valueChanges.subscribe((agencyTierNameFilterValue) => {
      this.updateFilters('agencyTierName', agencyTierNameFilterValue);
    });
    this.branchOfficesNumberFilter.valueChanges.subscribe((branchOfficesNumberFilterValue) => {
      this.updateFilters('branchOfficesNumber', branchOfficesNumberFilterValue);
    });
    this.marketNameFilter.valueChanges.subscribe((marketNameFilterValue) => {
      this.updateFilters('marketName', marketNameFilterValue);
    });
    this.pptoWaiverFilter.valueChanges.subscribe((pptoWaiverFilterValue) => {
      this.updateFilters('pptoWaiver', pptoWaiverFilterValue);
    });
    this.pptoAdFilter.valueChanges.subscribe((pptoAdFilterValue) => {
      this.updateFilters('pptoAd', pptoAdFilterValue);
    });
  }

  updateFilters(col: string, value: any): void {
    this.filteredValues[col] = value;
    this.dataSource.filter = JSON.stringify(this.filteredValues);
    this.dataSource.paginator.firstPage();
  }

  private customFilterPredicate() {
    const myfilterPredicate = (data: any, filter: string) => {
      const searchString = JSON.parse(filter);
      const nameFound = this.isNameFound(data, searchString);
      const agencyTierNameFound = this.isAgencyTierNameFound(data, searchString);
      const branchOfficesNumberFound = this.isBranchOfficesNumberFound(data, searchString);
      const marketNameFound = this.isMarketNameFound(data, searchString);
      const pptoWaiverFound = this.isPptoWaiverFound(data, searchString);
      const pptoAdFound = this.isPptoAdFound(data, searchString);

      return nameFound && agencyTierNameFound && branchOfficesNumberFound && marketNameFound && pptoWaiverFound && pptoAdFound;
    };

    return myfilterPredicate;
  }

  isPptoAdFound(data: any, searchString: any) {
    return (data.pptoAd === null && searchString.pptoAd === '') ||
    (data.pptoAd !== null && data.pptoAd.toString().trim().toLowerCase().indexOf(searchString.pptoAd.toLowerCase()) !== -1);
  }

  isPptoWaiverFound(data: any, searchString: any) {
    return (data.pptoWaiver === null && searchString.pptoWaiver === '') ||
    (data.pptoWaiver !== null && data.pptoWaiver.toString().trim().toLowerCase().indexOf(searchString.pptoWaiver.toLowerCase()) !== -1);
  }

  isMarketNameFound(data: any, searchString: any) {
    return (data.marketName === null && searchString.marketName === '') ||
    (data.marketName !== null && data.marketName.toString().trim().toLowerCase().indexOf(searchString.marketName.toLowerCase()) !== -1);
  }

  isBranchOfficesNumberFound(data: any, searchString: any) {
    return (data.branchOfficesNumber === null && searchString.branchOfficesNumber === '') ||
    (data.branchOfficesNumber.toString().trim().toLowerCase().indexOf(searchString.branchOfficesNumber.toLowerCase()) !== -1);
  }

  isAgencyTierNameFound(data: any, searchString: any) {
    return (data.agencyTierName === null && searchString.agencyTierName === '') ||
    (data.agencyTierName !== null &&
     data.agencyTierName.toString().trim().toLowerCase().indexOf(searchString.agencyTierName.toLowerCase()) !== -1);
  }

  isNameFound(data: any, searchString: any) {
    return (data.name === null && searchString.name === '') ||
    (data.name !== null && data.name.toString().trim().toLowerCase().indexOf(searchString.name.toLowerCase()) !== -1);
  }

  getAgencyGroupList(pageIndex: number, pageSize: number): void {
    this.loading = true;
    this.agenciesGroupsDataFound = false;

    this.agencyGroupService.getAgencyGroupList(pageIndex, pageSize, 'creationDate', 'desc').subscribe({
      next: data => {
        if (data.length === 0) {
          this.dataSource.data = [{ noData: true }];
        } else {
          this.agenciesGroupsDataFound = true;

          setTimeout(() => {
            this.dataSource.data = data;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (dataAux: AgencyGroupInterface, header) => {
              return (!isNaN(dataAux[header]) || dataAux[header] == null) ? dataAux[header] : dataAux[header].toLocaleLowerCase();
            };
          });
        }

        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.dataSource.data = [{ errorLoadingData: true }];
        this.messageErrorService.sendError(error, 'error.loadingAgencies');

        this.loading = false;
        console.log('error', error);
      }
  });
  }

  openCreateGroupDialog(): void {
    let dialogRef = this.dialog.open(CreateAgencyGroupComponent);

    this.sessionInfoService.getSessionInfoAsPromise()
    .then(
      sessionInfo => dialogRef.componentInstance.userMarketIds = sessionInfo.marketId
    )
    .catch(
      error => {
        console.log('error', error);
      }
    );

    dialogRef.afterClosed().subscribe(() => {
      this.getAgencyGroupList(0, this.maxLength);
    });
  }

  highlightRow(event: Event, row: AgencyGroupInterface): void {
    this.selectedRowIndex = event.type === 'mouseover' ? row.id : -1;
  }

  /**
   * Redireccionamiento al perfil del grupo
   */
  public openGroupProfile(id: number): void {
    this.router.navigate(['../profile'], { relativeTo: this.activatedRoute, queryParams: { id: id } });
  }

  public openEditProfile(id: number): void {
    let dialogRef = this.dialog.open(EditAgencyGroupComponent);
    dialogRef.componentInstance.groupId = id;
    dialogRef.afterClosed().subscribe(() => {
      this.getAgencyGroupList(0, this.maxLength);
    });
  }

  reloadAgenciesGroups() {
    this.countAgencyGroup();
  }

  updateWaiverNotifications(element: AgencyGroupInterface) {
    this.updating = true;
    this.spinner.show();
    this.agencyGroupService.updateWaiverNotifications(element).subscribe({
      next: (data: any) => {
        this.updating = false;
        this.spinner.hide();
        element.notification = !element.notification;
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loadingAgencies');
        this.updating = false;
        this.spinner.hide();
        console.log('error', error);
      }
  });
  }
}
