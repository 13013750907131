import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';

import { Market } from '../../../models/market.model';
import { Profile } from '../../../models/profile.model';
import { SessionInfo } from '../../../models/SessionInfo.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';
import { WaiverTypeStatus } from '../../../models/waiver-type-status.model';

import { MarketService } from './../../../services/market.service';
import { MessageService } from './../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { SessionInfoService } from '../../../services/session-info.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';
import { WaiverPostsaleService } from './../../../services/waiver-postsale.service';
import { MessageErrorService } from './../../../services/message-error.service';


@Component({
  selector: 'app-waiver-type-management',
  templateUrl: './waiver-type-management.component.html',
  styleUrls: ['./waiver-type-management.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WaiverTypeManagementComponent implements OnInit {
  loading = false;
  isMarketSelected = false;

  selectedPosRowIndex: number = -1;

  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['waiver', 'status'];
  infoUser$: Promise<SessionInfo> = this.sessionInfoService.getSessionInfoAsPromise();
  marketInfo: Market = new Market();
  profiles = new Profile();
  waiverTypeStatus: WaiverTypeStatus[] = [];
  sidebarMenu: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_LATAM, SidebarMenuModel.SUBMENU_WAIVER_TYPE_MANAGEMENT);

  constructor(
    private marketService: MarketService,
    private messageService: MessageService,
    private profilesService: ProfilingService,
    private messageErrorService: MessageErrorService,
    private sessionInfoService: SessionInfoService,
    private sidebarShareDataService: SidebarShareDataService,
    private translateService: TranslateService,
    private waiverPostsaleService: WaiverPostsaleService) { }

  ngOnInit() {
    this.profiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenu);
  }

  getWaiverTypeStatusByMaketId() {
    this.loading = true;

    this.waiverPostsaleService.getWaiverTypeStatusByMaketId(this.marketInfo.id).subscribe({
      next: data => {
        this.waiverTypeStatus = data;
        this.dataSource.data = data;
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.loading');

        this.loading = false;
        console.log('error', error);
      }
  });
  }

  getMarketBySelectedPos(selectedPos: string) {
    this.loading = true;
    this.isMarketSelected = true;

    this.marketService.getMarketByName(selectedPos).subscribe({
      next: data => {
        this.marketInfo = data;
        this.getWaiverTypeStatusByMaketId();
      },
      error: (error: HttpErrorResponse) => {
        this.isMarketSelected = false;
        this.messageErrorService.sendError(error, 'error.loading');

        this.loading = false;
        console.log('error', error);
      }
  });
  }

  highlightRow(event: Event, rowIndex: number) {
    this.selectedPosRowIndex = event.type === 'mouseover' ? rowIndex : -1;
  }

  toggleSelectionRow(event: MouseEvent, row: WaiverTypeStatus) {
    let targetClassName: string = event.target['className'];

    if (!targetClassName.includes('mat-slide-toggle')) {
      row.status = !row.status;
    }
  }

  save(): void {
    this.loading = true;

    this.waiverPostsaleService.updateWaiverTypeStatusList(this.waiverTypeStatus).subscribe({
      next: data => {
        this.messageService.showSuccessMessage(
          this.translateService.instant('button.confirm'),
          this.translateService.instant('popup.wellDone'),
          this.translateService.instant('success.update')
        );

        this.getWaiverTypeStatusByMaketId();
      },
      error: (error: HttpErrorResponse) => {
        this.messageErrorService.sendError(error, 'error.request');

        this.loading = false;
        console.log('error', error);
      }
  });
  }
}
