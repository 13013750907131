import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ShareModule } from '../share-module/share.module';
import { UserModule } from './../user-module/user.module';

import { ProfileBranchOfficeComponent } from './profile-branch-office/profile-branch-office.component';

import { AgencyBranchOfficeService } from './../../services/agency-branch-office.service';


@NgModule({
  declarations: [
    ProfileBranchOfficeComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    ShareModule,
    UserModule
  ],
  providers: [
    AgencyBranchOfficeService
  ]
})
export class BranchOfficeModule { }
